<template>
  <div class="team-card">
    <div class="team-left">
      <div class="team-name">{{member.name}}</div>
      <div class="team-position">{{member.position}}</div>
    </div>
    <v-divider class="divider" vertical></v-divider>
    <div class="team-center">
      <div class="team-center-row">
        <div class="team-center-left">{{mailString}}</div>
        <div class="team-center-right">{{member.mail}}</div>
      </div>
      <div class="team-center-row">
        <div class="team-center-left">{{belongString}}</div>
        <div class="team-center-right">{{member.belong}}</div>
      </div>
    </div>
    <v-divider class="divider" vertical></v-divider>
    <div class="team-right">
      <a v-if="member.link.web" :href="member.link.web" target="_blank">
        <img :src="require('@/assets/images/icons/web.svg')" width="22" height="22" />
      </a>
      <a v-if="member.link.linkedin" :href="member.link.linkedin" target="_blank">
        <img :src="require('@/assets/images/icons/linkedin.svg')" width="22" height="22" />
      </a>
      <a v-if="member.link.twitter" :href="member.link.twitter" target="_blank">
        <img :src="require('@/assets/images/icons/twitter.svg')" width="22" height="22" />
      </a>
      <a v-if="member.link.facebook" :href="member.link.facebook" target="_blank">
        <img :src="require('@/assets/images/icons/facebook.svg')" width="22" height="22" />
      </a>
      <a v-if="member.link.blog" :href="member.link.blog" target="_blank">
        <img :src="require('@/assets/images/icons/blog.svg')" width="22" height="22" />
      </a>
      <a v-if="Object.keys(member.link).length === 1" style="cursor: auto;">
        <img width="22" height="22" style="display: none;"/>
      </a>
    </div>
  </div>
</template>

<script>
import Constant from '@/store/constant.js';
export default {
  name: 'TeamMember',
  props: {
    member: Object
  },
  computed: {
    mailString() {
      return Constant.STRING[this.$store.state.language].KLUE_MAIL;
    },
    belongString() {
      return this.member.studying ? 
        Constant.STRING[this.$store.state.language].KLUE_STUDIES_AT : 
        Constant.STRING[this.$store.state.language].KLUE_WORKS_AT;
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';
.team-card {
  background-color: white;
  margin-top: 24px;
  margin-left: 20px;
  margin-right: 20px;
  height: 80px;
  display: flex;
  flex-direction: row;
}

.team-left {
  min-width: 125px;
  max-width: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  .team-name {
    @extend .subheader-heavy;
    margin-bottom: 3px;
  }

  .team-position {
    @extend .caption-light;
  }

}

.team-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  &-row {
    min-width: 260px;
    margin-bottom: 6px;
    display: flex;
    justify-content: space-between;
    .team-center-left {
      @extend .caption-heavy;
      color: #515151;
    }
    .team-center-right {
      @extend .caption-light;
      color: #717171;
    }
  }
}

.team-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 25px;

  a {
    height: 22px;
    flex-grow: 0;
    margin: 6px 0;
  }
}

.divider {
  margin: 0 20px;
}
</style>