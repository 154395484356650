<template>
  <v-card class="signup-card elevation-5 rounded-xl">
    <div class="signup-wrapper">
      <v-row class="ml-0 mr-0 close-btn">
        <v-col class="pa-0"></v-col>
        <v-col class="pa-0 ml-auto" cols="auto">
          <v-btn icon @click="closeSignUpDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div class="signup-title">{{ signUpTitle }}</div>
      <v-row class="signup-img" align="center" justify="center">
        <v-col cols="auto">
          <v-img
            :src="imageSrc"
            class="rounded-circle"
            max-width="120"
            max-height="120"
            height="120"
            width="120"
          />
          <!-- <v-file-input class="camera-btn" prepend-icon="mdi-camera" outlined small-chips hide-input accept="image/*" label="Image" v-model="image"></v-file-input> -->
          <v-btn
            fab
            x-small
            class="camera-btn"
            color="#B0B0B0"
            icon
            depressed
            :loading="isSelecting"
            @click="onButtonClick"
          >
            <Icon
              name="signup_camera"
              size="large"
              style="margin-top: 1px;"
            ></Icon>
          </v-btn>
          <input
            ref="uploader"
            class="d-none"
            type="file"
            accept="image/*"
            @change="onFileChanged"
          />
        </v-col>
      </v-row>
      <v-form>
        <!-- <v-text-field
          id="vcode"
          name="vcode"
          v-model="vcode"
          :placeholder="inputVcodeString"
          type="text"
          outlined
          height="50"
          hide-details
          class="input-field"
          :rules="[requiredRule, alphaNumeric]"
          required
        >
          <template v-slot:prepend-inner>
            <div class="no-icon icon--small"></div>
          </template>
        </v-text-field> -->

        <v-text-field
          name="username"
          v-model="username"
          :placeholder="inputEmailString"
          type="text"
          outlined
          hide-details
          height="50"
          class="input-field"
          :rules="[requiredRule, emailValidation]"
          required
          :readonly="useOAuth"
        >
          <template v-slot:prepend-inner>
            <Icon name="login_id" size="small"></Icon>
          </template>
        </v-text-field>

        <v-text-field
          id="password"
          name="password"
          v-model="password"
          :placeholder="inputPasswordString"
          type="password"
          @keyup.enter="signIn"
          outlined
          height="50"
          hide-details
          class="input-field"
          :rules="[requiredRule]"
          :required="!useOAuth"
          v-if="!useOAuth"
        >
          <template v-slot:prepend-inner>
            <Icon name="login_pw" size="small"></Icon>
          </template>
        </v-text-field>

        <v-text-field
          id="nickname"
          name="nickname"
          v-model="nickname"
          :placeholder="inputNicknameString"
          type="text"
          outlined
          height="50"
          hide-details
          class="input-field"
          :rules="[requiredRule, nicknameRule]"
          required
        >
          <template v-slot:prepend-inner>
            <Icon name="signup_name" size="large"></Icon>
          </template>
        </v-text-field>

        <v-row
          @click.prevent.stop="() => (termDialog = true)"
          class="term-policy"
          no-gutters
        >
          <v-col cols="auto">
            <v-icon middle color="#E84660" v-if="term">
              mdi-checkbox-marked
            </v-icon>
            <v-icon middle color="#E84660" v-else>
              mdi-checkbox-blank-outline
            </v-icon>
          </v-col>
          <v-col cols="auto" class="ml-3" style="margin-top:1px">
            {{ termAgreeString }}
          </v-col>
        </v-row>

        <v-row
          @click.prevent.stop="() => (policyDialog = true)"
          class="term-policy pt-2"
          no-gutters
        >
          <v-col cols="auto">
            <v-icon middle color="#E84660" v-if="policy">
              mdi-checkbox-marked
            </v-icon>
            <v-icon middle color="#E84660" v-else>
              mdi-checkbox-blank-outline
            </v-icon>
          </v-col>
          <v-col cols="auto" class="ml-3" style="margin-top:1px">
            {{ policyAgreeString }}
          </v-col>
        </v-row>

        <Term
          :dialog="termDialog"
          :content="termText"
          @close:term="updateTerm($event)"
        ></Term>

        <Policy
          :dialog="policyDialog"
          :content="policyText"
          @close:policy="updatePolicy($event)"
        ></Policy>
      </v-form>
      <v-btn class="signup-button" @click="signUp">{{ signup }}</v-btn>
    </div>
  </v-card>
</template>

<script>
import Vue from 'vue';
import Icon from '@/components/Icon';
import Constant from '@/store/constant.js';
import Term from '@/components/dialog/TermDialog';
import Policy from '@/components/dialog/PolicyDialog';

export default {
  props: {
    source: String,
  },

  components: { Icon, Term, Policy },

  computed: {
    signUpTitle() {
      if (this.useOAuth)
        return Constant.STRING[this.$store.state.language].SIGNUP_TITLE_OAUTH;
      else return Constant.STRING[this.$store.state.language].SIGNUP_TITLE;
    },
    signup() {
      return Constant.STRING[this.$store.state.language].SIGNUP;
    },
    vcodeString() {
      return Constant.STRING[this.$store.state.language].VCODE;
    },
    emailString() {
      return Constant.STRING[this.$store.state.language].EMAIL;
    },
    passwordString() {
      return Constant.STRING[this.$store.state.language].PASSWORD;
    },
    nicknameString() {
      return Constant.STRING[this.$store.state.language].NICKNAME;
    },
    companyString() {
      return Constant.STRING[this.$store.state.language].COMPANY;
    },
    snsString() {
      return Constant.STRING[this.$store.state.language].SNS;
    },
    photoString() {
      return Constant.STRING[this.$store.state.language].PHOTO;
    },
    inputVcodeString() {
      return Constant.STRING[this.$store.state.language].INPUT_VCODE;
    },
    inputEmailString() {
      return Constant.STRING[this.$store.state.language].INPUT_EMAIL;
    },
    inputPasswordString() {
      return Constant.STRING[this.$store.state.language].INPUT_PASSWORD;
    },
    inputNicknameString() {
      return Constant.STRING[this.$store.state.language].INPUT_NICKNAME;
    },
    inputCompanyString() {
      return Constant.STRING[this.$store.state.language].INPUT_COMPANY;
    },
    inputSnsString() {
      return Constant.STRING[this.$store.state.language].INPUT_SNS;
    },
    formatIncorrectString() {
      return Constant.STRING[this.$store.state.language].FORMAT_INCORRECT;
    },
    fieldEmptyString() {
      return Constant.STRING[this.$store.state.language].FIELD_EMPTY;
    },
    duplicateEmailString() {
      return Constant.STRING[this.$store.state.language].DUPLICATE_EMAIL;
    },
    duplicateNicknameString() {
      return Constant.STRING[this.$store.state.language].DUPLICATE_NICKNAME;
    },
    signupFailString() {
      return Constant.STRING[this.$store.state.language].SIGNUP_FAIL;
    },
    termAgreeString() {
      return Constant.STRING[this.$store.state.language].TERM_AGREE;
    },
    policyAgreeString() {
      return Constant.STRING[this.$store.state.language].POLICY_AGREE;
    },
    nicknameRuleFrontString() {
      return Constant.STRING[this.$store.state.language].NICKNAME_RULE_FRONT;
    },
    nicknameRuleBackString() {
      return Constant.STRING[this.$store.state.language].NICKNAME_RULE_BACK;
    },
    spacesString() {
      return Constant.STRING[this.$store.state.language].SPACES;
    },
    imageSrc() {
      if (!this.image) {
        return require('@/assets/' + this.blankImageName);
      } else {
        const imageURL = URL.createObjectURL(this.image);
        return imageURL;
      }
    },
    useOAuth: function() {
      return this.$store.state.signupMethod !== 'password';
    },
  },

  mounted() {
    this.fetchTerm();
    this.fetchPolicy();
  },

  updated() {
    // Load user profile data from the store if the user signed in google
    if (this.useOAuth && this.$store.state.oauthProfile) {
      let profile = this.$store.state.oauthProfile;
      this.token = profile.token;
      this.username = profile.email;
      // fetch the user image (TODO error handling)
      Vue.axios
        .get(profile.imageURL, { responseType: 'blob' })
        .then(response => {
          this.image = new File([response.data], 'profile_image', {
            type: response.data.type,
          });
        });
      this.$store.commit('clearOAuthProfile');
    }
  },

  methods: {
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false;
        },
        { once: true },
      );

      this.$refs.uploader.click();
    },

    closeSignUpDialog() {
      this.$store.state.signupDialog = false;
    },

    closeDialog() {
      this.termDialog = false;
      this.policyDialog = false;
    },

    updateTerm(value) {
      if (value !== null) {
        this.term = value;
      }
      this.closeDialog();
    },

    updatePolicy(value) {
      if (value !== null) {
        this.policy = value;
      }
      this.closeDialog();
    },

    fetchTerm() {
      this.$store.dispatch('fetchTerm').then(data => {
        this.termText = data.text;
      });
    },

    fetchPolicy() {
      this.$store.dispatch('fetchPolicy').then(data => {
        this.policyText = data.text;
      });
    },

    onFileChanged(e) {
      this.image = e.target.files[0];
    },

    uploadImage() {
      alert('Boom');
    },

    validateForm() {
      let forms = [
        {
          field: this.emailString,
          value: this.username,
          rules: [this.emailValidation],
        },
        {
          field: this.passwordString,
          value: this.password,
          rules: [],
          allowBlank: this.useOAuth,
        },
        {
          field: this.nicknameString,
          value: this.nickname,
          rules: [this.nicknameRule],
        },
        // { field: this.vcodeString, value: this.vcode, rules: [] },
      ];

      for (let i = 0; i < forms.length; i++) {
        let form = forms[i];

        for (let j = 0; j < form.rules.length; j++) {
          let rule = form.rules[j];
          if (!rule(form.value)) {
            alert(form.field + this.formatIncorrectString);
            return false;
          }
        }
        if (!(form.allowBlank || form.value)) {
          alert(form.field + this.fieldEmptyString);
          return false;
        }
      }
      return true;
    },

    signUp() {
      if (this.validateForm()) {
        let image = this.image;
        let username = this.username;
        let password = this.password;
        let nickname = this.nickname;
        let vcode = this.vcode;
        let term = this.term;
        let policy = this.policy;
        let token = this.useOAuth ? this.token : null;

        let checkUnique = this.$store.dispatch('checkUnique', {
          username,
          nickname,
        });
        checkUnique.then(data => {
          if (!data.username) {
            alert(this.duplicateEmailString);
          } else if (!data.nickname) {
            alert(this.duplicateNicknameString);
          } else {
            this.$store
              .dispatch('signUp', {
                image,
                username,
                password,
                nickname,
                // vcode,
                term,
                policy,
                token,
              })
              .then(() => this.redirect())
              .catch(e => {
                console.log('signUp Failed', e);
                alert(this.signupFailString);
              });
          }
        });
      } else {
        console.log('Validation Failed');
      }
    },

    redirect() {
      this.closeSignUpDialog();
      if (this.$route.query.redirectTo) {
        this.$router.push(`${this.$route.query.redirectTo}`).catch(() => {});
      } else {
        this.$router.push('/').catch(() => {});
      }
    },

    requiredRule(value) {
      return !!value || 'This field is required';
    },

    alphaNumeric(value) {
      return (
        !!value.match(/^[0-9a-zA-Z]+$/) || 'This field must be alphanumeric'
      );
    },

    emailValidation(value) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(value).toLowerCase());
    },

    nicknameRule(value) {
      const blackListChars = [' ', '#', '@'];
      for (const char of blackListChars) {
        if (value.includes(char)) {
          alert(
            `${this.nicknameRuleFrontString} ${
              char !== ' ' ? char : this.spacesString
            }${this.nicknameRuleBackString}`,
          );
          return false;
        }
      }
      return true;
    },
  },

  data() {
    return {
      token: null, // OAuth token
      image: null,
      // vcode: '',
      username: '',
      password: '',
      nickname: '',
      term: false,
      policy: false,
      termDialog: false,
      policyDialog: false,
      termText: '',
      policyText: '',
      blankImageName: 'signup_user_photo.svg',
      isSelecting: false,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';

.signup-card {
  width: 440px;

  background: rgba(255, 255, 255, 0.95);
  border-radius: 10px !important;
}
.close-btn {
  margin-bottom: 2px;
}
.camera-btn {
  /* margin-top: -40px;
  padding-left: 80px; */
  margin-top: -55px;
  margin-left: 80px;
  /* max-height:28px;
  max-width:28px; */
  background: white;
  border: 1px solid #b0b0b0;
}
.signup-title {
  @extend .title-heavy;
  color: #3f3f3f !important;
  margin-bottom: 24px;
}
.signup-wrapper {
  padding: 30px;
}
.input-field {
  margin-bottom: 15px !important;
  width: 380px;
  @extend .subheader-light;
}
.text-area {
  margin-bottom: 15px !important;
  width: 380px;
  font-family: 'Noto Sans KR', sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.signup-button {
  width: 100%;
  height: 60px !important;
  background: linear-gradient(
    271.1deg,
    #e84660 0.46%,
    rgba(232, 70, 96, 0.85) 100%
  );
  border-radius: 5px;

  font-family: 'Noto Sans KR', sans-serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 18px !important;
  line-height: 27px;
  color: #ffffff !important;
  margin-top: 15px;
}

.v-text-field {
  &::v-deep .v-input__slot {
    padding: 0 !important;
  }

  &::v-deep .v-input__prepend-inner {
    padding: 0 !important;
    margin: 21px 12px !important;
  }

  &::v-deep .no-icon {
    margin: 5px;
  }
}

.v-input--checkbox {
  margin-top: 10px;
  padding: 0;
}

.term-policy {
  cursor: pointer;
  @extend .body-heavy;
}
</style>
