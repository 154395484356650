<template>
  <div class="editorwrapper">
    <div
      class="editor"
      id="editor"
      @dragover.stop.prevent="onDragOver"
      @dragleave.stop.prevent="onDragLeave"
      @drop.stop.prevent="onDrop($event, editor.commands.image)"
    >
      <editor-menu-bar v-slot="{ commands, isActive }" :editor="editor">
        <div class="menubar">
          <span v-for="actionName in activeButtons" :key="actionName">
            <button
              v-if="actionName === 'bold'"
              class="menubar__button"
              :class="{ 'is-active': isActive.bold() }"
              @click="commands.bold"
            >
              <icon name="bold" />
            </button>
            <button
              v-if="actionName === 'italic'"
              class="menubar__button"
              :class="{ 'is-active': isActive.italic() }"
              @click="commands.italic"
            >
              <icon name="italic" />
            </button>

            <button
              v-if="actionName === 'strike'"
              class="menubar__button"
              :class="{ 'is-active': isActive.strike() }"
              @click="commands.strike"
            >
              <icon name="strike" />
            </button>

            <button
              v-if="actionName === 'underline'"
              class="menubar__button"
              :class="{ 'is-active': isActive.underline() }"
              @click="commands.underline"
            >
              <icon name="underline" />
            </button>

            <button
              v-if="actionName === 'code'"
              class="menubar__button"
              :class="{ 'is-active': isActive.code() }"
              @click="commands.code"
            >
              <icon name="code" />
            </button>

            <button
              v-if="actionName === 'paragraph'"
              class="menubar__button"
              :class="{ 'is-active': isActive.paragraph() }"
              @click="commands.paragraph"
            >
              <icon name="paragraph" />
            </button>

            <button
              v-if="actionName === 'h1'"
              class="menubar__button"
              :class="{ 'is-active': isActive.heading({ level: 1 }) }"
              @click="commands.heading({ level: 1 })"
            >
              H1
            </button>

            <button
              v-if="actionName === 'h2'"
              class="menubar__button"
              :class="{ 'is-active': isActive.heading({ level: 2 }) }"
              @click="commands.heading({ level: 2 })"
            >
              H2
            </button>

            <button
              v-if="actionName === 'h3'"
              class="menubar__button"
              :class="{ 'is-active': isActive.heading({ level: 3 }) }"
              @click="commands.heading({ level: 3 })"
            >
              H3
            </button>

            <button
              v-if="actionName === 'bullet_list'"
              class="menubar__button"
              :class="{ 'is-active': isActive.bullet_list() }"
              @click="commands.bullet_list"
            >
              <icon name="ul" />
            </button>

            <button
              v-if="actionName === 'ordered_list'"
              class="menubar__button"
              :class="{ 'is-active': isActive.ordered_list() }"
              @click="commands.ordered_list"
            >
              <icon name="ol" />
            </button>

            <button
              v-if="actionName === 'blockquote'"
              class="menubar__button"
              :class="{ 'is-active': isActive.blockquote() }"
              @click="commands.blockquote"
            >
              <icon name="quote" />
            </button>

            <button
              v-if="actionName === 'code_block'"
              class="menubar__button"
              :class="{ 'is-active': isActive.code_block() }"
              @click="commands.code_block"
            >
              <icon name="code" />
            </button>

            <button
              v-if="actionName === 'horizontal_rule'"
              class="menubar__button"
              @click="commands.horizontal_rule"
            >
              <icon name="hr" />
            </button>

            <button
              v-if="actionName === 'undo'"
              class="menubar__button"
              @click="commands.undo"
            >
              <icon name="undo" />
            </button>

            <button
              v-if="actionName === 'redo'"
              class="menubar__button"
              @click="commands.redo"
            >
              <icon name="redo" />
            </button>

            <button
              v-if="actionName === 'image'"
              class="menubar__button"
              @click="showImagePrompt"
            >
              <icon name="image" />
              <input
                ref="uploader"
                class="d-none"
                type="file"
                accept="image/*"
                @change="onFileChanged($event, commands.image)"
              />
            </button>
          </span>
        </div>
      </editor-menu-bar>
      <editor-content
        :class="{
          editor__content: true,
          'post-editor': !isComment,
          'comment-editor': isComment,
        }"
        :editor="editor"
      />
    </div>

    <v-row class="pt-5" :class="isComment ? 'justify-end' : 'justify-start'">
      <v-col cols="auto">
        <v-btn
          class="submit-btn"
          depressed
          @click="submitPost"
          v-if="submitButton"
          >{{ isComment ? 'Post Comment' : 'Submit Post' }}</v-btn
        >
      </v-col>
      <v-col class="ml-5" cols="auto" v-if="cancelButton">
        <v-btn class="submit-btn" depressed @click="cancel"
          >Cancel</v-btn
        >
      </v-col>
    </v-row>

    <ProgressCircular
      :dialog="this.imageUploadInProgress"
      :message="'Uploading..'"
    ></ProgressCircular>

    <v-menu
      v-model="userPopup"
      min-width="245"
      max-width="245"
      max-height="120"
      absolute
      :position-x="popupX"
      :position-y="popupY + 20"
    >
      <div ref="userSuggestions">
        <v-list v-if="hasUserResults" class="usertag-list">
          <div v-for="user in filteredUsers" :key="'u' + user.id">
            <v-list-item @click="selectUser(user)">
              <v-list-item-content>
                <v-list-item-title
                  v-text="'@' + user.nickname"
                ></v-list-item-title>
              </v-list-item-content>
              <v-list-item-avatar>
                <v-img :src="user.image_url"></v-img>
              </v-list-item-avatar>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </v-list>
      </div>
    </v-menu>

    <v-menu
      v-model="tagPopup"
      min-width="300"
      max-width="300"
      max-height="150"
      absolute
      :position-x="popupX"
      :position-y="popupY + 20"
    >
      <div ref="tagSuggestions">
        <v-list v-if="hasTagResults" class="hashtag-list">
          <div v-for="tag in filteredTags" :key="'h' + tag.id">
            <v-list-item @click="selectTag(tag)">
              <v-list-item-content>
                <v-list-item-title v-text="'#' + tag.tag"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </v-list>
      </div>
    </v-menu>
  </div>
</template>

<script>
import ImageUpload from '@/plugins/ImageUpload';
import HashTag from '@/plugins/HashTag';
import Mention from '@/plugins/Mention';
import Icon from '@/components/Icon';
import ProgressCircular from '@/components/ProgressCircular';
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  Placeholder,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Image,
} from 'tiptap-extensions';

export default {
  name: 'Editor',

  components: {
    EditorContent,
    EditorMenuBar,
    Icon,
    ProgressCircular,
  },

  props: {
    initialContent: {
      type: String,
      required: true,
      default: '<em>editable text</em>',
    },
    placeholder: {
      type: String,
      default: '',
    },
    submitButton: Boolean,
    cancelButton: {
      type: Boolean,
      default: false,
    },
    isComment: {
      type: Boolean,
      default: false,
    },
    activeButtons: {
      type: Array,
      validator: function(list) {
        for (let el of list) {
          // The value must match one of these strings
          if (
            [
              'bold',
              'italic',
              'strike',
              'underline',
              'code',
              'paragraph',
              'h1',
              'h2',
              'h3',
              'bullet_list',
              'ordered_list',
              'blockquote',
              'code_block',
              'horizontal_rule',
              'undo',
              'redo',
            ].indexOf(el) === -1
          ) {
            return -1;
          }
        }
        return 1;
      },
      default: () => ['bold', 'italic'],
    },
  },

  data() {
    return {
      imageUploadInProgress: false,
      fileURLs: [],
      html: '',
      json: '',
      editor: new Editor({
        extensions: [
          new Placeholder({
            emptyEditorClass: 'is-editor-empty',
            emptyNodeClass: 'is-empty',
            emptyNodeText: '',
            showOnlyWhenEditable: true,
            showOnlyCurrent: true,
          }),
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Image(),
          new ImageUpload({
            uploader: image => {
              return this.uploadImage(image);
            },
          }),
          new Mention({
            // is called when a suggestion starts
            onEnter: ({ virtualNode, query, range, command }) => {
              this.query = query;
              this.suggestionRange = range;
              this.renderPopup(virtualNode, 'user');
              this.insertMention = command;
            },
            // is called when a suggestion has changed
            onChange: ({ virtualNode, query, range }) => {
              this.query = query;
              this.suggestionRange = range;
              this.renderPopup(virtualNode, 'user');
              this.fetchUsers(query);
            },
            // is called when a suggestion is cancelled
            onExit: () => {
              if (this.query) {
                const idx = this.filteredUsers.findIndex(
                  el => el.nickname === this.query,
                );

                if (idx !== -1) {
                  this.insertMention({
                    range: this.suggestionRange,
                    attrs: {
                      id: this.filteredUsers[idx].id,
                      label: this.query,
                    },
                  });
                }
              }

              this.query = null;
              this.filteredUsers = [];
              this.suggestionRange = null;
              this.destroyPopup();
            },
          }),
          new HashTag({
            onEnter: ({ virtualNode, query, range, command }) => {
              this.query = query;
              this.suggestionRange = range;
              this.renderPopup(virtualNode, 'tag');
              this.insertMention = command;
            },
            // is called when a suggestion has changed
            onChange: ({ virtualNode, query, range }) => {
              this.query = query;
              this.suggestionRange = range;
              this.renderPopup(virtualNode, 'tag');
              this.fetchTags(query);
            },
            // is called when a suggestion is cancelled
            onExit: () => {
              if (this.query) {
                const idx = this.filteredTags.findIndex(
                  el => el.tag === this.query,
                );
                this.insertMention({
                  range: this.suggestionRange,
                  attrs: {
                    id: idx === -1 ? idx : this.filteredTags[idx].id,
                    label: this.query,
                  },
                });
              }

              // reset all saved values
              this.query = null;
              this.filteredUsers = [];
              this.suggestionRange = null;

              this.destroyPopup();
            },
          }),
        ],
        content: this.initialContent,
      }),
      query: null,
      suggestionRange: null,
      filteredUsers: [],
      filteredTags: [],
      insertMention: () => {},
      userPopup: false,
      tagPopup: false,
      popupX: 0,
      popupY: 0,
    };
  },

  computed: {
    hasUserResults() {
      return this.filteredUsers.length;
    },
    hasTagResults() {
      return this.filteredTags.length;
    },
  },

  watch: {
    placeholder() {
      this.editor.extensions.options.placeholder.emptyNodeText = this.placeholder;
    },
  },

  mounted() {
    this.editor.extensions.options.placeholder.emptyNodeText = this.placeholder;
  },

  beforeDestroy() {
    this.editor.destroy();
    this.destroyPopup();
  },

  methods: {
    onDragOver() {
      let editor = document.getElementById('editor');
      editor.classList.add('hover');
    },

    onDragLeave() {
      let editor = document.getElementById('editor');

      if (editor.classList.contains('hover')) {
        editor.classList.remove('hover');
      }
    },

    async onDrop(e, command) {
      let editor = document.getElementById('editor');
      if (editor.classList.contains('hover')) {
        editor.classList.remove('hover');
      }

      let file = e.dataTransfer.files[0];
      const imageSrc = await this.uploadImage(file);
      if (imageSrc !== null) {
        command({ src: imageSrc });
      }
    },

    async onFileChanged(e, command) {
      let file = e.target.files[0];
      const imageSrc = await this.uploadImage(file);
      if (imageSrc !== null) {
        command({ src: imageSrc });
      }
    },

    showImagePrompt() {
      this.$refs.uploader[0].click();
    },

    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },

    async updateImageSrc(img) {
      if (img.src.startsWith('data:')) {
        let ext = img.src.split(';')[0].split('/')[1];
        let file = this.dataURLtoFile(img.src, `temp.${ext}`);
        const imageSrc = await this.uploadImage(file);
        img.src = imageSrc;
      }
    },

    async replaceImageToURL(html) {
      let parser = new DOMParser();
      let htmlDoc = parser.parseFromString(html, 'text/html');
      let imgs = Array.from(htmlDoc.getElementsByTagName('img'));
      const promises = imgs.map(this.updateImageSrc);
      return Promise.all(promises).then(() => {
        return htmlDoc.documentElement.innerHTML;
      });
    },

    submitPost() {
      let fileURLs = JSON.stringify(this.fileURLs);
      this.html = this.editor.getHTML();
      this.replaceImageToURL(this.html).then(html => {
        this.$emit('submit', html, fileURLs);
      });
    },

    cancel() {
      this.$emit('cancel');
    },

    clearContent() {
      this.editor.clearContent();
      this.fileURLs = [];
    },

    async uploadImage(file) {
      if (file.size / 1024 / 1024 > 5) {
        alert('image file size cannot be larger than 5MB');
        return;
      }

      if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
        alert('unsupported file type');
        return;
      }

      this.imageUploadInProgress = true;

      return this.$store
        .dispatch('uploadFile', { file })
        .then(data => {
          this.fileURLs.push({ url: data.url });
          return data.url;
        })
        .catch(e => alert(e))
        .finally(() => {
          this.imageUploadInProgress = false;
        });
    },

    selectUser(user) {
      this.destroyPopup();
      this.insertMention({
        range: this.suggestionRange,
        attrs: {
          id: user.id,
          label: user.nickname,
        },
      });
      this.editor.focus();
    },

    selectTag(tag) {
      this.destroyPopup();
      this.insertMention({
        range: this.suggestionRange,
        attrs: {
          id: tag.id,
          label: tag.tag,
        },
      });

      this.editor.focus();
    },

    renderPopup(node, mentionType) {
      const boundingClientRect = node.getBoundingClientRect();
      const { x, y } = boundingClientRect;
      if (x === 0 && y === 0) {
        return;
      }

      if (mentionType === 'user') {
        if (this.userPopup) return;
        this.userPopup = true;
      } else if (mentionType === 'tag') {
        if (this.tagPopup) return;
        this.tagPopup = true;
      }
      this.popupX = x;
      this.popupY = y;
    },

    destroyPopup() {
      this.query = null;
      this.filteredUsers = [];
      this.filteredTags = [];
      this.userPopup = false;
      this.tagPopup = false;
    },

    async fetchUsers(query) {
      if (!query) {
        this.filteredUsers = [];
        return;
      }

      this.$store
        .dispatch('fetchUserTags', { query })
        .then(data => {
          this.filteredUsers = data;
        })
        .catch(e => alert(e));
    },

    async fetchTags(query) {
      if (!query) {
        this.filteredTags = [];
        return;
      }

      this.$store
        .dispatch('fetchHashTags', { query })
        .then(data => {
          this.filteredTags = data;
        })
        .catch(e => alert(e));
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/editor/main.scss';
@import '@/assets/sass/global.scss';
.mention-suggestion {
  color: rgba($color-black, 0.6);
}
.hashtag-suggestion {
  color: rgba($color-black, 0.6);
}

.usertag-list {
  padding: 0;

  .v-list-item {
    margin: 0;
    padding: 0;

    &__content {
      padding: 15px;
    }

    &__title {
      @extend .body-heavy;
    }

    &__avatar {
      margin: 10px 30px;
    }
  }

  .v-divider {
    margin: 0 15px;
    border-color: rgba(0, 0, 0, 0.25);
  }
}

.hashtag-list {
  padding: 0;

  .v-list-item {
    padding: 0;
    margin: 0;

    &__content {
      padding: 15px;
    }
  }

  .v-divider {
    margin: 0 15px;
    border-color: rgba(0, 0, 0, 0.25);
  }
}
.post-editor {
  overflow-y: scroll !important;
  height: 600px !important;
  padding: 16px;
}
.comment-editor {
  overflow-y: scroll !important;
  height: 200px !important;
  padding: 16px;
}
.submit-btn {
  background: linear-gradient(
    271.1deg,
    #e84660 0.46%,
    rgba(232, 70, 96, 0.85) 100%
  );
  border-radius: 5px;
  @extend .body-heavy;
  text-transform: none;
  letter-spacing: normal;
  color: #ffffff !important;
  &-comment {
    display: flex;
    justify-content: flex-end;
  }
}
.hover {
  background: linear-gradient(
    45deg,
    #e846612c 25%,
    white 0,
    white 50%,
    #e846612c 0,
    #e846612c 75%,
    white 0
  );
  -webkit-background-size: 30px 30px;
  background-size: 30px 30px;
}

.editor .is-editor-empty {
  color: rgba(0, 0, 0, 0.35) !important;
}
</style>
