import '@fortawesome/fontawesome-free/css/all.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import Jupyter from '@/components/icon/Jupyter.vue';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      jupyter: {
        // name of our custom icon
        component: Jupyter, // our custom component
      },
    },
    // iconfont: 'fa',
  },
});
