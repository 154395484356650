<template>
  <div
    class="page-inner"
    name="discussion"
    :class="type === 'Discussion' ? 'page-padding' : ''"
  >
    <div class="back-to-list" @click="routeBack">
      <v-icon color="rgba(0, 0, 0, 0.87)">mdi-chevron-left</v-icon>Back To List
    </div>
    <div v-if="isAdmin">
      <v-card-title class="pa-0"><h5>Post Type</h5></v-card-title>
      <v-select
        v-model="type"
        :items="[qaString, noticeString]"
        style="width: 200px;"
      ></v-select>
    </div>

    <v-card-title class="pa-0"><h5>Title</h5></v-card-title>
    <v-card-text class="pa-0"
      >Be specific and imagine you’re asking a question to another
      person</v-card-text
    >
    <div class="title" v-if="(editMode && title) || !editMode">
      <v-text-field
        v-model="title"
        dense
        outlined
        hide-details
        autofocus
        @keydown.tab.prevent="$refs.editor.editor.view.dom.focus()"
      ></v-text-field>
    </div>
    <v-card-title class="pa-0"><h5>Body</h5></v-card-title>
    <v-card-text class="pa-0"
      >Include all the information someone would need to answer your
      question</v-card-text
    >
    <div
      @click="$refs.editor.editor.view.dom.focus()"
      v-if="(editMode && content) || !editMode"
    >
      <editor
        ref="editor"
        :initial-content="content"
        :active-buttons="[
          'bold',
          'italic',
          'strike',
          'underline',
          'code',
          'paragraph',
          'h1',
          'h2',
          'h3',
          'bullet_list',
          'ordered_list',
          'blockquote',
          'code_block',
          'horizontal_rule',
          'undo',
          'redo',
          'image',
        ]"
        :submitButton="true"
        @submit="submit"
      />
    </div>
  </div>
</template>

<script>
import Editor from '@/components/Editor';
import Constant from '@/store/constant.js';

export default {
  name: 'DiscussionEditor',

  props: {
    id: String,
    postID: {
      type: String,
      default: null,
    },
  },

  components: {
    Editor,
  },

  computed: {
    noticeString() {
      return Constant.STRING[this.$store.state.language].NOTICE;
    },
    qaString() {
      return Constant.STRING[this.$store.state.language].QA;
    },
    content() {
      if (this.editMode) {
        return this.post.body;
      } else {
        return '';
      }
    },
    isCompetition() {
      if(this.$route.name === 'CompetitionEditor' || this.$route.name === 'TaskDiscussionEditor') {
        return true;
      } else {
        return false;
      }
    },

    submit() {
      return this.editMode ? this.editPost : this.commitPost;
    },

    isAdmin() {
      return this.$store.state.userType.toLowerCase() === 'admin';
    },
  },

  methods: {
    routeBack() {
      if(this.isCompetition) {
        if (this.isCompetition) {
          let path = this.$route.query.back;
          let query = this.$route.query;
          delete query.back;
          this.$router.push({path, query});
        } else {
          this.$router.push({ name: 'Ustage', params: { id: this.id } });
        }
      }
    },
    commitPost(body, fileURLs) {
      let payload = {
        title: this.title,
        body: body,
        fileURLs: fileURLs,
        type:
          this.type === this.qaString ? this.postType.QA : this.postType.NOTICE,
      };

      if (!this.isCompetition) payload['discussionID'] = this.id;
      else payload['competitionID'] = this.id;

      this.$store
        .dispatch('commitPost', payload)
        .then(() => {
          this.routeBack();
        })
        .catch(e => alert(e));
    },

    editPost(body, fileURLs) {
      let payload = {
        type:
          this.type === this.qaString ? this.postType.QA : this.postType.NOTICE,
        title: this.title,
        body: body,
        fileURLs: fileURLs,
        postID: this.post.id,
      };
      return this.$store
        .dispatch('editPost', payload)
        .then(() => {
          this.routeBack();
        })
        .catch(e => alert(e));
    },

    fetchPost(postID) {
      this.$store
        .dispatch('fetchPost', { postID })
        .then(data => {
          this.post = data;
          this.title = this.post.title;
          this.type =
            this.post.type === this.postType.QA
              ? this.qaString
              : this.noticeString;
        })
        .catch(e => alert(e));
    },
  },

  data() {
    return {
      title: '',
      editMode: false,
      post: {},
      type: '',
      postType: Constant.TYPE.POST,
    };
  },

  created() {
    this.type = this.qaString;
    if (this.postID) {
      this.editMode = true;
      this.fetchPost(this.postID);
    }
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';
#discussion {
  display: flex;
  padding-top: 100px;
  box-sizing: border-box;
  flex-flow: column;
  align-items: center;
  font-family: -apple-system, 'Noto Sans', 'Helvetica Neue', Helvetica,
    'Nimbus Sans L', Arial, 'Liberation Sans', 'PingFang SC', 'Hiragino Sans GB',
    'Noto Sans CJK SC', 'Source Han Sans SC', 'Source Han Sans CN',
    'Microsoft YaHei', 'Wenquanyi Micro Hei', 'WenQuanYi Zen Hei', 'ST Heiti',
    SimHei, 'WenQuanYi Zen Hei Sharp', sans-serif;
}
.page-inner {
  width: 100%;
}

.title {
  padding: 0px;
}
.back-to-list {
  @extend .subheader-heavy;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 25px;
  cursor: pointer;
}
</style>
