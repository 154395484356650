<template>
  <div v-if="content">
    <v-dialog
      ref="dialog"
      hide-overlay
      @keydown.esc.prevent="buttonClicked(null)"
      @click:outside="buttonClicked(null)"
      :value="dialog"
      width="500"
    >
      <v-card class="dialog">
        <div class="dialog-top">
          <v-icon @click="buttonClicked(null)">mdi-close</v-icon>
        </div>
        <div class="dialog-row">
          <div class="dialog-subtitle">{{modelNameString}}</div>
          <div class="dialog-content">{{content.model}}</div>
        </div>
        <div class="dialog-row" v-if="content.url">
          <div class="dialog-subtitle">{{urlString}}</div>
          <div class="dialog-content">
            <a :href="content.url" target="_blank">{{content.url}}</a>
          </div>
        </div>
        <div class="dialog-row" v-if="content.size">
          <div class="dialog-subtitle">{{modelSizeString}}</div>
          <div class="dialog-content">{{content.size}}</div>
        </div>
        <div class="dialog-row" v-if="content.license">
          <div class="dialog-subtitle">{{modelLicenseString}}</div>
          <div class="dialog-content">{{content.license}}</div>
        </div>
        <div class="dialog-row">
          <div class="dialog-subtitle">{{inferenceString}}</div>
          <div class="dialog-content">{{content.inference}} {{secondString}}</div>
        </div>
        <div class="dialog-row">
          <div class="dialog-subtitle">{{descriptionString}}</div>
          <div class="dialog-content">{{content.description}}</div>
        </div>
        <div class="dialog-row" v-if="isMultiple">
          <div class="dialog-subtitle">{{taskRankingString}}</div>
          <div class="dialog-content">
            <table class="rank-table">
              <thead>
                <tr class="table-top-header">
                  <th 
                    v-for="header in upperTableTopHeaders" 
                    :key="header"
                    :colspan="headers.filter(el => el.text.includes(header)).length"
                  >{{header}}</th>
                </tr>
                <tr class="table-header">
                  <th
                    v-for="header in upperTableHeaders"
                    :key="header.text"
                  >
                    <span v-if="header.text.includes(' ')">
                      {{capitalize(header.text.split('\\')[1].split(' ')[1])}}
                      <sup>{{superString(header.text.split('\\')[1])}}</sup>
                    </span>
                    <span v-else>{{header.text.split('\\')[1]}}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td v-for="(data, idx) in upperTableRank" :key="idx">{{data}}</td>
                </tr>
              </tbody>
            </table>
            <table class="rank-table">
              <thead>
                <tr class="table-top-header">
                  <th 
                    v-for="header in lowerTableTopHeaders" 
                    :key="header"
                    :colspan="headers.filter(el => el.text.includes(header)).length"
                  >{{header}}</th>
                </tr>
                <tr class="table-header">
                  <th
                    v-for="header in lowerTableHeaders"
                    :key="header.text"
                  >
                    <span v-if="header.text.includes(' ')">
                      {{capitalize(header.text.split('\\')[1].split(' ')[1])}}
                      <sup>{{superString(header.text.split('\\')[1])}}</sup>
                    </span>
                    <span v-else>{{header.text.split('\\')[1]}}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td v-for="(data, idx) in lowerTableRank" :key="idx">{{data}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Constant from '@/store/constant.js';
export default {
  name: 'SimpleDialog',
  props: {
    dialog: Boolean,
    headers: Array,
    topHeaders: Array,
    content: Object,
    records: Array,
    isMultiple: Boolean,
  },
  computed: {
    modelNameString() {
      return Constant.STRING[this.$store.state.language].MODEL_NAME;
    },
    urlString() {
      return Constant.STRING[this.$store.state.language].URL;
    },
    modelSizeString() {
      return Constant.STRING[this.$store.state.language].MODEL_SIZE;
    },
    modelLicenseString() {
      return Constant.STRING[this.$store.state.language].MODEL_LICENSE;
    },
    descriptionString() {
      return Constant.STRING[this.$store.state.language].DESCRIPTION;
    },
    inferenceString() {
      return Constant.STRING[this.$store.state.language].COMPETITION_INFERENCE_TIME;
    },
    secondString() {
      return Constant.STRING[this.$store.state.language].SECOND;
    },
    upperTableTopHeaders() {
      return this.topHeaders.slice(4, 8);
    },
    lowerTableTopHeaders() {
      return this.topHeaders.slice(8);
    },
    upperTableHeaders() {
      return this.headers.filter(el => 
        this.upperTableTopHeaders.includes(el.text.split('\\')[0])
      );
    },
    lowerTableHeaders() {
      return this.headers.filter(el => 
        this.lowerTableTopHeaders.includes(el.text.split('\\')[0])
      );
    },
    upperTableRank() {
      return this.upperTableHeaders.map(el => {
        let value = this.content[el.value].value;
        let rank = 1;
        this.records.forEach(record => {
          if(record[el.value].value > value) rank++;
        })
        return this.ordinalSuffixOf(rank);
      })
    },
    lowerTableRank() {
      return this.lowerTableHeaders.map(el => {
        let value = this.content[el.value].value;
        let rank = 1;
        this.records.forEach(record => {
          if(record[el.value].value > value) rank++;
        })
        return this.ordinalSuffixOf(rank);
      })
    },
    taskRankingString() {
      return Constant.STRING[this.$store.state.language].INDIVIDUAL_TASK_RANKING;
    },
  },
  mounted() {
  },
  methods: {
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    superString(string) {
      if(string.includes('micro')) return 'mic';
      return string[0].toUpperCase();
    },
    buttonClicked(value) {
      this.$emit('close:simple', value);
    },
    ordinalSuffixOf(i) {
      let j = i % 10, k = i % 100;
      if (j == 1 && k != 11) {
          return i + "st";
      }
      if (j == 2 && k != 12) {
          return i + "nd";
      }
      if (j == 3 && k != 13) {
          return i + "rd";
      }
      return i + "th";
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/global.scss';
sup {
  display: inline-block;
  font-size: 10px;
  transform: translate(-3px, 0) scale(0.8);
  font-style: italic;
}

.dialog {
  padding: 48px 30px 32px 30px;
  background: #FBF8F7;
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.dialog-top {
  color: #3f3f3f;
  position: absolute;
  top: 48px;
  right: 30px;
}

.dialog-row {
  margin-bottom: 32px;
  .dialog-subtitle {
    @extend .body-heavy;
    margin-bottom: 4px;
  }

  .dialog-content {
    @extend .body-light;
    text-align: justify;
  }
}

.rank-table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  margin-top: 8px;
  margin-bottom: 16px;

  thead {
    background: #D3C7C0;
    height: 70px;

    .table-top-header {
      color: rgba(81, 81, 81, 0.75);
      th {
        @extend .caption-heavy;
      }
    }

    .table-header {
      color: #515151;
      
      th {
        @extend .caption-light;
      }
    }
  }

  tbody {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    tr {
      height: 40px;
      color: #515151;
      
      td {
        @extend .caption-light;
      }
    }
  }
}

</style>