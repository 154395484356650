<template>
  <v-card class="sign-selection-card elevation-0">
    <div class="d-flex justify-end">
      <v-btn icon @click="closeSignDialog">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-tabs v-model="activeTab" background-color="transparent">
      <v-tabs-slider></v-tabs-slider>
      <v-tab key="0">{{ signin }}</v-tab>
      <v-tab key="1">{{ signup }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="activeTab">
      <v-tab-item key="0">
        <v-btn class="elevation-0" @click="googleSignIn">
          <img :src="require('@/assets/images/icons/google.svg')" />
          <span>{{ signinWithGoogle }}</span>
        </v-btn>
        <!-- <v-btn class="elevation-0" @click="popupPasswordSignInDialog">
          <img :src="require('@/assets/images/icons/email.svg')" />
          <span>{{ signinWithPassword }}</span>
        </v-btn> -->
        <div class="last-option">
          {{ noAccount }}
          <span class="option-link" @click="activeTab = 1">{{
            signupTitle
          }}</span>
        </div>
      </v-tab-item>
      <v-tab-item key="1">
        <v-btn class="elevation-0" @click="googleSignUp">
          <img :src="require('@/assets/images/icons/google.svg')" />
          <span>{{ signupWithGoogle }}</span>
        </v-btn>
        <!-- <v-btn class="elevation-0" @click="popupPasswordSignUpDialog">
          <img :src="require('@/assets/images/icons/email.svg')" />
          <span>{{ signupWithPassword }}</span>
        </v-btn> -->
        <div class="last-option">
          {{ haveAnAccount }}
          <span class="option-link" @click="activeTab = 0">{{
            signinTitle
          }}</span>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import Constant from '@/store/constant.js';

export default {
  methods: {
    closeSignDialog() {
      this.$store.commit('setSignDialog', false);
    },

    popupSignUpDialog(method) {
      this.$store.commit('setSignUpMethod', { method });
      this.$store.commit('setSignDialog', false);
      this.$store.state.signupDialog = true;
    },

    popupPasswordSignInDialog() {
      this.$store.commit('setSignDialog', false);
      this.$store.state.signinDialog = true;
    },

    popupPasswordSignUpDialog() {
      this.popupSignUpDialog('password');
    },

    googleSignIn() {
      // Popup the Google OAuth window.
      // If the user successfully login, it returns googleUser.
      this.$gAuth
        .signIn()
        .then(googleUser => {
          // The ID token you need to pass to your backend:
          let id_token = googleUser.getAuthResponse().id_token;

          // Check whether the user already signed up
          this.$store
            .dispatch('signInWithGoogle', { token: id_token })
            .then(succeed => {
              if (succeed) {
                this.$store.commit('setSignDialog', false);
                this.$router.push('/').catch(() => {});
              } else {
                console.log('Google singin failed.');
                alert('로그인에 실패했습니다.');
              }
            });
        })
        .catch(error => {
          console.log('Google OAuth error: ' + error.error);
        });
    },

    googleSignUp() {
      // Popup the Google OAuth window.
      // If the user successfully login, it returns googleUser.
      this.$gAuth
        .signIn()
        .then(googleUser => {
          // Useful data for your client-side scripts:
          let profile = googleUser.getBasicProfile();

          // The ID token you need to pass to your backend:
          let id_token = googleUser.getAuthResponse().id_token;

          // Check whether the user already signed up
          this.$store
            .dispatch('signInWithGoogle', { token: id_token })
            .then(succeed => {
              if (succeed) {
                // The user already signed up, just close this dialog.
                this.$store.commit('setSignDialog', false);
                this.$router.push('/').catch(() => {});
              } else {
                // signup required
                this.$store.commit('setOAuthProfile', {
                  token: id_token,
                  email: profile.getEmail(),
                  imageURL: profile.getImageUrl(),
                });
                this.popupSignUpDialog('google');
              }
            });
        })
        .catch(error => {
          console.log('Google OAuth error: ' + error.error);
        });
    },
  },

  computed: {
    activeTab: {
      get: function() {
        return this.$store.state.signDialogActiveTab;
      },
      set: function(newValue) {
        this.$store.commit('setSignDialogActiveTab', { activeTab: newValue });
      },
    },
    signin() {
      return Constant.STRING[this.$store.state.language].SIGNIN;
    },
    signup() {
      return Constant.STRING[this.$store.state.language].SIGNUP;
    },
    signinTitle() {
      return Constant.STRING[this.$store.state.language].SIGNIN_TITLE;
    },
    signupTitle() {
      return Constant.STRING[this.$store.state.language].SIGNUP_TITLE;
    },
    signinWithGoogle() {
      return Constant.STRING[this.$store.state.language].SIGNIN_WITH_GOOGLE;
    },
    signinWithPassword() {
      return Constant.STRING[this.$store.state.language].SIGNIN_WITH_PASSWORD;
    },
    signupWithGoogle() {
      return Constant.STRING[this.$store.state.language].SIGNUP_WITH_GOOGLE;
    },
    signupWithPassword() {
      return Constant.STRING[this.$store.state.language].SIGNUP_WITH_PASSWORD;
    },
    noAccount() {
      return Constant.STRING[this.$store.state.language].NO_ACCOUNT;
    },
    haveAnAccount() {
      return Constant.STRING[this.$store.state.language].HAVE_AN_ACCOUNT;
    },
  },

  data() {
    return {
      signUpTitle: Constant.STRING[process.env.VUE_APP_LANG].SIGNUP_TITLE,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';
.sign-selection-card {
  width: 440px;

  background: rgba(255, 255, 255, 0.95);
  border-radius: 10px;

  padding: 30px 30px 44px 30px;
}

.v-tabs {
  background: transparent;
}
.v-tabs-slider {
  background: #e84661;
  height: 5px;
}
.v-tab {
  text-transform: none;

  @extend .subheader-heavy;

  color: #3f3f3f !important;
}

.v-tabs-items {
  background: transparent !important;
  padding-top: 39px;
}

.tab-card {
  padding-top: 44px;
}

$btn-icon-width: 24px;
$btn-text-padding-left: 45px;

.v-tabs-items {
  .v-btn {
    width: 380px;
    height: 56px !important;

    margin-top: 15px;

    text-transform: none;
    @extend .body-heavy;
    letter-spacing: 0;

    background: #ffffff;
    border: 1px solid #a3a3a3;
    box-sizing: border-box;
    border-radius: 5px;
    color: #9e9e9e;

    justify-content: left;

    img {
      width: $btn-icon-width;
    }
    span {
      padding-left: $btn-text-padding-left;
    }
    img ~ span {
      padding-left: $btn-text-padding-left - $btn-icon-width;
    }
  }
}

.last-option {
  padding-top: 26px;
  text-align: center;

  @extend .body-heavy;
  text-align: center;

  color: #000000;
}
.option-link {
  color: #e84661;
  text-decoration: none;
  padding: 0px;
}
.option-link:hover {
  cursor: pointer;
}
</style>
