import { Mark, Plugin } from 'tiptap';
export default class Tag extends Mark {
  get name() {
    return 'tag';
  }
  get schema() {
    return {
      attrs: {
        class: {
          default: 'hashtag',
        },
        'data-hashtag-id': {
          default: 0,
        },
        'data-mention-id': {
          default: 0,
        },
      },
      inclusive: false,
      parseDOM: [
        {
          tag: 'span[class]',
          getAttrs: dom => ({
            class: dom.getAttribute('class'),
            'data-hashtag-id': dom.getAttribute('data-hashtag-id'),
            'data-mention-id': dom.getAttribute('data-mention-id'),
          }),
        },
      ],
      toDOM: node => [
        'span',
        {
          ...node.attrs,
        },
        0,
      ],
    };
  }

  get plugins() {
    return [
      new Plugin({
        props: {
          handleClick(view, pos, event) {
            if (
              event.srcElement.className !== 'mention' &&
              event.srcElement.className !== 'hashtag'
            ) {
              return;
            }
            const span_type =
              event.srcElement.className === 'mention' ? 'usertag' : 'hashtag';
            const text = event.srcElement.innerText.substring(1);

            const default_url = window.location.href.substring(
              0,
              window.location.href.lastIndexOf('post') - 1,
            );
            const url = default_url + `?${span_type}=${text}`;
            window.location.replace(url);
          },
        },
      }),
    ];
  }
}
