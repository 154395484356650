import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Team from '../views/Team.vue';
import Sponsor from '../views/Sponsor.vue';
import Organizer from '../views/Organizer.vue';
import Tasks from '../views/Tasks.vue';
import Leaderboard from '../views/Leaderboard.vue';
import SignIn from '../views/SignIn.vue';
import SignUp from '../views/SignUp.vue';

import TaskDetail from '../views/task/TaskDetail.vue';
import TaskInner from '../views/task/Inner.vue';
import TaskCode from '../views/task/Code.vue';
import TaskNewSubmission from '../views/task/NewSubmission.vue';
import TaskMySubmission from '../views/task/MySubmission.vue';
import TaskJobLog from '../views/task/JobLog.vue';
import TaskRecord from '../views/task/Record.vue';
import TaskLeaderboardDescription from '../views/task/LeaderboardDescription.vue';
import TaskLeaderboard from '../views/task/Leaderboard.vue';
import TaskDiscussion from '../views/task/Discussion.vue';

import DiscussionEditor from '../views/discussion/Editor.vue';
import DiscussionPost from '../views/discussion/CompactPost.vue';

import store from '../store/index.js';

const requireAuth = () => (from, to, next) => {
  const isAuthenticated = store.state.token !== null;
  if (isAuthenticated) return next();
  next(`/signin?redirectTo=${from.fullPath}`);
};

Vue.use(VueRouter);

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/team',
    name: 'Team',
    component: Team,
  },
  {
    path: '/sponsors',
    name: 'Sponsor',
    component: Sponsor,
  },
  {
    path: '/organizers',
    name: 'Organizer',
    component: Organizer,
  },
  {
    path: '/tasks',
    name: 'Task',
    component: Tasks,
  },
  {
    path: '/tasks/:id',
    name: 'TaskDetail',
    component: TaskDetail,
    props: true,
    children: [{
        path: 'overview/description',
        name: 'TaskOverviewDescription',
        component: TaskInner,
        props: true
      },
      {
        path: 'overview/evaluation',
        name: 'TaskOverviewEvaluation',
        component: TaskInner,
        props: true
      },
      {
        path: 'overview/total',
        name: 'TaskOverviewTotal',
        component: TaskInner,
        props: true
      },
      {
        path: 'overview/copyright',
        name: 'TaskOverviewCopyright',
        component: TaskInner,
        props: true
      },
      {
        path: 'data/description',
        name: 'TaskDataDescription',
        component: TaskInner,
        props: true
      },
      {
        path: 'data/download',
        name: 'TaskDataDownload',
        component: TaskCode,
        props: true
      },
      {
        path: 'submission/new',
        name: 'TaskSubmissionNew',
        component: TaskNewSubmission,
        props: true
      },
      {
        path: 'submission/my',
        name: 'TaskSubmissionMy',
        component: TaskMySubmission,
        props: true
      },
      {
        path: 'submission/log',
        name: 'TaskSubmissionLog',
        component: TaskJobLog,
        props: true
      },
      {
        path: 'submission/log/:submissionID',
        name: 'TaskSubmissionLog',
        component: TaskJobLog,
        props: true
      },
      {
        path: 'record/task',
        name: 'TaskRecordTask',
        component: TaskRecord,
        props: true
      },
      {
        path: 'leaderboard/description',
        name: 'TaskLeaderboardDescription',
        component: TaskLeaderboardDescription,
        props: true
      },
      {
        path: 'leaderboard/task',
        name: 'TaskLeaderboardTask',
        component: TaskLeaderboard,
        props: true
      },
      {
        path: 'discussion/notice',
        name: 'TaskDiscussionNotice',
        component: TaskDiscussion,
        props: true
      },
      {
        path: 'discussion/discussion',
        name: 'TaskDiscussionDiscussion',
        component: TaskDiscussion,
        props: true
      },
      {
        path: 'discussion/edit',
        name: 'TaskDiscussionEditor',
        component: DiscussionEditor,
        props: true
      },
      {
        path: 'discussion/edit/:postID',
        name: 'TaskDiscussionEditor',
        component: DiscussionEditor,
        props: true
      },
      {
        path: 'discussion/post/:postID',
        name: 'TaskDiscussionPost',
        component: DiscussionPost,
        props: true
      },
    ],
    // beforeEnter: requireAuth(),
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    component: Leaderboard,
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn,
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;