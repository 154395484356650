<template>
  <div class="organizer-container">
    <div class="organizer-title-row">{{organizersTitleString}}</div>
    <div v-for="(innerOrganizer, type) in organizers" :key="type" class="organizer-type-row">
      <div class="organizer-subtitle-row">
        <hr class="line" />
        <div class="organizer-subtitle-text">{{type}}</div>
        <hr class="line" />
      </div>
      <div class="organizer-content-row">
        <organizer-member 
          class="organizer-content"
          v-for="organizer in innerOrganizer"
          :key="organizer.name"
          :organization="Object.assign(organizer)"
        ></organizer-member>
      </div>
    </div>
  </div>
</template>

<script>
import Constant from '@/store/constant.js';
import OrganizerMember from '@/components/OrganizationMember.vue';
export default {
  name: 'Organizer',
  components: { OrganizerMember },
  computed: {
    organizersTitleString() {
      return Constant.STRING[this.$store.state.language].KLUE_ORGANIZERS_TITLE;
    },
  },
  watch: {
    '$route.name'() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    window.scrollTo (0, 0);
  },
  data() {
    return {
      organizers: {
        Host: [
          {name: 'upstage', text: 'Upstage', url: 'https://www.upstage.ai'},
        ],
        "Co- Organizers": [
          {name: 'naver_ai_labs', text: 'Naver AI Labs', url: 'https://naver-career.gitbook.io/kr/business/clova-cic'},
          {name: 'newyork', text: 'New York University', url: 'https://www.nyu.edu'},
          {name: 'kaist', text: 'KAIST', url: 'https://www.kaist.ac.kr'},
        ],
        "Research Collaborators": [
          {name: 'kakao_enterprise', text: 'Kakao Enterprise', url: 'https://www.kakaoenterprise.com'},
          {name: 'scatter_lab', text: 'Scatter Lab', url: 'https://scatterlab.co.kr'},
          {name: 'riiid', text: 'Riiid', url: 'https://riiid.co'},
          {name: 'seoul', text: 'Seoul National University', url: 'https://www.snu.ac.kr'},
          {name: 'yonsei', text: 'Yonsei University', url: 'https://www.yonsei.ac.kr'},
          {name: 'sogang', text: 'Sogang University', url: 'https://www.sogang.ac.kr'},
          {name: 'kyunghee', text: 'Kyung Hee University', url: 'https://www.khu.ac.kr'},
          {name: 'hanbat', text: 'Hanbat National University', url: 'https://www.hanbat.ac.kr'},
        ],
      },
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';

.organizer-container {
  width: 1084px;
  margin: 0 auto;
  margin-top: 188px;
  margin-bottom: 72px;
}

.organizer-title-row {
  @extend .subheadline-heavy;
  text-align: center;
  margin-bottom: 16px;
}

.organizer-type-row {
  margin: 40px 0;

  .organizer-subtitle-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .line {
      flex: 1;
    }

    .organizer-subtitle-text {
      @extend .subheader-heavy;
      margin: 0 90px;
    }
  }

  .organizer-content-row {
    display: flex;
    flex-wrap: wrap;

    .organizer-content {
      margin-right: 50px;
    }

    .organizer-content:nth-child(3n) {
      margin-right: 20px !important;
    }
  }
}

</style>