var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"joblog-container"},[_c('link',{attrs:{"rel":"stylesheet","type":"text/css","href":"//fonts.googleapis.com/css?family=Ubuntu+Mono"}}),_c('simple-dialog',{ref:"dialog",attrs:{"dialog":_vm.dialog,"title":_vm.popupTitle,"content":_vm.popupContent},on:{"close:simple":_vm.closePopup}}),_c('div',{staticClass:"selection-row"},[_c('v-select',{staticClass:"submission-select",attrs:{"outlined":"","dense":"","hide-details":"","color":"white","items":_vm.submissions,"return-object":"","item-text":"local_id","item-value":"local_id"},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-icon',{staticClass:"submission-list-icon",attrs:{"small":"","color":"#ffffff"}},[_vm._v(" mdi-checkbox-blank-circle ")]),_vm._v(" "+_vm._s(_vm.tag(_vm.task.name) + ' - ' + data.item.local_id)+" ")]}}]),model:{value:(_vm.submission),callback:function ($$v) {_vm.submission=$$v},expression:"submission"}})],1),_c('div',{staticClass:"data-table-row"},[_c('v-data-table',{staticClass:"data-table",attrs:{"headers":_vm.headers,"items":_vm.jobs,"item-class":_vm.rowBackground,"items-per-page":5},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,'YYYY-MM-DD HH:mm'))+" ")]}},{key:"item.phase",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{class:'phase-' + item.phase.toLowerCase(),attrs:{"width":"96","height":"32"}},[_vm._v(_vm._s(item.phase))])]}},{key:"item.hyperparameters",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.showPopup('Hyperparameters', item.hyperparameters, $event)}}},[_vm._v(_vm._s(_vm.moreString))])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('span',{class:'state-' + item.state.toLowerCase()},[_vm._v(_vm._s(item.state))])]}},{key:"item.log",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.selectJob(item)}}},[_vm._v(_vm._s(_vm.moreString))])]}}],null,true)})],1),_c('div',{staticClass:"log-row"},[_c('div',{staticClass:"log-title"},[_vm._v(" "+_vm._s(_vm.selectedParsedJobName)+" ")]),_c('v-divider',{staticClass:"log-divider"}),_c('div',{ref:"logField",staticClass:"log-field",attrs:{"id":"logField"}},_vm._l((_vm.messages),function(message){return _c('p',{key:message.id},[_vm._v(" "+_vm._s(message.message.replace('\\n', ''))+" ")])}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }