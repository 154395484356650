<template>
  <div class="task-row" @click="routeToTab">
    <div class="task-left">
      <div class="task-content">
        <div class="task-title"><span class="task-tag" :class="tag(task.dataset).split(' ')[0].toLowerCase()">{{tag(task.dataset)}}</span> - {{task.name}}</div>
        <div class="task-desc">{{task.brief}}</div>
      </div>
      <div class="vertical-divider" v-if="task.metric.length > 0"></div>
    </div>
    <div class="task-right" v-if="task.metric.length > 0">
      <div class="task-subrow">
        <div class="task-subrow-title">{{klueEvaluationMetricString}}</div>
        <div class="task-subrow-content" v-html="joinEnter(task.metric)"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Constant from '@/store/constant.js';
export default {
  name: 'TaskRow',
  props: {
    task: Object,
  },
  computed: {
    klueString() {
      return Constant.STRING[this.$store.state.language].KLUE;
    },
    leadString() {
      return Constant.STRING[this.$store.state.language].LEAD;
    },
    klueEvaluationMetricString() {
      return Constant.STRING[this.$store.state.language].KLUE_EVALUATION_METRIC;
    },
    klueDatasetString() {
      return Constant.STRING[this.$store.state.language].KLUE_DATA_SET;
    },
  },
  methods: {
    routeToTab() {
      this.$router.push({ path: `/tasks/${this.task.id}` });
    },
    joinEnter(arr) {
      if(arr.length === 1) {arr = arr.concat(['', '']);}
      return arr.join('<br />').replace(/(\([^\)]*\))/g, (a, b) => {
        return `<span class='small-light'>${b}</span>`
      });
    },
    tag(name) {
      if(name.length === 0) return this.klueString;
      return name[0];
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/global.scss';

.task-row {
  cursor: pointer;
  padding: 17px 30px;
  display: flex;
  flex-direction: row;

  .task-left {
    display: flex;
    align-items: center;

    .task-title {
      @extend .subheader-heavy;
      margin-bottom: 4px;
    }

    .task-desc {
      @extend .caption-light;
      text-align: justify;
      margin-bottom: 4px;
    }
  }

  .task-right {
    flex: 1;
    min-width: 210px;
    display: flex;
    align-items: center;

    .task-subrow {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      margin-top: 12px;
      margin-bottom: 8px;

      .task-subrow-title {
        max-width: 60px;
        color: #515151;
        @extend .caption-heavy;
      }

      .task-subrow-content {
        color: #717171;
        @extend .caption-light;
        text-align: end;
      }
    }
  }
}

.vertical-divider {
  border-right: 1px solid rgba(189, 189, 189, 0.5);
  height: 96px;
  width: 1px;
  margin-left: 24px;
  margin-right: 24px;
}
</style>