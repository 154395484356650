<template>
  <div class="joblog-container">
    <link
      rel="stylesheet"
      type="text/css"
      href="//fonts.googleapis.com/css?family=Ubuntu+Mono"
    />
    <simple-dialog 
      ref="dialog"
      :dialog="dialog" 
      :title="popupTitle" 
      :content="popupContent" 
      @close:simple="closePopup"
    ></simple-dialog>
    <div class="selection-row">
      <v-select
        outlined dense hide-details
        class="submission-select"
        color="white"
        :items="submissions"
        return-object
        item-text="local_id"
        item-value="local_id"
        v-model="submission"
      >
        <template slot="selection" slot-scope="data">
          <v-icon small color="#ffffff" class="submission-list-icon">
            mdi-checkbox-blank-circle
          </v-icon>
          {{ tag(task.name) + ' - ' + data.item.local_id }}
        </template>
      </v-select>
    </div>
    <div class="data-table-row">
      <v-data-table
        :headers="headers"
        :items="jobs"
        class="data-table"
        :item-class="rowBackground"
        :items-per-page="5"
      > 
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at | moment('YYYY-MM-DD HH:mm') }}
        </template>

        <template v-slot:[`item.phase`]="{ item }">
          <v-btn
            :class="'phase-' + item.phase.toLowerCase()"
            width="96"
            height="32"
            >{{ item.phase }}</v-btn
          >
        </template>

        <template v-slot:[`item.hyperparameters`]="{ item }">
          <span style="cursor: pointer;" @click.stop="showPopup('Hyperparameters', item.hyperparameters, $event)">{{moreString}}</span>
        </template>

        <template v-slot:[`item.state`]="{ item }">
          <span :class="'state-' + item.state.toLowerCase()">{{
            item.state
          }}</span>
        </template>

        <template v-slot:[`item.log`]="{ item }">
          <span style="cursor: pointer;" @click.stop="selectJob(item)">{{moreString}}</span>
        </template>
      </v-data-table>
    </div>
    <div class="log-row">
      <div class="log-title">
        {{ selectedParsedJobName }}
        <!-- <div class="log-status" :class="status.toLowerCase()">
          {{ status }}
        </div> -->
      </div>
      <v-divider class="log-divider"></v-divider>
      <div class="log-field" ref="logField" id="logField">
        <p v-for="message in messages" :key="message.id">
          {{ message.message.replace('\\n', '') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Constant from '@/store/constant.js';
import SimpleDialog from '@/components/dialog/SimpleDialog.vue';
export default {
  name: 'JobLog',
  components: {
    SimpleDialog
  },
  props: {
    id: String,
    submissionID: String,
    task: Object,
  },
  computed: {
    moreString() {
      return Constant.STRING[this.$store.state.language].MORE;
    },
    klueString() {
      return Constant.STRING[this.$store.state.language].KLUE;
    }
  },
  methods: {
    tag(name) {
      if(name.length === 0) return this.klueString;
      return name[0];
    }, 
    changeRoute(item) {
      console.log(item)
    },
    showPopup(title, value, event) {
      this.popupTitle = title;
      this.popupContent = value;
      this.dialog = true;
    },
    closePopup() {
      this.dialog = false;
    },
    fetchSubmissions() {
      this.submissions = [];
      this.loading = true;
      this.$store
        .dispatch('fetchSubmissions', this.id)
        .then(data => {
          this.submissions = data;
        })
        .catch(e => alert(e))
        .finally(() => {
          this.submission = this.submissionID 
            ? this.submissions.find(el => el.local_id === parseInt(this.submissionID)) 
            : this.submissions[0];
        });
    },
    parseJobName(name) {
      if (!name) return '';

      let splits = name.split('-');
      return `${splits[0].slice(0, 2)}/C${parseInt(
        splits[1].slice(1),
      )}/U${parseInt(splits[2].slice(1))}/${parseInt(splits[3].slice(1))}`;
    },
    upperBound(array, id) {
      let l = 0;
      let r = array.length;
      let m = 0;
      while (l < r) {
        m = parseInt((l + r) / 2);
        if (id >= array[m]['id']) {
          l = m + 1;
        } else {
          r = m;
        }
      }
      if (array[l - 1] && array[l - 1]['id'] === id) {
        return -1;
      } else {
        return l;
      }
    },

    fetchLogs() {
      this.messages = [];
      let splitted = this.selectedJobName.split('-');
      let payload = {
        competitionID: Number.parseInt(splitted[1].slice(1)),
        submissionID: Number.parseInt(splitted[3].slice(1)),
        jobName: this.selectedJobName,
      };
      this.$store
        .dispatch('fetchLogs', payload)
        .then(data => {
          data.forEach(element => {
            element = JSON.parse(element.replace(/'/g, '"'));
            this.addMessage(element);
          });
        })
        .catch(e => alert(e));
    },

    addMessage(message) {
      let index = this.upperBound(this.messages, message['id']);
      if (index > -1) {
        this.messages.splice(index, 0, message);
      }
    },

    connect() {
      this.status = this.statusType.LOADING;
      if (this.socketConnection !== null) {
        this.disconnect();
      }
      let payload = {
        token: this.$store.state.token,
        jobName: this.selectedJobName,
      };
      payload = btoa(JSON.stringify(payload)).replace(/=/gi, '');
      this.socketConnection = new WebSocket(
        process.env.VUE_APP_SOCKET_URL,
        payload,
      );
      this.socketConnection.onopen = event => {
        console.log(event);
        this.socketConnection.send('Hi');
        this.status = this.statusType.CONNECTED;
      };
      this.socketConnection.onerror = event => {
        console.log(event);
        this.disconnect();
      };
      this.socketConnection.onmessage = event => {
        console.log(event);
        this.addMessage(JSON.parse(event.data));
      };
    },

    disconnect() {
      console.log('disconnect');
      this.socketConnection.close();
      this.status = this.statusType.DISCONNECTED;
    },

    scrollToBottom() {
      let logField = this.$refs.logField;
      logField.scrollTop = logField.scrollHeight;
    },

    selectJob(value) {
      this.selectedJobName = value.job_name || value.parsed_job_name;
    },

    rowBackground(value) {
      return value.job_name === this.selectedJobName
        ? 'selected-job-row'
        : 'unselected-job-row';
    },
  }, 
  watch: {
    messages() {
      this.scrollToBottom();
    },
    submission() {
      this.jobs = [];
      this.submission.trainings.forEach(training => {
        training['phase'] = this.phaseType.TRAINING;
        training['log'] = false;
        training['parsed_job_name'] = this.parseJobName(training['job_name']);
        training['hyperparameters'] =
          this.submission && this.submission.hyperparameters
            ? JSON.parse(this.submission.hyperparameters)['training']
            : '';
        this.jobs.push(training);
      });
      this.submission.inferences.forEach(inference => {
        inference['phase'] = this.phaseType.INFERENCE;
        inference['log'] = false;
        inference['parsed_job_name'] = this.parseJobName(inference['job_name']);
        inference['hyperparameters'] =
          this.submission && this.submission.hyperparameters
            ? JSON.parse(this.submission.hyperparameters)['inference']
            : '';
        this.jobs.push(inference);
      });
      this.submission.evaluations.forEach(evaluation => {
        evaluation['phase'] = this.phaseType.EVALUATION;
        evaluation['log'] = false;
        evaluation['parsed_job_name'] = this.parseJobName(
          evaluation['job_name'],
        );
        this.jobs.push(evaluation);
      });

      if (this.jobs.length !== 0) this.jobs[this.jobs.length - 1].log = true;
      this.selectedJobName = this.submission.last_job_name;
    },
    selectedJobName() {
      this.fetchLogs();
      this.connect();
      this.selectedParsedJobName = this.parseJobName(this.selectedJobName);
    },
  },
  mounted() {
    this.fetchSubmissions();
    // this.fetchSubmission();
    this.scrollToBottom();
  },

  updated() {
    this.scrollToBottom();
  },

  destroyed() {
    this.disconnect();
  },

  data() {
    return {
      statusType: Constant.TYPE.SUBMISSION_STATUS,
      stateType: Constant.TYPE.SUBMISSION_STATE,
      phaseType: Constant.TYPE.SUBMISSION_PHASE,
      dialog: false,
      popupTitle: '',
      popupContent: '',
      messages: [],
      submissions: [],
      submission: {},
      socketConnection: null,
      status: 'Disconnected',
      selectedParsedJobName: '',
      selectedJobName: '',
      jobs: [],
      headers: [
        {
          text: 'Phase',
          value: 'phase',
          align: 'center',
          sortable: false,
        },
        {
          text: 'State',
          value: 'state',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Hyperparameters',
          value: 'hyperparameters',
          align: 'center',
          sortable: false,
        },
        {
          text: 'JobName',
          value: 'parsed_job_name',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Created_at',
          value: 'created_at',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Job Log',
          value: 'log',
          align: 'center',
          sortable: false,
        },
      ],
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';
.joblog-container {
  flex: 1;
}

.data-table {
  width: 100%;
  &::v-deep .v-data-table-header {
    background-color: #FBF8F7;
    th {
      color: #515151 !important;
      @extend .body-heavy;
    }
  }

  td {
    @extend .body-light;
  }

  &::v-deep .v-data-footer {
    @extend .body-light;
    .v-select__selection {
      @extend .body-light;
    }
  }
}

.log-title {
  @extend .body-heavy;
  margin-bottom: 4px;

  .log-status {
    @extend .body-heavy;
    margin-left: 17px;
    display: inline-block;
  }
}

.log-divider {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 32px;
}

.log-field {
  background: #313335;
  min-height: 40vh;
  max-height: 60vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: scroll;
  padding: 20px;
  color: #ffffff !important;
  font: 1rem 'Ubuntu Mono';
  line-height: 0.9rem;
}

.selection-row {
  margin-bottom: 32px;
}

.data-table-row {
  margin-bottom: 70px;
}

p {
  word-wrap: anywhere;
  word-break: break-all;
  line-height: 1.1rem;
}

.submission-select {
  background: #705C4F;
  box-shadow: 1.5px 1.5px 4px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  color: white;
  height: 48px;
  @extend .subheader-heavy;
  &::v-deep fieldset {
    border: none;
  }

  .submission-list-icon {
    margin-left: 22px;
    margin-right: 20px;
  }
  &::v-deep .v-select__slot {
    margin-top: 4px;
  }
  i {
    font-size: 12px !important;
  }
}

</style>