<template>
  <div
    class="icon"
    :class="[`icon--${name}`, `icon--${size}`, { 'has-align-fix': fixAlign }]"
  >
    <v-img
      :class="[`icon--${name}`, `icon--${size}`, { 'has-align-fix': fixAlign }]"
      :src="require(`@/assets/images/icons/${name}.svg`)"
      :alt="`${name}`"
      content-class="span"
      contain
    />
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'normal',
    },
    modifier: {
      type: Object,
      default: null,
    },
    fixAlign: {
      type: Boolean,
      default: false,
    },
  },
  // created: function() {}
};
</script>

<style lang="scss" scoped>
.icon--small {
  width: 12px;
  height: 12px;
}

.icon--normal {
  width: 14px;
  height: 14px;
}

.icon--large {
  width: 16px;
  height: 16px;
}

.icon--x-large {
  width: 20px;
  height: 20px;
}

.icon--biggest {
  width: 30px;
  height: 30px;
}
</style>
