<template>
  <div
    :class="isCompetition ? '' : 'post-wrapper'"
    ref="postWrapper"
    id="post-wrapper"
  >
    <div v-if="post.user">
      <CompactPostCard
        :post="post"
        :isComment="false"
        @scrollDownEvent="scrollToBottom"
      >
      </CompactPostCard>

      <hr class="post-divider"/>

      <div class="comments-row">
        <div class="comments">Comments</div>
        <div class="comments-number">({{ comments.length }})</div>
      </div>
      <div v-if="user" class="comments-edit-row">
        <div class="comments-edit-left">
          <v-menu
            :close-on-content-click="false"
            offset-y
            nudge-bottom="30"
            nudge-left="150"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-img
                v-bind="attrs"
                v-on="on"
                :src="user.detail.image_url"
                class="hidden-xs-only rounded-circle"
                :class="['circle', user.detail.type.toLowerCase()]"
                max-width="48"
                max-height="48"
                width="48"
                height="48"
              />
            </template>
            <UserCard
              :userID="user.id.toString()"
              :stateButton="false"
            ></UserCard>
          </v-menu>
        </div>
        <div class="comments-edit-right" @click="$refs.postEditor.editor.view.dom.focus()">
          <PostEditor
            ref="postEditor"
            initial-content=""
            :isComment="true"
            :active-buttons="[
              'bold',
              'italic',
              'strike',
              'underline',
              'code',
              'paragraph',
              'h1',
              'h2',
              'h3',
              'bullet_list',
              'ordered_list',
              'blockquote',
              'code_block',
              'horizontal_rule',
              'undo',
              'redo',
              'image',
            ]"
            :submitButton="true"
            @submit="commitComment"
          />
        </div>
      </div>
      <div class="comments-list" v-for="comment in comments" :key="comment.id">
        <CompactCommentCard :comment="comment" :isComment="true" @delete="deleteComment"></CompactCommentCard>
      </div>
    </div>
  </div>
</template>

<script>
import { Editor } from 'tiptap';
import UserCard from '@/components/card/UserCard';
import PostEditor from '@/components/Editor';
import CompactCommentCard from '@/components/card/CompactCommentCard';
import CompactPostCard from '@/components/card/CompactPostCard';
import TagSpan from '@/plugins/TagSpan';
import Constant from '@/store/constant.js';

import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Image,
} from 'tiptap-extensions';

export default {
  name: 'DiscussionPost',

  props: {
    id: String,
    postID: String,
  },

  components: {
    CompactPostCard,
    // EditorContent,
    PostEditor,
    CompactCommentCard,
    UserCard,
  },

  computed: {
    isCompetition() {
      if(this.$route.name === 'CompetitionPost' || this.$route.name === 'TaskDiscussionPost') {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    fetchUserInfo() {
      const userID = this.$store.state.userID;
      this.$store.dispatch('fetchUserDetail', { userID }).then(data => {
        this.user = data;
      });
    },

    fetchPost(postID) {
      this.$store
        .dispatch('fetchPost', { postID })
        .then(data => {
          this.post = data;
          this.comments = data.comments;
          this.sortComments(this.filter);
        })
        .catch(e => alert(e));
    },

    commitComment(body, fileURLs) {
      let payload = {
        body: body,
        postID: this.postID,
        fileURLs: fileURLs,
      };
      return this.$store
        .dispatch('commitComment', payload)
        .then(data => {
          this.comments = data;
          this.sortComments(this.filter);
          this.$refs.postEditor.clearContent();
        })
        .catch(e => alert(e));
    },

    deleteComment(id) {
      console.log(Number(id));
      this.comments = this.comments.filter(el => el.id !== Number(id));
    },

    scrollToBottom() {
      let postWrapper = document.getElementsByTagName('html')[0];
      postWrapper.scrollTop = postWrapper.scrollHeight;
      this.$refs.postEditor.editor.view.dom.focus();
    },

    sortComments(filter) {
      if (filter === 0) {
        this.comments.sort(function(a, b) {
          return Date.parse(b.created_at) - Date.parse(a.created_at);
        });
      } else if (filter === 1) {
        this.comments.sort(function(a, b) {
          return b.votes.length - a.votes.length;
        });
      } else {
        console.log('test');
      }
    },
    routeBack() {
      if(this.isCompetition) {
        if (this.isCompetition) {
          let path = this.$route.query.back;
          let query = this.$route.query;
          delete query.back;
          this.$router.push({path, query});
        } else {
          this.$router.push({ name: 'Ustage', params: { id: this.id } });
        }
      }
    },
  },

  mounted() {
    this.fetchUserInfo();
    this.fetchPost(this.postID);
    this.sortComments(this.filter);
    this.user = this.$store.state.user;
  },

  watch: {
    filter() {
      this.sortComments(this.filter);
    },
    postID() {
      this.fetchUserInfo();
      this.fetchPost(this.postID);
      this.sortComments(this.filter);
      this.user = this.$store.state.user;
    },
  },

  data() {
    return {
      postType: Constant.TYPE.POST,
      user: null,
      comments: [],
      post: {},
      comment: new Editor({
        editable: false,
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Image(),
          new TagSpan(),
        ],
      }),
      // filters: [
      //   'Recently Posted',
      //   'Most Votes',
      // ],
      filter: 1,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/sass/editor/main.scss';
@import '@/assets/sass/global.scss';
#post-wrapper {
  width: 100%;
}

.comments-row {
  display: flex;
  margin-bottom: 32px;
  .comments {
    @extend .body-heavy;
    color: rgba(0, 0, 0, 0.87);
  }
  .comments-number {
    @extend .body-heavy;
    color: rgba(0, 0, 0, 0.45);
    margin-left: 13px;
  }
}

.comments-edit-row {
  display: flex;
  .comments-edit-left {
    margin-right: 32px;
  }

  .comments-edit-right {
    flex: 1;
  }

}

.comments-list {
  margin-top: 24px;
}

.post-wrapper {
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 14vw;
  padding-right: 14vw;
}

.post-divider {
  margin-top: 48px;
  margin-bottom: 32px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.comment-card {
  padding-top: 25px;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 25px;
}

.circle {
  background-color: #ffffff;
  padding: 1px;
}

.back-to-list {
  @extend .subheader-heavy;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 25px;
  cursor: pointer;
}
</style>
