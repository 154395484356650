<template>
  <div class="footer">
    <div class="footer-content">
      <div class="powered-by">{{poweredByString}}</div>
      <div class="copyright">{{copyrightString}}</div>
    </div>
  </div>
</template>

<script>
import Constant from '@/store/constant.js';
export default {
  mounted() {
    this.changeLanguage();
    this.fetchUser();
  },
  methods: {
    changeLanguage() {
      let language = 'EN';
      this.$store.dispatch('commitLanguage', { language });
    },
    fetchUser() {
      let userID = this.$store.state.userID;
      if (!userID) return;

      return this.$store
        .dispatch('fetchUserDetail', { userID })
        .then(data => {
          this.user = data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    
  },
  data() {
    return {
    };
  },
  computed: {
    poweredByString() {
      return Constant.STRING[this.$store.state.language].POWERED_BY;
    },
    copyrightString() {
      return Constant.STRING[this.$store.state.language].UPSTAGE_COPYRIGHT;
    },
    
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';
@import '@/assets/sass/variables.scss';

.footer {
  width: 100%;
  min-height: 80px;
  background: #212121;

  &-content {
    width: 1084px;
    margin: 14px auto;

    div {
      @extend .caption-light;
      color: white;
      text-align: center;
      margin-bottom: 4px;
    }
  }
}
</style>
