<template>
  <div class="description-container">
    <div class="description-inner">
      <div class="description">{{leaderboardDescriptionTextString}}</div>
      <div class="button-area">
        <v-btn
          width="200"
          height="40"
          depressed
          class="button"
          @click="routeToLeaderboard"
          >{{ checkLeaderboardString }}</v-btn>
      </div>
    </div>
    <hr class="bottom-line" />
  </div>
</template>

<script>
import Constant from '@/store/constant.js';
export default {
  name: 'LeaderboardDescription',
  computed: {
    leaderboardDescriptionTextString() {
      return Constant.STRING[this.$store.state.language].LEADERBOARD_DESCRIPTION_TEXT;
    },
    checkLeaderboardString() {
      return Constant.STRING[this.$store.state.language].CHECK_LEADERBOARD;
    },
  },
  methods: {
    routeToLeaderboard() {
      this.$router.push({ name: 'Leaderboard' });
    }
  }

}
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';

.bottom-line {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  margin-top: 56px;
}

.description-container {
  flex: 1;

  .description-inner {
    min-height: 280px;
  }

  .description {
    @extend .body-light;
    margin-bottom: 48px;
  }

  .button-area {
    display: flex;
    justify-content: flex-end;
    margin-right: 60px;
  }
}

.button {
  background-color: #E84660 !important;
  border-radius: 5px;
  letter-spacing: normal !important;
  @extend .subheader-heavy;
  color: #ffffff;
  text-transform: none !important;
}
</style>