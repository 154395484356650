<template>
  <div class="post-card" @click="routeToPost">
    <div class="post-row">
      <div class="post-image-area">
        <div class="post-image">
          <v-img
            :src="post.user.detail.image_url"
            class="hidden-xs-only rounded-circle"
            max-width="42"
            max-height="42"
            height="42"
            width="42"
          />
          <div :class="['circle', post.user.detail.type.toLowerCase()]"></div>
        </div>
      </div>

      <div class="post-content">
        <div class="post-title">
          <div class="post-title-text">
            {{ post.title }}
          </div>
        </div>
        <div class="post-info">
          <div class="post-author">
            posted by {{ post.user.detail.nickname }}
          </div>
          <div class="info-divider"></div>
          <div class="post-date">
            {{ post.created_at | dateFormat }}
          </div>
          <div class="info-divider"></div>
          <div class="post-comments">Comments ({{ post.comments.length }})</div>
        </div>
      </div>

      <div class="post-additional">
        <div class="post-vote" @click.stop="toggleVote">
          <div class="vote-left">
            <v-icon small color="pink" v-if="voted">mdi-heart</v-icon>
            <v-icon small color="pink" v-else>mdi-heart-outline</v-icon>
            {{ numVotes }}
          </div>
          <div class="vote-right">UP</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Constant from '@/store/constant.js';
export default {
  name: 'DiscussionRow',
  props: {
    post: Object,
    id: String,
    isCompetition: Boolean,
    to: Object,
  },
  computed: {
    noticeString() {
      return Constant.STRING[this.$store.state.language].NOTICE;
    },
    qaString() {
      return Constant.STRING[this.$store.state.language].QA;
    },
    user() {
      return this.$store.state.user;
    },
  },
  filters: {
    dateFormat(string) {
      Date.prototype.format = function() {
        let yy = this.getFullYear();
        let mm = this.getMonth() + 1;
        let dd = this.getDate();
        let hour = this.getHours();
        let minute = this.getMinutes();
        return (
          [yy, (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('.') +
          ' ' +
          [(hour > 9 ? '' : '0') + hour, (minute > 9 ? '' : '0') + minute].join(
            ':',
          )
        );
      };
      let date = new Date(string);
      return date.format();
    },
  },
  created() {
    let userID = this.$store.state.userID;
    for (let i = 0; i < this.post.votes.length; i++) {
      if (this.post.votes[i].user == userID) {
        this.voted = true;
        break;
      }
    }
    this.numVotes = this.post.votes.length;
  },
  methods: {
    routeToPost() {
      this.$router.push(this.to);
    },
    toggleVote() {
      let postID = this.post.id;
      let isComment = false;

      if (this.voted) {
        this.$store
          .dispatch('deleteVote', { postID, isComment })
          .then(() => {})
          .catch(e => alert(e));
        this.numVotes--;
      } else {
        this.$store
          .dispatch('commitVote', { postID, isComment })
          .then(() => {})
          .catch(e => alert(e));
        this.numVotes++;
      }
      this.voted = !this.voted;
    },
  },
  data() {
    return {
      voted: false,
      numVotes: 0,
      postType: Constant.TYPE.POST,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';

.post-card {
  cursor: pointer;
  width: 100%;
  /* min-width: 1100px; */
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  .post-row {
    display: flex;
    flex-direction: row;
    padding: 25px 0;
    height: 100%;
  }
}

.title-divider {
  height: 17px;
  margin-left: 9px;
  margin-right: 9px;
  border: 1px solid #000000;
}

.info-divider {
  height: 17px;
  margin-left: 9px;
  margin-right: 9px;
  border: 1px solid #c4c4c4;
}

.post-content {
  flex: 1;

  .post-title,
  .post-info {
    display: flex;
    align-items: center;
  }

  .post-title {
    @extend .body-heavy;
  }

  .post-info {
    .post-author {
      @extend .caption-heavy;
    }

    .post-date {
      @extend .caption-light;
      color: #212121;
    }
    .post-comments {
      @extend .caption-light;
      color: #414141;
    }
  }
}

.post-image-area {
  margin-left: 10px;
  width: 90px;
  display: flex;
  align-items: center;
}

.post-additional {
  margin-right: 10px;
  display: flex;
  align-items: center;

  .post-vote {
    cursor: pointer;
  }
}

.vote-left {
  display: inline-block;
  width: 56px;
  height: 30px;
  border: 2px solid #e84660;
  box-sizing: border-box;
  border-radius: 5px 0px 0px 5px;
  @extend .body-heavy;
  color: #e84660;
  text-align: center;
}
.vote-right {
  display: inline-block;
  width: 40px;
  height: 30px;
  border: 2px solid #e84660;
  background: #e84660;
  border-radius: 0px 5px 5px 0px;
  @extend .body-heavy;
  color: #ffffff;
  text-align: center;
}

.rounded-circle {
  margin-left: 3px;
}
.circle {
  background-color: #ffffff;
  border-radius: 48px;
  height: 48px;
  width: 48px;
  margin-top: -45px;
}
</style>
