<template>
  <div class="code" v-if="task && task.detail">
    <div class="code-inner">
      <div class="link" v-if="data_link && !task.is_multiple">
        {{ linkDataString }}
        <div class="copy">
          <a :href="data_link">{{ data_link }}</a>
          <v-tooltip bottom v-model="tooltip1">
            <template v-slot:activator="{}">
              <img class="icon-copy" :src="copyImageSrc" width="15" height="15" @click="copyText(data_link, 1)"/>
            </template>
            <span>{{ copiedString }}</span>
          </v-tooltip>
        </div>
      </div>
      <div class="link" v-if="code_link">
        {{ linkCodeString }}
        <div class="copy">
          <a :href="code_link">{{ code_link }}</a>
          <v-tooltip bottom v-model="tooltip2">
            <template v-slot:activator="{}">
              <img class="icon-copy" :src="copyImageSrc" width="15" height="15" @click="copyText(code_link, 2)"/>
            </template>
            <span>{{ copiedString }}</span>
          </v-tooltip>
        </div>
      </div>
      <div v-for="subtask in tasks.filter(el => task.is_multiple && !el.is_multiple)" :key="subtask.id">
        <div class="link" v-if="subtask.data_url">
          {{ linkDataString }}_{{ subtask.dataset.join(" ") }}
          <div class="copy">
            <a :href="subtask.data_url">{{ subtask.data_url }}</a>
            <v-tooltip bottom v-model="tooltip1">
              <template v-slot:activator="{}">
                <img class="icon-copy" :src="copyImageSrc" width="15" height="15" @click="copyText(subtask.data_url, 1)"/>
              </template>
              <span>{{ copiedString }}</span>
            </v-tooltip>
          </div>
        </div>
        <div class="link" v-if="subtask.code_url">
          {{ linkCodeString }}_{{ subtask.dataset.join(" ") }}
          <div class="copy">
            <a :href="subtask.code_url">{{ subtask.code_url }}</a>
            <v-tooltip bottom v-model="tooltip2">
              <template v-slot:activator="{}">
                <img class="icon-copy" :src="copyImageSrc" width="15" height="15" @click="copyText(subtask.code_url, 2)"/>
              </template>
              <span>{{ copiedString }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
      <div class="button-container" v-if="task.is_multiple">
        <v-btn class="download-all-button" :href="data_link">{{downloadAllDataString}}</v-btn>
      </div>
    </div>
    <hr class="bottom-line" />
  </div>
</template>

<script>
import Constant from '@/store/constant.js';
export default {
  name: 'TaskDataDownload',
  props: {
    id: String,
    task: Object,
  },
  computed: {
    linkDataString() {
      return Constant.STRING[this.$store.state.language].LINK_DATA;
    },
    linkCodeString() {
      return Constant.STRING[this.$store.state.language].LINK_CODE;
    },
    copiedString() {
      return Constant.STRING[this.$store.state.language].COPIED;
    },
    downloadAllDataString() {
      return Constant.STRING[this.$store.state.language].DOWNLOAD_ALL_DATA;
    },
    data_link() {
      return this.task.detail.data_url;
    },
    code_link() {
      return this.task.detail.code_url;
    },
  },
  data() {
    return {
      tasks: [],
      tooltip1: false,
      tooltip2: false,
      copyImageSrc: require('@/assets/images/icons/copy.svg'),
    };
  },
  mounted() {
    if(this.tasks.length === 0) {
      this.$store
      .dispatch('fetchTasks')
      .then(() => {
        let tasks = this.$store.state.tasks.map((el, idx) => {
          let base_url = this.data_link.split('/app')[0];
          let etc = JSON.parse(el.etc);
          delete el.etc;
          return {
            ...el, 
            idx: idx,
            data_url: base_url + '/' + etc.data,
            lead: etc.lead, 
            metric: etc.metric, 
            members: etc.members,
            dataset: etc.dataset,
          }
        });
        this.tasks = tasks.filter(el => !el.is_multiple);
      })
      .catch(e => alert(e));
    }
  },
  methods: {
    copyText(text, num) {
      const element = document.createElement('textarea');
      element.value = text;
      element.setAttribute('readonly', '');
      element.style.position = 'absolute';
      element.style.left = '-9999px';
      document.body.appendChild(element);
      element.select();
      var returnValue = document.execCommand('copy');
      document.body.removeChild(element);
      if (!returnValue) {
        throw new Error('copied nothing');
      }
      if (num === 1) {
        this.tooltip1 = true;
        setTimeout(() => {
          this.tooltip1 = false;
        }, 400);
      } else if (num === 2) {
        this.tooltip2 = true;
        setTimeout(() => {
          this.tooltip2 = false;
        }, 400);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';
.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;

  .download-all-button {
    @extend .subheader-heavy;
    width: 200px;
    height: 40px;
    background-color: #E84660 !important;
    border-radius: 5px;
    text-transform: none;
    color: white !important;
    letter-spacing: normal;

  }
}



.bottom-line {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: 72px;
}

.code {
  flex: 1;

  .code-inner {
    min-height: 280px;
  }
}

.icon-copy {
  cursor: pointer;
}

.link {
  width: 100%;
  @extend .body-heavy;

  margin: 24px 0 !important;
  .copy {
    width: 100%;
    margin-top: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @extend .body-light;
    color: #B1A094;
    
    a {
      text-decoration: none;
    }

    .v-icon {
      margin-right: 60px;
    }
  }
}
</style>
