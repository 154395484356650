export default Object.freeze({
  REG_TAG: /<span class=("|')hashtag("|') data-hashtag-id=("|')(.*?)("|')>#\S+<\/span>/g,
  REG_USER: /<span class=("|')mention("|') data-mention-id=("|')(.*?)("|')>@\S+<\/span>/g,

  TYPE: {
    USER: {
      ADMIN: 'admin',
    },
    DISCUSSION: {
      CLASS: 'class',
      GENERAL: 'general',
      NETWORKING: 'networking',
      OFFICIAL: 'official',
    },
    POST: {
      NOTICE: 'notice',
      QA: 'qa',
    },
    NOTIFICATION: {
      MENTION: 'Mention',
      COMMENT: 'Comment',
      QA: 'QA',
    },
    MENTION: {
      USER: 'user',
      TAG: 'tag',
    },
    SUBMISSION: {
      FILE: 'tar.gz',
      NOTEBOOK: 'Notebook',
    },
    COMPETITION: {
      PUBLIC: 'public',
      PRIVATE: 'private',
    },
    SUBMISSION_STATUS: {
      DISCONNECTED: 'Disconnected',
      LOADING: 'Loading',
      CONNECTED: 'Connected',
    },
    SUBMISSION_STATE: {
      FAILED: 'Failed',
      PENDING: 'Pending',
      RUNNING: 'Running',
      DONE: 'Done',
    },
    SUBMISSION_PHASE: {
      CREATED: 'Created',
      TRAINING: 'Training',
      INFERENCE: 'Inference',
      EVALUATION: 'Evaluation',
      FINISHED: 'Finished',
      FAILED: 'Failed',
    },
    SUBMISSION_RESULT: {
      SUCCESS: 'Success',
      FAILURE: 'Failure',
    },
    SERVER_STATUS: {
      STOPPED: 'Stopped',
      PENDING: 'Pending',
      ACTIVE: 'Active',
    },
    SERVER_EVENT: {
      START: 'Start',
      TERMINATE: 'Terminate',
    },
    LEADERBOARD_TYPE: {
      PUBLIC: 'Public',
      PRIVATE: 'Private',
      PROTECTED: 'Protected',
    },
    COMPETITION_CLASSIFICATION: {
      TRAINING: 'Training',
      INFERENCE: 'Inference',
      EVALUATION: 'Evaluation',
    },
    COMPETITION_STATE: {
      WRITING: 'Writing',
      REVIEW: 'Review',
      REVISING: 'Revising',
      PENDING: 'Pending',
      ACTIVE: 'Active',
      HALTED: 'Halted',
      EXPIRED: 'Expired',
    },
    LEADERBOARD_SIZE: {
      ALL: 'All',
      SMALL: 'Small Size',
      BASE: 'Base Size',
      LARGE: 'Large Size'
    },
    MODEL_SIZE: {
      SMALL: 'Small',
      BASE: 'Base',
      LARGE: 'Large',
    }
  },

  STRING: {
    EN: {
      SIGNIN: 'SignIn',
      SIGNIN_TITLE: 'SignIn',
      SIGNUP: 'SignUp',
      SIGNUP_TITLE: 'SignUp',
      SIGNUP_TITLE_OAUTH: 'Complete Sign Up',
      SIGNIN_WITH_GOOGLE: 'Sign in with Google',
      SIGNIN_WITH_PASSWORD: 'Sign in with your mail',
      SIGNUP_WITH_GOOGLE: 'Sign up with Google',
      SIGNUP_WITH_PASSWORD: 'Sign up with your mail',
      NO_ACCOUNT: 'No account?',
      HAVE_AN_ACCOUNT: 'Have an account?',
      USEREDIT: 'Edit User',
      USEREDIT_TITLE: 'Edit User',
      SIGNOUT: 'SignOut',
      EDIT_USER: 'Edit',
      VCODE: 'Verification Code',
      INPUT_VCODE: 'Verification Code',
      EMAIL: 'Email',
      INPUT_EMAIL: 'Email',
      PASSWORD: 'Password',
      INPUT_PASSWORD: 'Password',
      PHOTO: 'Photo',
      NICKNAME: 'Nickname',
      INPUT_NICKNAME: 'Nickname',
      COMPANY: 'Company',
      INPUT_COMPANY: 'Company',
      NO_COMPANY: 'No Company',
      SNS: 'SNS',
      INPUT_SNS: 'SNS',
      NO_SNS: 'No SNS',
      TERM_AGREE: 'Accept to the terms',
      POLICY_AGREE: 'Agree to the policy',
      AGREE: 'Agree',
      DISAGREE: 'Disagree',
      TERM: 'Terms of Use',
      POLICY: 'Privacy Policy',
      PRIZE: 'Prize',
      DATE: 'Date',
      TEAM: 'Team',
      TOTAL_SUBMISSION: 'Submission',
      BLOCKED: 'Blocked',
      STATE_WRITING: 'Writing',
      STATE_REVIEW: 'Review',
      STATE_REVISING: 'Revising',
      STATE_PENDING: 'Pending',
      STATE_HALTED: 'Halted',
      STATE_EXPIRED: 'Expired',
      NOTIFICATION: 'Notification',
      CONFIRM: 'Confirm',
      CONFIRM_DELETION: 'Are you sure you want to delete?',
      NOT_ALLOWED: 'Not allowed',
      NOTICE: 'Notice',
      QA: 'Discussion',
      USTAGE_DISCUSSION: 'UStage Lecture List',
      USTAGE_DISCUSSION_BRIEF: `This tab allows you to ask questions about what you have learned in class and conduct various discussions.</br>
        Feel free to post questions and topics you want to discuss. The following is a list for 6 classes and 3 general bulletin boards.`,
      PSTAGE_COMPETITION: 'Competitions List',
      PSTAGE_COMPETITION_BRIEF: `In the Competition tab, there is a competition for varius AI issues provided by AI STAGES.</br>
        Participate in varius competitions to check your skills and compete with other developers for problem solving!`,
      BOARD: 'Board',
      OVERVIEW: 'Overview',
      DATA: 'Data',
      SERVER: 'Server',
      SUBMISSION: 'Submission',
      LEADERBOARD: 'Leaderboard',
      OVERVIEW_DESCRIPTION: 'Overview Description',
      EVALUATION_METHODS: 'Evaluation Methods',
      CODE_REQUIREMENTS: 'Code Requirements',
      TIMELINE: 'Timeline',
      COPYRIGHT_NOTICE: 'Copyright Notice',
      DATA_DESCRIPTION: 'Data & Baseline Description',
      DATA_CODE: 'Data & Baseline Code',
      NEW_NOTIFICATIONS: 'New Notifications',
      DELETE_NOTIFICATIONS: 'Delete Notifications',
      NOTIFICATION_TAGGED: 'tagged you in',
      NOTIFICATION_COMMENT: 'commented to your post in',
      NOTIFICATION_QA: 'posted in',
      UPSTAGE: 'UPSTAGE',
      UPSTAGE_EMAIL: 'contact@upstage.ai',
      UPSTAGE_ADDRESS: '338, Gwanggyojungang-ro, Suji-gu, Yongin-si, Gyeonggi-do, Republic of Korea (Zipcode: 16942)',
      UPSTAGE_COPYRIGHT: 'Copyright © 2021 Upstage all rights reserved.',
      COMPANY_INFORMATION: 'Company',
      SEARCH_PLACEHOLDER: '#  You can search through hashtags',
      SIGNIN_ALERT: 'Email or Password is incorrect',
      FORMAT_INCORRECT: ' format is incorrect',
      FIELD_EMPTY: ' field is empty',
      DUPLICATE_EMAIL: 'The email is duplicated',
      DUPLICATE_NICKNAME: 'The nickname is duplicated',
      SIGNUP_FAIL: 'Signup Failed',
      USEREDIT_FAIL: 'UserEdit Failed',
      NICKNAME_RULE_FRONT: 'Nickname cannot contain',
      NICKNAME_RULE_BACK: '',
      SPACES: 'spaces',
      LINK_DATA: 'Download Data Link',
      LINK_CODE: 'Download Baseline Code Link',
      COPIED: 'copied!',
      RECORD_PRIVATE: 'My Record',
      RECORD_PUBLIC: 'All',
      NEW_SUBMISSION: 'New Submission',
      MY_SUBMISSION: 'My Submissions',
      HYPERPARAMETER: 'Hyperparameter',
      SUBMIT: 'Submit',
      FAILURE: 'Fail',
      NO_SOURCE_FILE: 'Please upload source file.',
      DESCRIPTION_TOO_LONG: 'Description is too long.',
      SELECT_SERVER_TYPE: 'Please Select Server Type',
      NEW_SERVER: 'New Server',
      SERVER_TYPE: 'Server Type',
      SERVER_DESCRIPTION: 'Describe your server (Less than 500 characters)',
      SERVER_SUBMISSION_DESCRIPTION: 'Describe your submission (Less than 500 characters)',
      FILE_DESCRIPTION: 'Describe your File (Less than 500 characters)',
      CREATE_SERVER: 'Create Server',
      MY_SERVER: 'My Server',
      TYPE: 'Type',
      CREATED_AT: 'Created_at',
      STATUS: 'Status',
      DESCRIPTION: 'Description',
      CONNECT_SERVER: 'Connect Server',
      SAVE_VERSION: 'Save Version',
      START: 'Start',
      TERMINATE: 'Terminate',
      LOAD: 'Load',
      NEW_POST: 'New Post',
      SUBMISSION_UPLOADING_TITLE: 'Submission',
      SUBMISSION_UPLOADING_MESSAGE: 'Uploading...',
      REMAINING_SUBMISSION: 'Number of submissions left today',
      COMPETITION_ORGANIZE_DESCRIPTION: 'Competition Organize Description',
      COMPETITION_ORGANIZE_LIST: 'Competition Organize List',
      COMPETITION_ORGANIZE_SETTINGS: 'Competition Hosting Settings',
      ORGANIZE_TITLE: 'AI Problem Solving Competition',
      ORGANIZE_DESCRIPTION: `We provide various and unresolved artificial 
      intelligence problems in a company or group in the form of a competition. <br/>
      Competitions can be held for high-level developers outside the company or 
      group to solve problems and obtain more accurate algorithms.`,
      COMPETITION_BASIC_INFO: 'Basic Info',
      COMPETITION_TYPE_SETTINGS: 'Type Settings',
      COMPETITION_USAGE_DATA: 'Usage Data',
      COMPETITION_REVIEW: 'Review',
      COMPETITION_MODIFY_UPLOAD: 'Modify & Upload',
      COMPETITION_BASIC_INFO_INPUT: 'Competition Basic Info Input',
      COMPETITION_NAME: 'Name',
      COMPETITION_NAME_PLACEHOLDER: 'Input competition name',
      COMPETITION_DESCRIPTION: 'Description',
      COMPETITION_DESCRIPTION_PLACEHOLDER: 'Input competition description within 2000 characters (includes spaces)',
      COMPETITION_BRIEF: 'Brief',
      COMPETITION_BRIEF_PLACEHOLDER: 'Brief information shown in competition list. Input brief information within 180 characters (includes spaces)',
      COMPETITION_TYPE: 'Type',
      COMPETITION_TYPE_PLACEHOLDER: '# Hashtag input(EX: NLP, RecSys, TimeSeries, etc...)',
      COMPETITION_TYPE_ADD: 'Add',
      COMPETITION_ICON: 'Icon',
      COMPETITION_ICON_TEXT: `Register a photo exposed in the competition list<br />
      (Recommended Size : 130px * 130px or 1:1 ratio)`,
      COMPETITION_ICON_REGISTER: 'Register',
      COMPETITION_ICON_DELETE: 'Delete',
      COMPETITION_PERIOD: 'Period',
      COMPETITION_PERIOD_START: 'Enter a starting point',
      COMPETITION_PERIOD_END: 'Enter a ending point',
      COMPETITION_LEADERBOARD_TYPE: 'Leaderboard Type',
      COMPETITION_LEADERBOARD_TYPE_PUBLIC: 'Share every user LB',
      COMPETITION_LEADERBOARD_TYPE_PROTECTED: 'Share LB with anonymous or id',
      COMPETITION_LEADERBOARD_TYPE_PRIVATE: 'You can only see your private LB',
      PUBLIC: 'Public',
      PRIVATE: 'Private',
      PROTECTED: 'Protected',
      PREV_STEP: 'Prev',
      NEXT_STEP: 'Next',
      COMPETITION_TYPE_RESTRICTION_SETTING: 'Competition Type & Restriction Settings',
      COMPETITION_CLASSIFICATION: 'Classification',
      COMPETITION_CLASSIFICATION_TRAINING: 'Submit train.py & inference.py',
      COMPETITION_CLASSIFICATION_INFERENCE: 'Submit inference.py & model checkpoint',
      COMPETITION_CLASSIFICATION_EVALUATION: 'Submit Result file',
      COMPETITION_COPYRIGHT: 'Copyrights',
      COMPETITION_COPYRIGHT_PLACEHOLDER: 'Input copyrights description about competition within 2000 characters',
      COMPETITION_SUBMISSIONS_PER_DAY: 'Submissions Per Day',
      COMPETITION_NO_LIMIT: 'No Limit',
      COMPETITION_ETC: 'etc',
      COMPETITION_SUBMISSIONS_1: '1',
      COMPETITION_SUBMISSIONS_2: '< 3',
      COMPETITION_SUBMISSIONS_3: '< 5',
      COMPETITION_SUBMISSIONS_4: '< 7',
      COMPETITION_TRAINING_TIME: 'Training Time',
      COMPETITION_TRAINING_1: '< 60m',
      COMPETITION_TRAINING_2: '< 90m',
      COMPETITION_TRAINING_3: '< 120m',
      COMPETITION_TRAINING_4: '< 180m',
      COMPETITION_INFERENCE_TIME: 'Inference Time',
      COMPETITION_INFERENCE_1: '< 1m',
      COMPETITION_INFERENCE_2: '< 3m',
      COMPETITION_INFERENCE_3: '< 5m',
      COMPETITION_INFERENCE_4: '< 10m',
      COMPETITION_SERVER: 'Server',
      COMPETITION_NOT_PROVIDED: 'Not Provide',
      COMPETITION_PROVIDED: 'Provide',
      COMPETITION_TRAINING_DATA: 'Training Data',
      COMPETITION_DATA_FILE_PLACEHOLDER: 'Data file upload (Recommend: Less than 1GB)',
      COMPETITION_UPLOAD: 'Upload',
      COMPETITION_DATA_URL_PLACEHOLDER: 'Url where the data was uploaded',
      COMPETITION_INDIVIDUAL: 'Individual',
      COMPETITION_TRAINING_DATA_DESCRIPTION: 'Training Data Description',
      COMPETITION_TRAINING_DATA_DESCRIPTION_PLACEHOLDER: 'Input training data descriptionwithin 2000 characters',
      COMPETITION_EVALUATION_DATA: 'Evaluation Data',
      COMPETITION_EVALUATION_DATA_DESCRIPTION: 'Evaluation Data Description',
      COMPETITION_EVALUATION_DATA_DESCRIPTION_PLACEHOLDER: 'Input data evaluation about competition within 2000 characters',
      COMPETITION_BASELINE_CODE: 'Baseline Code',
      COMPETITION_BASELINE_CODE_PLACEHOLDER: 'code.tar.gz - Baseline code should include train.py and inference.py',
      COMPETITION_BASELINE_CODE_DESCRIPTION: 'Baseline Code Description',
      COMPETITION_BASELINE_CODE_DESCRIPTION_PLACEHOLDER: 'Input baseline code description within 2000 characters',
      COMPETITION_EVALUATION_SCRIPT: 'Evaluation Script',
      COMPETITION_EVALUATION_SCRIPT_PLACEHOLDER: 'Upload Evaluation.py',
      COMPETITION_EVALUATION_METHODS_DESCRIPTION: 'Evaluation Methods Description',
      COMPETITION_EVALUATION_METHODS_DESCRIPTION_PLACEHOLDER: 'Input evaluation methods description within 2000 characters',
      // Competition Host
      COMPETITION_HOST_TITLE: 'Processes for Hosting Competition',
      COMPETITION_HOST_PROCESS_1_TITLE: 'Get Host Permissions',
      COMPETITION_HOST_PROCESS_1_TEXT: `To host competition, you may give some basic information<b>(competition name, organizer, duration, prize, overview, dataset)</b> to aistages@upstage.ai . Hosting composition is only available with a host-authorized account, not with a regular account. Based on the information in e-mail, UPSTAGE issues an ID with host permission. (If you have a regular account then please give that account information to add host permission.)`,
      COMPETITION_HOST_PROCESS_2_TITLE: 'Create Competition',
      COMPETITION_HOST_PROCESS_2_TEXT: `You can create a competition using an account that has been granted host privileges by first step. You can create a competition by entering More tab - Competition Management. If there's not single contest you create, it can be managed separately by <b>Ongoing, Writing, Revising, Reviewing</b>. You can create a competition from that tahb, but it can be canceled later in the process if competition is created other than the information you sent when you are granted host privileges.`,
      COMPETITION_HOST_PROCESS_3_TITLE: 'Input Details for Competition',
      COMPETITION_HOST_PROCESS_3_TEXT: `After you create a competition, enter the details of the competition in three stages. First, in the competition basic information, enter the most basic information that constitutes the competition, such as the name, description, duration, and prize money of the competition. Secondly, in the competition format setting, enter GPU and contest submission restrictions, format and description for the competition. Finally, in the competition usage data, you enter the data you need to proceed with the competition, the baseline, and the evaluation code.`,
      COMPETITION_HOST_PROCESS_4_TITLE: 'Review and Modification',
      COMPETITION_HOST_PROCESS_4_TEXT: `UPSTAGE will conduct a review if you submit all the details of the competition after it has been entered. The data and information required to host the competition are combined, and if there is no problem, upload it immediately so that the competition can be held for a specified period of time. However, if there is an error in the data or information, or if there is something that needs to be corrected, we request a correction. In this case, you can find it in the competition management list mentioned earlier.`,
      COMPETITION_HOST_PROCESS_5_TITLE: 'Start Competition',
      COMPETITION_HOST_PROCESS_5_TEXT: `The competition where all the courses are completed is uploaded for a fixed period of time and provided to users. Hosts are provided with a management board to manage the competition they hosted and a dashboard to view statistics about the competition. This allows you to modify or change data or information during the competition period. (It is also reviewed by UPSTAGE in the event of data changes or modifications.) You can also visualize and view statistics for participants in the competition.`,
      // Competition Hosting Process list
      COMPETITION_PROCESS_LIST_TITLE: 'Competition Host Process List',
      COMPETITION_PROCESS_LIST_TEXT: `You can check the current competition, the competition you are writing, have requested to modify, and reviewing.<br>All competitions are arranged by the creation date.`,
      COMPETITION_CREATE_BTN_TEXT: 'New Competition',
      COMPETITION_APPLY_LIST: 'Competition Apply List',
      MODIFY: 'Modify',
      COMPETITION_FINAL_SUBMISSION: 'Submit',
      LESS_THAN: 'Less than',
      MINUTES: 'mins',
      TIMES: 'times',
      COMPETITION_MODIFICATION_NOTIFICATION: 'Modification Notification',
      COMPETITION_REVIEW_TEXT: 'Sorry. We are reviewing it now',
      COMPETITION_REVIEW_DETAIL: `The competition information you submitted is currently being reviewed by the Upstage staff. <br/>
        If there is a need for revision after review, we will guide you to the revision, and after the revision is complete, it will be uploaded and held.`,
      COMPETITION_GO_TO_COMPETITIONS: 'Go Back to Comeptition List',
      COMPETITION_MODIFICATION_REQUEST: 'Modification Request',
      // Competition Host Admin
      HOST_ADMIN_STATISTICS: 'Statistics',
      HOST_ADMIN_PARTICIPANTS: 'Participants',
      HOST_ADMIN_LEADERBOARD: 'Leaderboard',
      HOST_ADMIN_SUBMISSIONS: 'Submissions',
      HOST_ADMIN_DISCUSSIONS: 'Discussions',
      HOST_ADMIN_COMPETITION_DATA: 'Data',
      // Compeition Host Admin welcome message
      HOST_ADMIN_WELCOME_TITLE_PART1: 'Welcome,  ',
      HOST_ADMIN_WELCOME_TITLE_PART2: '!',
      HOST_ADMIN_WELCOME_TEXT_PART1: 'In this page, ',
      HOST_ADMIN_WELCOME_TEXT_PART2: ` can manage competition by various functions offered by AI STAGES.<br>`,
      HOST_ADMIN_WELCOME_TEXT_PART3: ' can check the statistics and the participant information, and check and manage competition leaderboard, discussions, and data.',
      // Competition Statistics Page
      HOST_ADMIN_STATISTICS_DAILY_TREND: 'Daily Submission Trend',
      HOST_ADMIN_STATISTICS_FAILURE_RATIO: 'Failure Ratio',
      HOST_ADMIN_STATISTICS_ELAPSED_TIME: 'Submission Time',
      HOST_ADMIN_STATISTICS_AVG_SUBMISSION: 'Avg. Submission',
      HOST_ADMIN_STATISTICS_AVG_TRAINING: 'Avg. Training',
      HOST_ADMIN_STATISTICS_AVG_INFERENCE: 'Avg. Inference',
      MONTH: '/',
      DAY: '',
      MINUTE: 'min',
      SECOND: 'sec',
      ONPROGRESS: 'on',
      ENDED: 'end',
      // Competition Data Page
      HOST_DATA_MANAGEMENT: 'Data Management',
      HOST_LAST_MODIFIED: 'Last Modified',
      // Data Dialog
      DATA_MODIFICATION: 'Data Modification',
      CANCEL: 'Cancel',
      MODIFICATION_COMPLETE: 'Submit',
      // Competition Host Submissions Page
      HOST_TOTAL_SUBMISSIONS: 'Total Submissions',
      UPLOADED_AT: 'Uploaded At',
      NAME: 'Name',
      PHASE: 'Phase',
      EVALUATION_METRICS: 'Metrics',
      MODEL: 'Model',
      SHOW_DESCRIPTION: 'Description',
      // Competition Host Discussions Page
      HOST_NOTICE_MANAGEMENT: 'Notice',
      HOST_POST_MANAGEMENT: 'Post',
      // CreateServer Dialog
      SERVER_NAME: 'Server Name',
      GPU_TYPE: 'GPU Type',
      GPU_COUNT: 'GPU Count',
      REQUEST_TIME: 'Request Time',
      PORTS: 'Ports',
      CURRENT_CREDIT: 'Current Credit',
      EXPECTED_CREDIT: 'Expected Credit',
      CREDIT_AFTER_USE: 'Credit After Use',
      // ContainerDetail Dialog
      CONNECT_SSH: 'Connect SSH (Use key file which is downloaded when creating server.)',
      CONNECT_JUPYTER: 'Connect jupyter notebook',
      CONNECT_TENSORBOARD: 'Connect tensorboard',
      // ContainerSettings Dialog
      CONFIRM_TERMINATION: 'Please enter the server name if you agree that all data is deleted by shutting down the server.',
      TERMINATE_SERVER: 'Terminate',
      // Server
      PAUSE: 'Pause',
      RESUME: 'Resume',
      SAVE: 'Save',
      SERVER_INFO: 'Information',
      VERSION_CONTROL: 'Version Control',
      SETTINGS: 'Settings',
      // KLUE
      POWERED_BY: 'Powered by AI STAGES',
      KLUE: 'KLUE',
      KLUE_TEAM: 'Team',
      KLUE_SPONSORS: 'Sponsors',
      KLUE_ORGANIZERS: 'Organizers',
      KLUE_GUIDE_START: 'Check guide to start',
      KLUE_TEAM_TITLE: 'KLUE Team',
      KLUE_RESEARCHERS: 'Researchers',
      KLUE_AUTHORS: 'Correspondence Authors',
      KLUE_MAIL: 'Mail',
      KLUE_WORKS_AT: 'Works at',
      KLUE_STUDIES_AT: 'Studies at',
      KLUE_PS: 'P.S',
      KLUE_SPONSORS_TITLE: 'KLUE Sponsors',
      KLUE_DATA_PROVIDERS_TITLE: 'Data Providers',
      KLUE_ORGANIZERS_TITLE: 'KLUE Organizers',
      KLUE_TASK_TEXT: 'Korean Language Understanding Evaluation (KLUE) benchmarks is a series of datasets to evaluate natural language understanding capability of Korean language models. KLUE contains diverse tasks and domains, which are accessible to anyone without any restrictions.',
      KLUE_TASK_TOTAL: 'Task _ Total Evaluation',
      KLUE_TASK_INDIVIDUAL: 'Tasks _ Individual Tasks',
      KLUE_METRIC: 'Metric',
      KLUE_DATA_SET: 'Data Set',
      MY_RECORD: 'My Record',
      LEAD: 'Lead',
      MEMBERS: 'Members',
      TOTAL_SCORE_EVALUATION: 'Total Score Evaluation',
      KLUE_DATA_DESCRIPTION: 'Data Description',
      KLUE_DATA_DOWNLOAD: 'Data Download',
      KLUE_NEW_SUBMISSION: 'New Submission',
      KLUE_MY_SUBMISSION: 'My Submission',
      KLUE_JOB_LOG: 'Job Log',
      KLUE_SUBMISSION_CLICK: 'Click to find your submission file',
      KLUE_SUBMISSION_CONDITION: 'File should be tar.gz',
      UPLOADED_FILE: 'Uploaded File',
      MODEL_NAME: 'Model Name',
      MODEL_NAME_DESC: 'Used model name such as KoBert',
      MODEL_URL_DESC: 'Model download URL',
      MODEL_SIZE: 'Model Size',
      MODEL_SIZE_DESC: `Based on trainable parameters (for existing architectures, follow the original paper.)\nsmall (70M) / base(110M) / large(340M)`,
      OPTIONAL: 'Optional',
      MODEL_PARAM_DESC: '--batch_size 100 --learning_rate 0.001',
      MODEL_LICENSE_DESC: 'Input',
      MODEL_DESC: 'Describe your model within 1000 characters',
      MORE: 'More',
      TASK_RECORD: 'Task Record',
      TASK_LEADERBOARD: 'Task Leaderboard',
      SELECT: 'Select',
      KLUE_LEADERBOARD_TITLE: 'KLUE Leaderboard',
      KLUE_LEADERBOARD_DESC: 'Unlike other benchmarks, klue benchmarks do not provide total scores and leaderboards for the entire task. On the leaderboard, you can check each score for one model and sort by each evaluation metric.',
      ADD_REPLY: 'Add Reply',
      DELETE: 'Delete',
      KLUE_EVALUATION_METRIC: 'Evaluation Metric',
      KLUE_TASK_LIST: 'KLUE Task List',
      DOWNLOAD_ALL_DATA: 'Download All Data',
      URL: 'URL',
      MODEL_URL: 'Model URL',
      MODEL_SIZE: 'Model Size',
      MODEL_LICENSE: 'Model License',
      INDIVIDUAL_TASK_RANKING: 'Individual Task Ranking',
      LEADERBOARD_DESCRIPTION: 'Leaderboard Description',
      LEADERBOARD_DESCRIPTION_TEXT: 'The evaluation leaderboard for total tasks in KLUE - Benchmark can be found on the full leaderboard tab at the top.',
      CHECK_LEADERBOARD: 'Check Leaderboard',
      MIT: 'MIT',
      APACHE: 'Apache-2.0',
      KLUE_SUBMISSION_NOTICE: 'When submitting the model, you agree to calculate the test score and disclose the score through the leaderboard.<br />Submitted models, source codes, etc. are licensed and specified by participants themselves.',
      NO_MODEL_NAME: 'Please input model name',
      NO_MODEL_SIZE: 'Please select model size',
      NO_MODEL_LICENSE: 'Please select model license',
    },

    KO: {
      SIGNIN: '로그인',
      SIGNIN_TITLE: '로그인하기',
      SIGNUP: '회원가입',
      SIGNUP_TITLE: '회원가입하기',
      SIGNUP_TITLE_OAUTH: '추가 정보 입력',
      SIGNIN_WITH_GOOGLE: '구글 계정으로 로그인',
      SIGNIN_WITH_PASSWORD: '이메일으로 로그인',
      SIGNUP_WITH_GOOGLE: '구글 계정으로 회원가입',
      SIGNUP_WITH_PASSWORD: '이메일으로 회원가입',
      NO_ACCOUNT: '아직 회원이 아니신가요?',
      HAVE_AN_ACCOUNT: '이미 가입을 하셨나요?',
      USEREDIT: '회원정보수정',
      USEREDIT_TITLE: '회원정보수정',
      SIGNOUT: '로그아웃',
      EDIT_USER: '정보수정',
      VCODE: '인증코드',
      INPUT_VCODE: '인증코드를 입력해주세요',
      EMAIL: '이메일',
      INPUT_EMAIL: '이메일을 입력해주세요',
      PASSWORD: '비밀번호',
      INPUT_PASSWORD: '비밀번호를 입력해주세요',
      PHOTO: '사진',
      NICKNAME: '닉네임',
      INPUT_NICKNAME: '닉네임을 입력해주세요',
      COMPANY: '소속',
      INPUT_COMPANY: '소속을 입력해주세요',
      NO_COMPANY: '소속 없음',
      SNS: 'SNS',
      INPUT_SNS: 'SNS를 입력해주세요',
      NO_SNS: 'SNS 없음',
      TERM_AGREE: '서비스 이용약관 동의',
      POLICY_AGREE: '개인정보처리방침 동의',
      AGREE: '동의합니다',
      DISAGREE: '동의하지 않습니다',
      TERM: '서비스 이용 약관',
      POLICY: '개인정보 처리방침',
      PRIZE: '총 상금',
      DATE: '기간',
      TEAM: '참여자 (팀)',
      TOTAL_SUBMISSION: '제출 수',
      BLOCKED: '비공개',
      STATE_WRITING: '작성 중',
      STATE_REVIEW: '검토 중',
      STATE_REVISING: '수정 요청',
      STATE_PENDING: '대회 대기',
      STATE_HALTED: '대회 중단',
      STATE_EXPIRED: '대회 취소',
      NOTIFICATION: '알림',
      CONFIRM: '확인',
      CONFIRM_DELETION: '정말 삭제하시겠습니까?',
      NOT_ALLOWED: '권한이 없습니다.',
      NOTICE: '공지',
      QA: '토론',
      USTAGE_DISCUSSION: 'UStage 강의 리스트',
      USTAGE_DISCUSSION_BRIEF: `AI 모델 개발과 서비스 적용에 필요한 기초 지식을 학습하고 도메인마다 널리 쓰이는 backbone 모델을 다룹니다. </br>
        매주 공개되는 온라인 강의를 edwith에서 수강하고, 강의마다 출제되는 퀴즈와 프로그래밍 과제를 수행합니다. 강좌 별 게시판을 활용해 다른 학생 & 조교 분들과 소통하세요.`,
      PSTAGE_COMPETITION: 'PStage 강의 리스트',
      PSTAGE_COMPETITION_BRIEF: `AI를 많이 활용하는 응용 분야(Computer Vision, 자연어 처리, 추천 시스템)의 실전 프로젝트를 통해 현업과 유사한 엔지니어링 프로세스를 경험합니다.</br>
        AI 모델이 실제 서비스로 이어지는데 필요한 파이프라인 구축과 성능 개선을 위한 실습으로 이루어져 있습니다. 강좌별 게시판을 활용해 강좌에 맞는 실습을 경험하세요.`,
      BOARD: '게시판',
      OVERVIEW: '개요',
      DATA: '데이터',
      SERVER: '서버',
      SUBMISSION: '제출',
      LEADERBOARD: '리더보드',
      OVERVIEW_DESCRIPTION: '전체 개요 설명',
      EVALUATION_METHODS: '평가 방법',
      CODE_REQUIREMENTS: '필수 코드 항목',
      TIMELINE: '타임라인',
      COPYRIGHT_NOTICE: '저작권 공지',
      DATA_DESCRIPTION: '데이터 개요',
      DATA_CODE: '데이터 & 베이스라인 코드',
      NEW_NOTIFICATIONS: '새로운 알림',
      DELETE_NOTIFICATIONS: '전체 알림 지우기',
      NOTIFICATION_TAGGED: '회원님을 태그했습니다',
      NOTIFICATION_COMMENT: '답글을 달았습니다',
      NOTIFICATION_QA: '글을 올렸습니다',
      UPSTAGE: '업스테이지',
      UPSTAGE_EMAIL: 'contact@upstage.ai',
      UPSTAGE_ADDRESS: '경기도 용인시 수지구 광교중앙로 338 (상현동, 광교우미뉴브)',
      UPSTAGE_COPYRIGHT: 'Copyright © 2021 Upstage all rights reserved.',
      COMPANY_INFORMATION: '회사정보',
      SEARCH_PLACEHOLDER: '#  해시태그를 통해 검색할 수 있습니다',
      SIGNIN_ALERT: '이메일 혹은 비밀번호가 올바르지 않습니다',
      FORMAT_INCORRECT: ' 형식이 올바르지 않습니다',
      FIELD_EMPTY: ' 필드를 입력하세요',
      DUPLICATE_EMAIL: '이메일이 중복됩니다',
      DUPLICATE_NICKNAME: '닉네임이 중복됩니다',
      SIGNUP_FAIL: '회원가입 실패',
      USEREDIT_FAIL: '회원정보수정 실패',
      NICKNAME_RULE_FRONT: '닉네임은',
      NICKNAME_RULE_BACK: '을 포함할 수 없습니다',
      SPACES: '공백',
      LINK_DATA: 'Download Data Link',
      LINK_CODE: 'Download Baseline Code Link',
      COPIED: 'copied!',
      RECORD_PRIVATE: 'My Record',
      RECORD_PUBLIC: 'All',
      NEW_SUBMISSION: '새 제출',
      MY_SUBMISSION: '내 제출',
      HYPERPARAMETER: 'Hyperparameter',
      SUBMIT: '제출',
      FAILURE: '실패',
      NO_SOURCE_FILE: '파일을 제출하십시오.',
      DESCRIPTION_TOO_LONG: '설명이 너무 깁니다.',
      SELECT_SERVER_TYPE: '서버 타입을 선택해 주세요.',
      NEW_SERVER: '새 서버',
      SERVER_TYPE: '서버 타입',
      SERVER_DESCRIPTION: '서버에 대한 설명을 작성해주세요 (500자 이내)',
      SERVER_SUBMISSION_DESCRIPTION: '서버 제출에 대한 설명을 작성해주세요 (500자 이내)',
      FILE_DESCRIPTION: '파일에 대한 설명을 작성해주세요 (500자 이내)',
      CREATE_SERVER: '서버 생성',
      MY_SERVER: '내 서버',
      TYPE: '타입',
      CREATED_AT: '생성 시간',
      STATUS: '상태',
      DESCRIPTION: '설명',
      CONNECT_SERVER: '서버 연결',
      SAVE_VERSION: '버전 저장',
      START: '시작',
      TERMINATE: '종료',
      LOAD: '불러오기',
      NEW_POST: '글쓰기',
      SUBMISSION_UPLOADING_TITLE: '제출',
      SUBMISSION_UPLOADING_MESSAGE: '업로드 중입니다',
      REMAINING_SUBMISSION: '오늘 남은 제출 기회',
      COMPETITION_ORGANIZE_DESCRIPTION: '컴페티션 주최 설명',
      COMPETITION_ORGANIZE_LIST: '컴페티션 주최 관리',
      COMPETITION_ORGANIZE_SETTINGS: '컴페티션 주최 설정',
      ORGANIZE_TITLE: 'AI Problem Solving Competition',
      ORGANIZE_DESCRIPTION: `회사 혹은 그룹에서 가지고 있던 다양하고 해결하지 못한
      인공지능 문제들을 컴페티션 형태로 제공합니다. <br />회사 혹은 그룹 외부의 수준 높은 개발자들을 
      대상으로 컴페티션을 개최하여 문제를 해결하고, 보다 정확한 알고리즘을 얻을 수 있습니다.`,
      COMPETITION_BASIC_INFO: '컴페티션 기본 정보',
      COMPETITION_TYPE_SETTINGS: '컴페티션 형식 설정',
      COMPETITION_USAGE_DATA: '컴페티션 사용 데이터',
      COMPETITION_REVIEW: 'AI STAGES 검토',
      COMPETITION_MODIFY_UPLOAD: '수정 & 업로드',
      COMPETITION_BASIC_INFO_INPUT: '컴페티션 기본 정보 입력',
      COMPETITION_NAME: '컴페티션 이름',
      COMPETITION_NAME_PLACEHOLDER: '컴페티션 이름을 입력해 주세요',
      COMPETITION_DESCRIPTION: '컴페티션 설명',
      COMPETITION_DESCRIPTION_PLACEHOLDER: '컴페티션에 대한 설명을 2000자 이내로 입력해 주세요 (공백 포함)',
      COMPETITION_BRIEF: '컴페티션 요약',
      COMPETITION_BRIEF_PLACEHOLDER: '컴페티션 목록에서 확인할 수 있는 짧은 정보입니다. 컴페티션에 대한 요약된 설명을 180자 이내로 입력해 주세요 (공백 포함)',
      COMPETITION_TYPE: '컴페티션 형식',
      COMPETITION_TYPE_PLACEHOLDER: '# 해시태그 입력(EX: NLP, 추천 알고리즘, 시계열, etc...)',
      COMPETITION_TYPE_ADD: '추가',
      COMPETITION_ICON: '컴페티션 아이콘',
      COMPETITION_ICON_TEXT: `컴페티션 목록에서 노출되는 사진 등록<br />
      (권장 사이즈 : 가로 130px * 세로 130px 혹은 가로 1: 세로1 비율)`,
      COMPETITION_ICON_REGISTER: '사진 등록',
      COMPETITION_ICON_DELETE: '삭제',
      COMPETITION_PERIOD: '컴페티션 기간',
      COMPETITION_PERIOD_START: '시작 시점을 입력해 주세요',
      COMPETITION_PERIOD_END: '끝나는 시점을 입력해 주세요',
      COMPETITION_LEADERBOARD_TYPE: '리더보드 형식',
      COMPETITION_LEADERBOARD_TYPE_PUBLIC: '모든 사용자의 Public LB 를 서로 공유',
      COMPETITION_LEADERBOARD_TYPE_PROTECTED: '익명 혹은 id 로 공개',
      COMPETITION_LEADERBOARD_TYPE_PRIVATE: '본인의 Private LB 만 볼 수 있음',
      PUBLIC: 'Public',
      PRIVATE: 'Private',
      PROTECTED: 'Protected',
      PREV_STEP: '이전 단계',
      NEXT_STEP: '다음 단계',
      COMPETITION_TYPE_RESTRICTION_SETTING: '컴페티션 형식 & 제한사항 설정',
      COMPETITION_CLASSIFICATION: '컴페티션 분류',
      COMPETITION_CLASSIFICATION_TRAINING: 'train.py 와 inference.py 를 제출',
      COMPETITION_CLASSIFICATION_INFERENCE: 'inference.py 와 model checkpoint 를 제출',
      COMPETITION_CLASSIFICATION_EVALUATION: 'Result file 을 제출',
      COMPETITION_COPYRIGHT: '저작권 설명',
      COMPETITION_COPYRIGHT_PLACEHOLDER: '컴페티션과 관련된 저작권 설명을 2000자 이내로 입력해 주세요.',
      COMPETITION_SUBMISSIONS_PER_DAY: '하루 제출 횟수',
      COMPETITION_NO_LIMIT: '제한 없음',
      COMPETITION_ETC: '기타',
      COMPETITION_SUBMISSIONS_1: '1회',
      COMPETITION_SUBMISSIONS_2: '3회 이하',
      COMPETITION_SUBMISSIONS_3: '5회 이하',
      COMPETITION_SUBMISSIONS_4: '7회 이하',
      COMPETITION_TRAINING_TIME: 'Training Time',
      COMPETITION_TRAINING_1: '60분 이하',
      COMPETITION_TRAINING_2: '90분 이하',
      COMPETITION_TRAINING_3: '120분 이하',
      COMPETITION_TRAINING_4: '180분 이하',
      COMPETITION_INFERENCE_TIME: 'Inference Time',
      COMPETITION_INFERENCE_1: '1분 이하',
      COMPETITION_INFERENCE_2: '3분 이하',
      COMPETITION_INFERENCE_3: '5분 이하',
      COMPETITION_INFERENCE_4: '10분 이하',
      COMPETITION_SERVER: 'Server',
      COMPETITION_NOT_PROVIDED: '제공 안함',
      COMPETITION_PROVIDED: '제공',
      COMPETITION_TRAINING_DATA: '학습 데이터',
      COMPETITION_DATA_FILE_PLACEHOLDER: '데이터 파일 업로드 (권장 : 파일 크기 1GB 이하)',
      COMPETITION_UPLOAD: '업로드',
      COMPETITION_DATA_URL_PLACEHOLDER: '데이터가 업로드된 url 주소',
      COMPETITION_INDIVIDUAL: '개별 전달',
      COMPETITION_TRAINING_DATA_DESCRIPTION: '학습 데이터 설명',
      COMPETITION_TRAINING_DATA_DESCRIPTION_PLACEHOLDER: '학습 데이터에 대한 설명을 2000자 이내로 입력해 주세요',
      COMPETITION_EVALUATION_DATA: '평가 데이터',
      COMPETITION_EVALUATION_DATA_DESCRIPTION: '평가 데이터 설명',
      COMPETITION_EVALUATION_DATA_DESCRIPTION_PLACEHOLDER: '평가 데이터에 대한 설명을 2000자 이내로 입력해 주세요',
      COMPETITION_BASELINE_CODE: '베이스라인 코드',
      COMPETITION_BASELINE_CODE_PLACEHOLDER: 'code.tar.gz - Baseline code는 train.py 와 inference.py를 필수적으로 포함합니다',
      COMPETITION_BASELINE_CODE_DESCRIPTION: '코드 설명',
      COMPETITION_BASELINE_CODE_DESCRIPTION_PLACEHOLDER: '베이스라인 코드에 대한 설명을 2000자 이내로 입력해 주세요',
      COMPETITION_EVALUATION_SCRIPT: '평가 스크립트',
      COMPETITION_EVALUATION_SCRIPT_PLACEHOLDER: 'evaluation.py 를 업로드해 주세요',
      COMPETITION_EVALUATION_METHODS_DESCRIPTION: '평가 방법 설명',
      COMPETITION_EVALUATION_METHODS_DESCRIPTION_PLACEHOLDER: '평가 방법에 대한 설명을 2000자 이내로 입력해 주세요',
      // Competition Host
      COMPETITION_HOST_TITLE: '컴페티션 주최 프로세스',
      COMPETITION_HOST_PROCESS_1_TITLE: '호스트 권한 받기',
      COMPETITION_HOST_PROCESS_1_TEXT: `컴페티션을 주최하기 위해서는 가장 먼저 컴페티션에 대한 정보<b>(이름, 주최 기관 정보, 기간, 상금, 개요, 데이터 종류)</b>를 aistages@upstage.ai로 보내주셔야합니다. AI STAGES에서 컴페티션을 주최할 때에는 반드시 일반 계정이 아닌, 호스트 권한을 부여 받은 계정으로 컴페티션을 주최할 수 있습니다. 메일로 보내주신 정보를 바탕으로 컴페티션에 대해 이해한 후 UPSTAGE애서는 고객님에게 호스트 권한이 부여된 아이디를 배정합니다. (개인 계정이 있는 경우 해당 계정이 호스트 권한을 추가할 수 있습니다.)`,
      COMPETITION_HOST_PROCESS_2_TITLE: '컴페티션 생성',
      COMPETITION_HOST_PROCESS_2_TEXT: `호스트 권한을 부여받은 계정을 사용하여 컴페티션을 생성할 수 있습니다. 상단 More 탭 - 컴페티션 관리 탭으로 들어가면 컴페티션을 새로 생성할 수 있으며, 생성하는 컴페티션이 한가지가 아닐 경우에는 <b>&lt;진행중인 컴페티션, 작성중인 컴페티션, 수정 요청 받은 컴페티션, 검토중인 컴페티션&gt;</b>으로 구분하여 관리할 수 있습니다. 해당 탭에서 컴페티션을 생성할 수 있지만, 호스트 권한을 부여 받을시 보내주신 정보 이외의 컴페티션이 생성될 경우 프로세스 후반부에서 취소될 수 있습니다.`,
      COMPETITION_HOST_PROCESS_3_TITLE: '상세정보 입력',
      COMPETITION_HOST_PROCESS_3_TEXT: `컴페티션을 생성한 후에는 컴페티션에 대한 상세 정보를 3단계에 걸쳐 입력합니다. 먼저 컴페티션 기본 정보에서는 컴페티션의 이름과, 설명, 기간, 상금 등 컴페티션을 구성하는 가장 기본적인 정보를 입력합니다. 두번쨰로 컴페티션 형식 설정에서는 컴페티션에 활용할 GPU나 컴페티션의 제출 제한사항, 컴페티션의 형식과 설명등을 입력합니다. 마지막으로 컴페티션 사용데이터에서는 컴페티션을 진행하기 위해 필요한 데이터와 베이스라인, 평가 코드 등을 입력합니다.`,
      COMPETITION_HOST_PROCESS_4_TITLE: '검토 & 수정',
      COMPETITION_HOST_PROCESS_4_TEXT: `컴페티션에 대한 상세 정보가 모두 입력된 후 제출하시면 UPSTAGE에서 검토를 진행하게 됩니다. 해당 컴페티션 주최시 필요한 데이터와 정보들을 종합하여 검토 후 문제가 없다고 판단되면 바로 업로드하여 컴페티션이 지정된 기간동안 개최될 수 있도록 합니다. 하지만 데이터 혹은 정보에 오류가 있거나, 수정이 필요한 사항이 있을 경우에는 수정 요청을 드립니다. 이 경우에는 앞서 언급한 컴페티션 관리 리스트에서 확인할 수 있습니다.`,
      COMPETITION_HOST_PROCESS_5_TITLE: '컴페티션 업로드',
      COMPETITION_HOST_PROCESS_5_TEXT: `모든 과정이 마무리된 컴페티션은 정해진 기간동안 업로드되어 사용자들에게 제공됩니다. 호스트 분들은 주최한 컴페티션을 관리할 수 있는 매니지 보드와 컴페티션에 대한 통계자료를 확인할 수 있는 대시보드를 제공받습니다. 이를 활용하여 컴페티션 기간 도중에 데이터나 정보를 수정하거나 변경할 수 있습니다. (데이터 변경 혹은 수정 시에도 마찬가지로 UPSTAGE를 통해 검토 후 진행됩니다.) 또한 컴페티션에 참여하는 참여자들에 대한 통계자료를 시각화하여 확인할 수 있습니다.`,
      // Competition Hosting Process list
      COMPETITION_PROCESS_LIST_TITLE: '컴페티션 주최 프로세스 리스트',
      COMPETITION_PROCESS_LIST_TEXT: `현재 진행중인 컴페티션과 작성중인 컴페티션, 수정 요청 받은 컴페티션, 검토중인 컴페티션을 모두 확인할 수 있습니다.<br>모든 컴페티션은 생성 일자를 기준으로 정렬되어 제공됩니다.`,
      COMPETITION_CREATE_BTN_TEXT: '컴페티션 생성',
      COMPETITION_APPLY_LIST: '컴페티션 신청 내역',
      MODIFY: '수정하기',
      COMPETITION_FINAL_SUBMISSION: '최종 제출',
      LESS_THAN: '이하',
      MINUTES: '분',
      TIMES: '회',
      COMPETITION_MODIFICATION_NOTIFICATION: '수정사항 알림',
      COMPETITION_REVIEW_TEXT: 'Sorry. We are reviewing it now',
      COMPETITION_REVIEW_DETAIL: `제출해주신 컴페티션 정보는 지금 업스테이지 담당자가 검토중에 있습니다. <br/>
        검토 후 수정이 필요할 경우 수정사항을 안내드리며, 수정이 모두 완료된 후에는 업로드되어 개최될 예정입니다. `,
      COMPETITION_GO_TO_COMPETITIONS: '컴페티션 관리 리스트로 가기',
      COMPETITION_MODIFICATION_REQUEST: '수정요청 사항',
      // Competition Host Admin
      HOST_ADMIN_STATISTICS: '컴페티션 통계',
      HOST_ADMIN_PARTICIPANTS: '참여자 데이터',
      HOST_ADMIN_LEADERBOARD: '리더보드',
      HOST_ADMIN_SUBMISSIONS: '전체 제출',
      HOST_ADMIN_DISCUSSIONS: '게시글 관리',
      HOST_ADMIN_COMPETITION_DATA: '컴페티션 관리',
      /* Competition Host Admin */
      // Welcome message
      HOST_ADMIN_WELCOME_TITLE_PART1: '환영합니다 ',
      HOST_ADMIN_WELCOME_TITLE_PART2: '님.',
      HOST_ADMIN_WELCOME_TEXT_PART1: '이 페이지에서는 ',
      HOST_ADMIN_WELCOME_TEXT_PART2: `님이 AI STAGES에서 사용하시는 다양한 기능들을 관리할 수 있습니다. <br>`,
      HOST_ADMIN_WELCOME_TEXT_PART3: '님이 개최하신 컴페티션의 통계자료와 참여자들의 정를 확인하실 수 있고, 컴페티션의 리더보드와 게시글과 데이터를 확인 & 관리하실 수 있습니다.',
      // Competition Statistics Page
      HOST_ADMIN_STATISTICS_DAILY_TREND: '일자별 제출 트렌드',
      HOST_ADMIN_STATISTICS_FAILURE_RATIO: 'Fail 비율',
      HOST_ADMIN_STATISTICS_ELAPSED_TIME: '제출당 평균 시간',
      HOST_ADMIN_STATISTICS_AVG_SUBMISSION: '평균 제출',
      HOST_ADMIN_STATISTICS_AVG_TRAINING: '평균 학습 시간',
      HOST_ADMIN_STATISTICS_AVG_INFERENCE: '평균 인퍼런스 시간',
      MONTH: '월',
      DAY: '일',
      MINUTE: '분',
      SECOND: '초',
      ONPROGRESS: '진행중',
      ENDED: '종료',
      // Competition Data Page
      HOST_DATA_MANAGEMENT: '데이터 관리',
      HOST_LAST_MODIFIED: '최종 수정일',
      // Data Dialog
      DATA_MODIFICATION: '데이터 수정하기',
      CANCEL: '취소',
      MODIFICATION_COMPLETE: '수정 완료',
      // Competition Host Submissions Page
      HOST_TOTAL_SUBMISSIONS: '참여자 전체 제출',
      UPLOADED_AT: '업로드 시간',
      NAME: '이름',
      PHASE: 'Phase',
      EVALUATION_METRICS: '평가 메트릭',
      MODEL: '모델',
      SHOW_DESCRIPTION: '설명 보기',
      // Competition Host Discussions Page
      HOST_NOTICE_MANAGEMENT: '공지사항 관리',
      HOST_POST_MANAGEMENT: '게시글 관리',
      // CreateServer Dialog
      SERVER_NAME: '서버 이름',
      GPU_TYPE: 'GPU 타입',
      GPU_COUNT: 'GPU 개수',
      REQUEST_TIME: '대여 시간',
      PORTS: '포트',
      CURRENT_CREDIT: '보유 전체 크레딧',
      EXPECTED_CREDIT: '사용 예정 크레딧',
      CREDIT_AFTER_USE: '남는 크레딧',
      // ContainerDetail Dialog
      CONNECT_SSH: 'SSH 연결 (서버 생성시에 다운로드된 key 파일을 이용하세요. 파일을 분실하지 않도록 주의 해주세요)',
      CONNECT_JUPYTER: '노트북 연결',
      CONNECT_TENSORBOARD: 'Tensorboard 이용하기',
      // ContainerSettings Dialog
      CONFIRM_TERMINATION: '서버를 종료하여 모든 자료가 사라지는 것을 인지하였으면 서버 이름을 입력해 주세요',
      TERMINATE_SERVER: '서버 반납',
      PAUSE: '일시정지',
      RESUME: '재시작',
      SAVE: '저장',
      SERVER_INFO: '서버 정보',
      VERSION_CONTROL: '버전 관리',
      SETTINGS: '설정',
      // KLUE
      POWERED_BY: 'Powered by AI STAGES',
      KLUE: 'KLUE',
      KLUE_TEAM: 'Team',
      KLUE_SPONSORS: 'Sponsors',
      KLUE_ORGANIZERS: 'Organizers',
      KLUE_GUIDE_START: 'Check guide to start',
      KLUE_TEAM_TITLE: 'KLUE Team',
      KLUE_RESEARCHERS: 'Researchers',
      KLUE_AUTHORS: 'Correspondence Authors',
      KLUE_MAIL: 'Mail',
      KLUE_WORKS_AT: 'Works at',
      KLUE_STUDIES_AT: 'Studies at',
      KLUE_PS: 'P.S',
      KLUE_SPONSORS_TITLE: 'KLUE Sponsors',
      KLUE_DATA_PROVIDERS_TITLE: 'Data Providers',
      KLUE_ORGANIZERS_TITLE: 'KLUE Organizers',
      KLUE_TASK_TEXT: 'Korean Language Understanding Evaluation (KLUE) benchmarks is a series of datasets to evaluate natural language understanding capability of Korean language models. KLUE contains diverse tasks and domains, which are accessible to anyone without any restrictions.',
      KLUE_TASK_TOTAL: 'Task _ Total Evaluation',
      KLUE_TASK_INDIVIDUAL: 'Tasks _ Individual Tasks',
      KLUE_METRIC: 'Metric',
      KLUE_DATA_SET: 'Data Set',
      MY_RECORD: 'My Record',
      LEAD: 'Lead',
      MEMBERS: 'Members',
      TOTAL_SCORE_EVALUATION: 'Total Score Evaluation',
      KLUE_DATA_DESCRIPTION: 'Data Description',
      KLUE_DATA_DOWNLOAD: 'Data Download',
      KLUE_NEW_SUBMISSION: 'New Submission',
      KLUE_MY_SUBMISSION: 'My Submission',
      KLUE_JOB_LOG: 'Job Log',
      KLUE_SUBMISSION_CLICK: 'Click to find your submission file',
      KLUE_SUBMISSION_CONDITION: 'File should be tar.gz',
      UPLOADED_FILE: 'Uploaded File',
      MODEL_NAME: 'Model Name',
      MODEL_NAME_DESC: 'Used model name such as KoBert',
      MODEL_URL_DESC: 'Model download URL',
      MODEL_SIZE: 'Model Size',
      OPTIONAL: 'Optional',
      MODEL_PARAM_DESC: '--batch_size 100 --learning_rate 0.001',
      MODEL_DESC: 'Describe your model within 1000 characters',
      MODEL_LICENSE_DESC: 'Input',
      MORE: 'More',
      TASK_RECORD: 'Task Record',
      TASK_LEADERBOARD: 'Task Leaderboard',
      SELECT: 'Select',
      KLUE_LEADERBOARD_TITLE: 'KLUE Leaderboard',
      KLUE_LEADERBOARD_DESC: 'Unlike other benchmarks, klue benchmarks do not provide total scores and leaderboards for the entire task. On the leaderboard, you can check each score for one model and sort by each evaluation metric.',
      ADD_REPLY: 'Add Reply',
      DELETE: 'Delete',
      KLUE_EVALUATION_METRIC: 'Evaluation Metric',
      KLUE_TASK_LIST: 'KLUE Task List',
      DOWNLOAD_ALL_DATA: 'Download All Data',
      URL: 'URL',
      MODEL_URL: 'Model URL',
      MODEL_SIZE: 'Model Size',
      MODEL_SIZE_DESC: `Based on trainable parameters (for existing architectures, follow the original paper.)\nsmall (70M) / base(110M) / large(340M)`,
      MODEL_LICENSE: 'Model License',
      INDIVIDUAL_TASK_RANKING: 'Individual Task Ranking',
      LEADERBOARD_DESCRIPTION: 'Leaderboard Description',
      LEADERBOARD_DESCRIPTION_TEXT: 'The evaluation leaderboard for total tasks in KLUE - Benchmark can be found on the full leaderboard tab at the top.',
      CHECK_LEADERBOARD: 'Check Leaderboard',
      MIT: 'MIT',
      APACHE: 'Apache-2.0',
      KLUE_SUBMISSION_NOTICE: 'When submitting the model, you agree to calculate the test score and disclose the score through the leaderboard.<br />Submitted models, source codes, etc. are licensed and specified by participants themselves.',
      NO_MODEL_NAME: 'Please input model name',
      NO_MODEL_SIZE: 'Please select model size',
      NO_MODEL_LICENSE: 'Please select model license',
    },
  },
});