<template>
  <div class="task-cover-row" :style="{ backgroundImage }">
    <div class="task-cover-title">
      <span class="task-tag" :class="tag(task.dataset).split(' ')[0].toLowerCase()">
        {{tag(task.dataset)}}
      </span> - {{task.name}}
    </div>
    <div class="task-cover-content">
      <div class="task-cover-subrow" v-if="task.metric && task.metric.length > 0">
        <div class="task-cover-subrow-title">{{klueMetricString}}</div>
        <div class="task-cover-subrow-content" v-html="joinTab(task.metric)"></div>
      </div>
      <div class="task-cover-subrow" v-if="task.lead && task.lead.length > 0">
        <div class="task-cover-subrow-title">{{leadString}}</div>
        <div class="task-cover-subrow-content" v-if="task.lead">
          <span v-for="(leader, idx) in task.lead" :key="idx">{{leader}}</span>
        </div>
      </div>
      <div class="task-cover-subrow" v-if="task.members && task.members.length > 0">
        <div class="task-cover-subrow-title">{{membersString}}</div>
        <div class="task-cover-subrow-content" v-if="task.members">
          <span v-for="(member, idx) in task.members" :key="idx">{{member}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Constant from '@/store/constant.js';
export default {
  name: 'TaskCoverRow',
  props: {
    task: Object
  },
  methods: {
    tag(name) {
      if(name.length === 0) return this.klueString;
      return name[0];
    }, 
    joinTab(arr) {
      return arr.join(' & ');
    },
  }, 
  mounted() {
  },
  computed: {
    klueString() {
      return Constant.STRING[this.$store.state.language].KLUE;
    },
    klueMetricString() {
      return Constant.STRING[this.$store.state.language].KLUE_METRIC;
    },
    klueDatasetString() {
      return Constant.STRING[this.$store.state.language].KLUE_DATA_SET;
    },
    leadString() {
      return Constant.STRING[this.$store.state.language].LEAD;
    },
    membersString() {
      return Constant.STRING[this.$store.state.language].MEMBERS;
    },
    backgroundImage() {
      if(!this.task) return '';
      else if(this.task.is_multiple) return `url(${require('@/assets/task/bg0.jpg')})`;
      return `url(${require('@/assets/task/bg' + (this.task.id % 8 + 1) +'.jpg')})`;
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/global.scss';
.task-cover-row {
  background-size: cover;
  height: 216px;
}

.task-cover-title {
  @extend .subheadline-heavy;
  color: white;
  padding: 32px 48px 28px 48px;
}

.task-cover-content {
  color: white;
  padding-left: 48px;

  .task-cover-subrow {
    display: flex;
    margin-bottom: 6px;

    &-title {
      @extend .body-heavy;
      width: 120px;
    }

    &-content {
      @extend .caption-light;
      line-height: 28px;
      opacity: 0.8;

      span {
        margin-right: 16px;
      }
    }
  }
}
</style>