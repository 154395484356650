const reformatSubmissions = submissions => {
  for (let i = 0; i < submissions.length; i++) {
    if (
      submissions[i]['phase'] === 'Created' ||
      submissions[i]['phase'] === 'Finished' ||
      submissions[i]['phase'] === 'Failed'
    ) {
      submissions[i]['state'] = 'Done';
    } else {
      let job = submissions[i]['phase'].toLowerCase() + 's';
      let len = submissions[i][job].length;
      if (len === 0) {
        submissions[i]['state'] = 'Created';
      } else {
        submissions[i]['state'] = submissions[i][job][len - 1]['state'];
        submissions[i]['job_name'] = submissions[i][job][len - 1]['job_name'];
      }
    }

    if (submissions[i].trainings) {
      submissions[i].trainings.forEach(training => {
        submissions[i]['last_job_name'] = training['job_name'];
      });
    }
    if (submissions[i].inferences) {
      submissions[i].inferences.forEach(inference => {
        submissions[i]['last_job_name'] = inference['job_name'];
      });
    }
  }
  return submissions;
};

const reformatDailySubmissionCount = dailySubmissions => {
  let today = new Date();
  var afterTodayIdx = 0;

  for (let i = 0; i < dailySubmissions.length; ++i) {
    let logDate = new Date(dailySubmissions[i]['date']);
    if (today.getTime() - logDate.getTime() < 0) {
      afterTodayIdx = i;
      break;
    } else {
      dailySubmissions[i]['xLabel'] = dailySubmissions[i]['date']
        .slice(-5)
        .replace('-', '.');
    }
  }
  // for color gradient
  let colorStepValue = 8 / afterTodayIdx;
  for (let i = 0; i < afterTodayIdx; ++i) {
    dailySubmissions[i]['class'] = `bar-gradient-${Math.min(
      Math.round(colorStepValue * i + 1),
      8,
    )}`;
  }

  // for x-tick (5 step)
  let xTickStepValue = 0.0;
  const stepSize = 0.25;
  for (let i = 0; i < afterTodayIdx; ++i) {
    if (Math.round((afterTodayIdx - 1) * xTickStepValue) === i) {
      xTickStepValue += stepSize;
      dailySubmissions[i]['xtick'] = true;
    } else {
      dailySubmissions[i]['xtick'] = false;
    }
  }

  return dailySubmissions.slice(0, afterTodayIdx);
};

const reformatCompetitionElapsedTime = elapsedTimes => {
  // training job
  var reformattedData = {};
  const reducer = (accumulator, currentValue) =>
    accumulator + parseFloat(currentValue);
  var arrayLength = 0;

  arrayLength = elapsedTimes['training_job'].length;
  var trainingJobDict = {
    min: arrayLength
      ? Math.round(Math.min(...elapsedTimes['training_job']))
      : 0,
    avg: Math.round(
      elapsedTimes['training_job'].reduce(reducer, 0) / arrayLength,
    ),
    max: arrayLength
      ? Math.round(Math.max(...elapsedTimes['training_job']))
      : 0,
  };
  reformattedData.training_job = trainingJobDict;

  // inference job
  arrayLength = elapsedTimes['inference_job'].length;
  var inferenceJobDict = {
    min: arrayLength
      ? Math.round(Math.min(...elapsedTimes['inference_job']))
      : 0,
    avg: Math.round(
      elapsedTimes['inference_job'].reduce(reducer, 0) / arrayLength,
    ),
    max: arrayLength
      ? Math.round(Math.max(...elapsedTimes['inference_job']))
      : 0,
  };
  reformattedData.inference_job = inferenceJobDict;
  return reformattedData;
};

const getTimeDiffString = (aDate, bDate) => {
  let ms = aDate - bDate;
  let s = parseInt(ms / 1000);
  let diff = '';
  if (s < 60) {
    diff = `${s}s`;
  } else {
    let m = parseInt(s / 60);
    if (m < 60) {
      diff = `${m}m`;
    } else {
      let h = parseInt(m / 60);
      if (h < 24) {
        diff = `${h}h`;
      } else {
        let d = parseInt(h / 24);
        diff = `${d}d`;
      }
    }
  }
  return diff;
};

export {
  reformatSubmissions,
  reformatDailySubmissionCount,
  reformatCompetitionElapsedTime,
  getTimeDiffString,
};
