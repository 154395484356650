<template>
  <div class="mysubmission-container">
    <v-data-table
      class="data-table"
      @click:row.self="toLogView"
      :headers="headers"
      :items="submissions"
      :loading="loading"
      :items-per-page="5"
    >
      <template v-slot:[`item.local_id`]="{ item }">
        {{tag(task.name) + ' - ' + item.local_id}}
      </template>
      
      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.created_at | moment('YYYY-MM-DD HH:mm') }}
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <span style="cursor: pointer;" @click.stop="showPopup('Description', item.description, $event)">{{moreString}}</span>
      </template>

      <template v-slot:[`item.hyperparameters`]="{ item }">
        <span style="cursor: pointer;" @click.stop="showPopup('Hyperparameters', item.hyperparameters, $event)">{{moreString}}</span>
      </template>

      <template v-slot:[`item.phase`]="{ item }">
        <v-btn
          :class="'phase-' + item.phase.toLowerCase()"
          width="96"
          height="32"
          >{{ item.phase }}</v-btn
        >
      </template>
    </v-data-table>
    <simple-dialog 
      ref="dialog"
      :dialog="dialog" 
      :title="popupTitle" 
      :content="popupContent" 
      @close:simple="closePopup"
    ></simple-dialog>
  </div>
</template>

<script>
import Constant from '@/store/constant.js';
import SimpleDialog from '@/components/dialog/SimpleDialog.vue';
export default {
  name: 'MySubmission',
  components: {
    SimpleDialog
  },
  props: {
    id: String,
    task: Object,
  },
  computed: {
    moreString() {
      return Constant.STRING[this.$store.state.language].MORE;
    },
    klueString() {
      return Constant.STRING[this.$store.state.language].KLUE;
    }
  },
  methods: {
    tag(name) {
      if(name === 'Total Evaluation') return this.klueString;
      return name.match(/\b(\w)/g).join('');
    }, 
    toLogView(value) {
      console.log(value);
      this.$router.push({ path: `log/${value.local_id}` });
    },
    showPopup(title, value, event) {
      this.popupTitle = title;
      this.popupContent = value;
      this.dialog = true;
    },
    closePopup() {
      this.dialog = false;
    }, 
    fetchSubmissions() {
      this.submissions = [];
      this.loading = true;
      this.$store
        .dispatch('fetchSubmissions', this.id)
        .then(data => {
          this.submissions = data;
        })
        .catch(e => alert(e))
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.fetchSubmissions();
  },
  data() {
    return {
      loading: false,
      dialog: false,
      popupTitle: '',
      popupContent: '',
      headers: [
        {
          text: 'Submission ID',
          value: 'local_id',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Phase',
          value: 'phase',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Model',
          value: 'name',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Description',
          value: 'description',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Hyperparameters',
          value: 'hyperparameters',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Created_at',
          value: 'created_at',
          align: 'center',
          sortable: false,
        },
      ],
      submissions: []
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';
.mysubmission-container {
  flex: 1;
}

.data-table {
  width: 100%;
  &::v-deep .v-data-table-header {
    background-color: #FBF8F7;
    th {
      color: #515151 !important;
      @extend .body-heavy;
    }
  }

  td {
    @extend .body-light;
  }

  &::v-deep .v-data-footer {
    @extend .body-light;
    .v-select__selection {
      @extend .body-light;
    }
  }
}
</style>