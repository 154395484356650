import Mention from './Mention';

export default class Hashtag extends Mention {
  get name() {
    return 'hashtag';
  }

  get defaultOptions() {
    return {
      matcher: {
        char: '#',
        allowSpaces: false,
        startOfLine: false,
      },
      mentionClass: 'hashtag',
      suggestionClass: 'hashtag-suggestion',
    };
  }

  get schema() {
    return {
      ...super.schema,
      toDOM: node => {
        // use a dummy domain because URL cannot handle relative urls

        return [
          'span',
          {
            class: this.options.mentionClass,
            'data-hashtag-id': node.attrs.id,
          },
          `${this.options.matcher.char}${node.attrs.label}`,
        ];
      },
      parseDOM: [
        {
          tag: 'span[data-hashtag-id]',
          getAttrs: dom => {
            const id = dom.getAttribute('data-hashtag-id');
            const label = dom.innerText
              .split(this.options.matcher.char)
              .join('');
            return { id, label };
          },
        },
      ],
    };
  }
}
