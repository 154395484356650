<template>
  <div
    :class="['user-card', { 'user-card-state-button': stateButton }]"
    v-if="user.id"
  >
    <v-row class="user-content" no-gutters justify="center" align="center">
      <v-col>
        <v-row no-gutters>
          <v-col class="nickname">
            {{ user.detail.nickname }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="company">
            {{ user.detail.company ? user.detail.company : noCompanyString }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="username">
            {{ user.username }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="sns">
            {{ user.detail.sns ? user.detail.sns : noSnsString }}
          </v-col>
        </v-row>
        <v-row no-gutters style="margin-top:19px;" v-if="stateButton">
          <v-col cols="auto">
            <v-btn depressed class="btn" @click="userEditorDialog = true">{{
              editUserString
            }}</v-btn>
          </v-col>
          <v-dialog v-model="userEditorDialog" width="440px">
            <UserEditorDialog :user="user"></UserEditorDialog>
          </v-dialog>
          <v-col cols="auto" style="margin-left:10px;">
            <v-btn depressed class="btn" @click="signOut">{{
              signoutString
            }}</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col justify="center" align="center" cols="auto">
        <v-img
          :src="user.detail.image_url"
          class="hidden-xs-only rounded-circle"
          max-width="100"
          max-height="100"
          height="100"
          width="100"
        />
        <div :class="['circle', user.detail.type.toLowerCase()]"></div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UserEditorDialog from '@/components/dialog/UserEditorDialog';
import Constant from '@/store/constant.js';

export default {
  props: {
    userID: String,
    stateButton: Boolean,
  },

  components: {
    UserEditorDialog,
  },

  computed: {
    signoutString() {
      return Constant.STRING[this.$store.state.language].SIGNOUT;
    },
    editUserString() {
      return Constant.STRING[this.$store.state.language].EDIT_USER;
    },
    noCompanyString() {
      return Constant.STRING[this.$store.state.language].NO_COMPANY;
    },
    noSnsString() {
      return Constant.STRING[this.$store.state.language].NO_SNS;
    },
    userEditorDialog: {
      get: function() {
        return this.$store.state.userEditorDialog;
      },
      set: function(newValue) {
        this.$store.state.userEditorDialog = newValue;
      },
    },
  },

  methods: {
    fetchUser() {
      let userID = this.userID;
      this.$store
        .dispatch('fetchUserDetail', { userID })
        .then(data => {
          this.user = data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    signOut() {
      this.$store.dispatch('signOut').finally(() => this.$router.push('/'));
    },
  },

  created() {
    this.fetchUser();
  },

  data() {
    return {
      user: {},
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';

.user-card {
  width: 420px;
  height: 180px !important;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 29px;
  padding-bottom: 29px;
}
.user-card-state-button {
  height: 210px !important;
}
.user-content {
  height: 100%;
}
.nickname {
  @extend .subheader-heavy;
  color: #212121;
}
.group {
  @extend .caption-light;
  color: #212121;
}
.company {
  margin-top: 5px;
  @extend .caption-heavy;
  color: #212121;
}
.username {
  @extend .caption-light;
  color: #212121;
}
.sns {
  @extend .caption-light;
  color: #212121;
}
.btn {
  width: 70px;
  height: 30px !important;
  letter-spacing: normal !important;
  background: #ffffff !important;
  border: 1px solid #e84660;
  box-sizing: border-box;
  border-radius: 5px;
  @extend .caption-heavy;
  color: #e84660;
}
.circle {
  background-color: #ffffff;
  border-radius: 110px;
  height: 110px;
  width: 110px;
  margin-top: -105px;
}
</style>
