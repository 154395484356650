<template>
  <div class="leaderboard-container">
    <div class="leaderboard-text-row">
      <div class="leaderboard-title">{{klueLeaderboardTitleString}}</div>
      <div class="leaderboard-desc" v-html="klueLeaderboardDescString"></div>
    </div>
    <div class="leaderboard-button-row">
      <v-btn
        v-for="(value, key) in leaderboardSize"
        :key="key"
        width="96"
        height="36"
        depressed
        class="tab-button"
        :class="tab === key? 'tab-button-active' : ''"
        @click="changeTab(key)"
        >{{ value }}</v-btn>
    </div>
    <div class="leaderboard-row">
      <v-data-table
        hide-default-header
        class="data-table"
        :headers="headers"
        :items="records"
        :loading="loading"
        :items-per-page="5"
      >

        <template v-slot:header="{props: {headers}}">
          <tr>
            <th v-for="header in topHeaders" 
              :key="header"
              :colspan="headers.filter(el => el.text.includes(header)).length">
              {{header.includes(' ') ? header.split(' ')[0] : header}}
            </th>
          </tr>
          <tr>
            <th v-for="header in headers"
              :key="header.value">
              <div v-if="header.value.includes('\\')" class="header-inner">
                <span v-if="header.text.split('\\')[1].includes(' ')">
                  {{capitalize(header.text.split('\\')[1].split(' ')[1])}}
                  <sup>
                    {{superString(header.text.split('\\')[1])}}
                  </sup>
                </span>
                <span v-else>{{header.text.split('\\')[1]}}</span>
                <div class="sort">
                  <img 
                    :src="require('@/assets/images/icons/sort_up.svg')" 
                    width="6px" height="6px"
                    :class="header.value === sorting && ascending ? 'sort-active' : ''" 
                    @click="sortRecord(header.value, true)">
                  <img 
                    :src="require('@/assets/images/icons/sort_down.svg')" 
                    width="6px" height="6px"
                    :class="header.value === sorting && !ascending ? 'sort-active' : ''" 
                    @click="sortRecord(header.value, false)">
                </div>
              </div>
            </th>
          </tr>
        </template>

        <template v-for="header in headers" v-slot:[`item.${header.value}`]="{item}">
          <span :key="header.value" :class="item[header.value].top ? 'top-score' : ''">
            {{item[header.value].hasOwnProperty('value') ? item[header.value].value : item[header.value]}}
          </span>
        </template>

        <template v-slot:[`item.ranking`]="{ item }">
          <div class="ranking">
            <div class="ranking-left"></div>
            <div class="ranking-content">{{ records.indexOf(item) + 1 }}</div>
          </div>
        </template>

        <template v-slot:[`item.description`]="{ item }">
          <span style="cursor: pointer;" @click.stop="showPopup(item)">{{moreString}}</span>
        </template>

        <template v-slot:[`item.downloadURL`]="{ item }">
          <a target="_blank" :href="item.downloadURL" v-if="item.downloadURL">
            <img :src="download" width="16">
          </a>
        </template>

      </v-data-table>
      <detail-description-dialog 
        ref="dialog"
        :dialog="dialog" 
        :headers="headers"
        :topHeaders="topHeaders" 
        :records="records"
        :content="popupContent" 
        :isMultiple="true"
        @close:simple="closePopup"
      ></detail-description-dialog>
    </div>
  </div>
</template>

<script>
import Constant from '@/store/constant.js';
import DetailDescriptionDialog from '@/components/dialog/DetailDescriptionDialog.vue';

export default {
  name: 'Leaderboard',
  components: {
    DetailDescriptionDialog
  },
  computed: {
    moreString() {
      return Constant.STRING[this.$store.state.language].MORE;
    },
    selectString() {
      return Constant.STRING[this.$store.state.language].SELECT;
    },
    klueLeaderboardTitleString() {
      return Constant.STRING[this.$store.state.language].KLUE_LEADERBOARD_TITLE;
    },
    klueLeaderboardDescString() {
      return Constant.STRING[this.$store.state.language].KLUE_LEADERBOARD_DESC;
    },
    leaderboardSize() {
      return Constant.TYPE.LEADERBOARD_SIZE;
    },
    defaultHeader() {
      return [
        {
          text: '#',
          value: 'ranking',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Team',
          value: 'team',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Model',
          value: 'model',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Description',
          value: 'description',
          align: 'center',
          sortable: false,
        },
        {
          text: '',
          value: 'downloadURL',
          align: 'center',
          sortable: false,
        },
      ]
    },
    defaultTopHeader() {
      return ['#', 'Team', 'Model', 'Description', ''];
    }
  },
  mounted() {
    this.loading = true;
    this.$store
      .dispatch('fetchTasks')
      .then(() => {
        this.multipleTask = this.$store.state.tasks.find(el => el.is_multiple);
        this.fetchAllRecords();
      })
    
  },
  methods: {
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    superString(string) {
      if(string.includes('micro')) return 'mic';
      return string[0].toUpperCase();
    },
    showPopup(value) {
      this.popupContent = value;
      this.dialog = true;
    },
    closePopup() {
      this.dialog = false;
    },
    changeTab(size) {
      this.tab = size;
      this.fetchAllRecords();
    },
    emphasizeTopValue() {
      this.headers.filter(el => el.value.includes('\\')).forEach(el => {
        let key = el.value;
        let max = 0, maxIdx=-1;
        this.records.forEach((el, idx) => {
          if(el[key] && max < el[key].value) {
            maxIdx = idx;
            max = el[key].value;
          }
        });
        if(maxIdx !== -1) this.records[maxIdx][key].top = true;
      })
    },
    sortRecord(metric, isAscending) {
      this.sorting = metric;
      this.ascending = isAscending;

      let sign = isAscending ? 1 : -1;
      this.records.sort((a, b) => {
        if(a[metric].value > b[metric].value) return sign;
        else if(a[metric].value < b[metric].value) return -sign;
        else return 0;
      });

      let idx = this.headers.findIndex(el => el.value === metric);
      this.$nextTick(() => {
        document.querySelectorAll('.data-table tr td:first-child').forEach(el => {
          el.style.backgroundColor = null;
          el.querySelector('.ranking-left').style.backgroundColor = null;
          
          let rank = parseInt(el.innerText);
          if(rank <= 10) {
            switch(rank) {
              case 1:
                el.style.backgroundColor = 'rgba(232, 70, 96, 0.1)';
                el.querySelector('.ranking-left').style.backgroundColor = '#E84660';
                break;
              case 2: case 3:
                el.style.backgroundColor = 'rgba(205, 164, 52, 0.1)';
                el.querySelector('.ranking-left').style.backgroundColor = '#CDA434';
                break;
              case 4: case 5: case 6:
                el.style.backgroundColor = 'rgba(131, 130, 128, 0.1)';
                el.querySelector('.ranking-left').style.backgroundColor = '#838280';
                break;
              default:
                el.style.backgroundColor = 'rgba(142, 91, 61, 0.1)';
                el.querySelector('.ranking-left').style.backgroundColor = '#8E5B3D';
                break;
            }
          }
        });


        document.querySelectorAll('.data-table td:nth-of-type(n+2)').forEach(el => {
          el.style.backgroundColor = 'white';
        });
        document.querySelectorAll(`.data-table td:nth-of-type(${idx+1})`).forEach(el => {
          el.style.backgroundColor = '#F9D1CB';
        });
        document.querySelectorAll('.data-table tr:nth-of-type(2) > th').forEach(el => {
          el.style.backgroundColor = 'white';
        });
        document.querySelectorAll(`.data-table tr:nth-of-type(2) > th:nth-of-type(${idx+1})`).forEach(el => {
          el.style.backgroundColor = '#F9D1CB';
        });
      })
      
    },
    parseResultsInRecord(row) {
      let result = row.result;
      Object.keys(result).forEach(key => {
        try {
          let inner_result = result[key];
          Object.keys(inner_result).forEach(inner_key => {
            row[key + '\\' + inner_key] = {top: false, value: inner_result[inner_key]};
          });
        } catch (e) {
          row[key] = {top: false, value: result[key]};
        }
      });
      return row;
    },
    recursiveInit(obj) {
      Object.keys(obj).forEach(key => {
        if(typeof(obj[key]) === 'number') {
          obj[key] = 0.0;
        } else if(typeof(obj[key]) === 'object') {
          obj[key] = this.recursiveInit(obj[key]);
        }
      })
      return obj;
    },
    setHeaders(defaultKeys) {
      let inner_index = 4;
      Object.keys(defaultKeys).forEach((key, index) => {
        this.topHeaders.splice(index+4, 0, key.toUpperCase());
        try {
          let inner_result = defaultKeys[key];
          Object.keys(inner_result).forEach(inner_key => {
            this.headers.splice(inner_index++, 0, {
              text: key.toUpperCase() + '\\' + inner_key,
              value: `${key}\\${inner_key}`,
              align: 'center', 
              sortable: true,
            });
          });
        } catch (e) {
          this.headers.splice(index + 4, 0, {
            text: key,
            value: key,
            align: 'center', 
            sortable: true,
          });
        }
      })
    },
    fetchAllRecords() {
      this.records = [];
      this.headers = this.defaultHeader.slice();
      this.topHeaders = this.defaultTopHeader.slice();
      let competitionID = this.multipleTask.id;
      let mode = 'all';
      let size = this.tab.toLowerCase();

      this.$store
        .dispatch('fetchRecords', { competitionID, mode, size })
        .then(data => {
          if(data.length === 0) return;

          let defaultKeys = JSON.parse(data[0].result);
          for(let record of data) {
            defaultKeys = Object.assign(defaultKeys, JSON.parse(record.result));
          }
          defaultKeys = this.recursiveInit(defaultKeys);
          this.setHeaders(defaultKeys);

          data.forEach(item => {
            let inferences = item.submission.inferences;
            let inferenceTime = inferences && inferences.length !== 0 
              ? inferences.reduce((time, cur) => {
                if(cur.finished_at && cur.started_at) {
                  return time + (new Date(cur.finished_at) - new Date(cur.started_at));
                } else {
                  return time;
                }
              }, 0) : 0;

            let row = {
              team: item.team.members[0].user.detail.company,
              model: item.submission.name,
              result: item.result,
              description: item.submission.description,
              url: item.submission.url,
              license: item.submission.license,
              size: item.submission.size,
              inference: inferenceTime / 1000, 
              downloadURL: ''
            };

            // this.$store.dispatch('getDownloadURL', {
            //   competitionID, 
            //   submissionID: item.submission.id,
            //   isHost: true
            // })
            // .then(data => {
            //   row['downloadURL'] = data['url'];
            // });
            row.result = Object.assign({}, defaultKeys, JSON.parse(row.result));
            row = this.parseResultsInRecord(row);
            this.records.push(row);
          });
          this.sortRecord(this.headers.find(el => el.value.includes('\\')).value, false);
          this.emphasizeTopValue();
        })
        .catch(e => alert(e))
        .finally(() => (this.loading = false));
    },
  },
  data() {
    return {
      file_download: require('@/assets/images/icons/file_download.svg'),
      download: require('@/assets/images/icons/download.svg'),
      loading: false,
      dialog: false,
      popupContent: null,
      multipleTask: null,
      tab: 'ALL',
      sorting: '',
      ascending: true,
      topHeaders: [],
      headers: [],
      records: []
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';

sup {
  display: inline-block;
  font-size: 10px;
  transform: translate(-3px, 0) scale(0.8);
  font-style: italic;
}

.leaderboard-container {
  width: 1084px;
  margin: 0 auto;
  margin-top: 144px;
  margin-bottom: 72px;
}

.leaderboard-text-row {
  margin-bottom: 32px;
}

.leaderboard-button-row {
  margin-bottom: 32px;
}

.leaderboard-title {
  @extend .biggest-heavy;
  margin-bottom: 16px;
}

.leaderboard-desc {
  text-align: justify;
  @extend .body-light;
}

.top-score {
  color: #E84660;
  font-weight: 700;
}

.tab-button {
  background-color: white !important;
  border-radius: 5px;
  letter-spacing: normal !important;
  @extend .caption-heavy;
  color: #705C4F !important;
  text-transform: none !important;
  margin-right: 12px;
  border: 2px solid #705C4F;

  &-active {
    background-color: #705C4F !important;
    color: white !important;
  }
}

.data-table {
  width: 100%;
  &::v-deep tr {
    td {
      @extend .caption-light;
      font-size: 12px !important;
      padding: 0 7px !important;
      height: 72px !important;
      max-width: 60px;

      &:last-of-type a {
        margin-top: 5px;
        margin-right: 10px;
        display: inline-block;
        height: 16px;
      }
    }
  }

  &::v-deep tr td:first-child {
    padding: 0 !important;
    min-width: 72px;
    width: 72px;
    .ranking {
      display: flex;
      align-items: center;
      height: 100%;

      &-left {
        width: 8px;
        height: 100%;
      }
      &-content {
        display: flex;
        align-items: center;
        flex-grow: 1;
        height: 100%;
        justify-content: center;
      }
    }
  }

  table > tr {
    &:first-of-type {
      background-color: #f7f2f0;
      th {
        @extend .caption-heavy;
      }
    }
    th {
      height: 52px;
      padding: 0;
      color: #515151 !important;
      @extend .small-heavy;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);

      .header-inner {
        display: flex;
        align-items: center;
        justify-content: center;

        .sort {
          display: flex;
          flex-direction: column;
          margin-left: 5px;

          img {
            margin: 1px;
            cursor: pointer;
          }

          &-active {
            filter: invert(45%) sepia(66%) saturate(6036%) hue-rotate(330deg) brightness(102%) contrast(82%);
          }
        }
      }
      
    }
  }

  &::v-deep .v-data-footer {
    @extend .body-light;
    .v-select__selection {
      @extend .body-light;
    }
  }

  .final {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      @extend .body-light;
      margin-right: 13px;

    }
  }
}
</style>