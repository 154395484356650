<template>
  <div class="organization-card">
    <div class="organization-image-row">
      <img :src="require(`@/assets/organization/${organization.name}.png`)"/>
    </div>
    <div class="organization-text-row">
      <div class="organization-text-left">
        <div class="organization-name">{{organization.text}}</div>
        <div class="organization-url">
          <a :href="organization.url" target="_blank">{{organization.url}}</a>
        </div>
      </div>
      <div class="organization-text-right">
        <span v-if="organization.rank" class="rank" :class="organization.rank.toLowerCase()">{{organization.rank}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'organizationMember',
  props: {
    organization: Object
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';
.organization-card {
  margin: 30px 20px;
  width: 300px;

  .organization-image-row {
    border-radius: 5px;
    border: 1px solid #DEDFE0;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    img {
      max-height: 45px;
    }
  }

  .organization-text-row {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    
    .organization-name {
      @extend .body-heavy;
    }

    .organization-url {
      @extend .caption-light;
    }

    .organization-text-right {
      display: flex;
      align-items: center;
    }
  }
}

.rank {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 40px;
  border-radius: 5px;
  letter-spacing: normal !important;
  @extend .body-heavy;
  color: #ffffff;
  text-transform: none !important;
}
</style>