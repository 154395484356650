<template>
  <v-card class="usereditor-card elevation-5 rounded-xl">
    <div class="usereditor-wrapper" v-if="user.detail">
      <v-row class="ml-0 mr-0 close-btn">
        <v-col class="pa-0"></v-col>
        <v-col class="pa-0 ml-auto" cols="auto">
          <v-btn icon @click="userEditorDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div class="usereditor-title">{{ userEditorTitleString }}</div>
      <v-row class="usereditor-img" align="center" justify="center">
        <v-col cols="auto">
          <v-img
            :src="imageSrc"
            class="rounded-circle"
            max-width="120"
            max-height="120"
            height="120"
            width="120"
          />
          <v-btn
            fab
            x-small
            class="camera-btn"
            color="#B0B0B0"
            icon
            depressed
            :loading="isSelecting"
            @click="onButtonClick"
          >
            <Icon
              name="signup_camera"
              size="large"
              style="margin-top: 1px"
            ></Icon>
          </v-btn>
          <input
            ref="uploader"
            class="d-none"
            type="file"
            accept="image/*"
            @change="onFileChanged"
          />
        </v-col>
      </v-row>
      <v-form>
        <v-text-field
          id="password"
          name="password"
          v-model="password"
          :placeholder="inputPasswordString"
          type="password"
          outlined
          height="50"
          hide-details
          class="input-field"
        >
          <template v-slot:prepend-inner>
            <Icon name="login_pw" size="small"></Icon>
          </template>
        </v-text-field>

        <!-- <v-text-field
          id="group"
          name="group"
          v-model="group"
          placeholder="조를 입력해주세요"
          type="text"
          outlined
          height="50"
          hide-details
          class="input-field"
        >
          <template v-slot:prepend-inner>
            <Icon name="modify_group" size="large"></Icon>
          </template>
        </v-text-field> -->

        <v-text-field
          id="nickname"
          name="nickname"
          v-model="nickname"
          :placeholder="inputNicknameString"
          type="text"
          outlined
          height="50"
          hide-details
          class="input-field"
          :rules="[requiredRule]"
          required
        >
          <template v-slot:prepend-inner>
            <Icon name="signup_name" size="large"></Icon>
          </template>
        </v-text-field>

        <v-text-field
          id="company"
          name="company"
          v-model="company"
          :placeholder="inputCompanyString + '(Optional)'"
          type="text"
          outlined
          height="50"
          hide-details
          class="input-field"
          prepend-inner-icon="mdi-clipboard-account"
        >
          <template v-slot:prepend-inner>
            <Icon name="signup_assign" size="large"></Icon>
          </template>
        </v-text-field>

        <v-text-field
          id="sns"
          name="sns"
          v-model="sns"
          :placeholder="inputSnsString + '(Optional)'"
          type="text"
          outlined
          height="50"
          hide-details
          class="input-field"
          prepend-inner-icon="mdi-share-variant"
        >
          <template v-slot:prepend-inner>
            <Icon name="signup_sns" size="small"></Icon>
          </template>
        </v-text-field>

        <v-row
          @click.prevent.stop="() => (termDialog = true)"
          class="term-policy"
          no-gutters
        >
          <v-col cols="auto">
            <v-icon middle color="#E84660" v-if="term">
              mdi-checkbox-marked
            </v-icon>
            <v-icon middle color="#E84660" v-else>
              mdi-checkbox-blank-outline
            </v-icon>
          </v-col>
          <v-col cols="auto" class="ml-3" style="margin-top:1px">
            {{ termAgreeString }}
          </v-col>
        </v-row>

        <v-row
          @click.prevent.stop="() => (policyDialog = true)"
          class="term-policy pt-2"
          no-gutters
        >
          <v-col cols="auto">
            <v-icon middle color="#E84660" v-if="policy">
              mdi-checkbox-marked
            </v-icon>
            <v-icon middle color="#E84660" v-else>
              mdi-checkbox-blank-outline
            </v-icon>
          </v-col>
          <v-col cols="auto" class="ml-3" style="margin-top:1px">
            {{ policyAgreeString }}
          </v-col>
        </v-row>

        <Term
          :dialog="termDialog"
          :content="termText"
          @close:term="updateTerm($event)"
        ></Term>

        <Policy
          :dialog="policyDialog"
          :content="policyText"
          @close:policy="updatePolicy($event)"
        ></Policy>
      </v-form>
      <v-btn class="usereditor-button" @click="editUser">{{
        userEditorString
      }}</v-btn>
    </div>
  </v-card>
</template>

<script>
import Constant from '@/store/constant.js';
import Icon from '@/components/Icon';
import Term from '@/components/dialog/TermDialog';
import Policy from '@/components/dialog/PolicyDialog';

export default {
  props: {
    user: Object,
  },

  components: {
    Icon,
    Term,
    Policy,
  },

  mounted() {
    this.nickname = this.user.detail.nickname;
    this.company = this.user.detail.company;
    this.term = this.user.detail.term_event.agree ? true : false;
    this.policy = this.user.detail.policy_event.agree ? true : false;
    this.sns = this.user.detail.sns;
    this.username = this.user.username;
    this.fetchTerm();
    this.fetchPolicy();
  },

  computed: {
    userEditorTitleString() {
      return Constant.STRING[this.$store.state.language].USEREDIT_TITLE;
    },
    userEditorString() {
      return Constant.STRING[this.$store.state.language].USEREDIT;
    },
    nicknameString() {
      return Constant.STRING[this.$store.state.language].NICKNAME;
    },
    inputPasswordString() {
      return Constant.STRING[this.$store.state.language].INPUT_PASSWORD;
    },
    inputNicknameString() {
      return Constant.STRING[this.$store.state.language].INPUT_NICKNAME;
    },
    inputCompanyString() {
      return Constant.STRING[this.$store.state.language].INPUT_COMPANY;
    },
    inputSnsString() {
      return Constant.STRING[this.$store.state.language].INPUT_SNS;
    },
    formatIncorrectString() {
      return Constant.STRING[this.$store.state.language].FORMAT_INCORRECT;
    },
    fieldEmptyString() {
      return Constant.STRING[this.$store.state.language].FIELD_EMPTY;
    },
    duplicateNicknameString() {
      return Constant.STRING[this.$store.state.language].DUPLICATE_NICKNAME;
    },
    usereditFailString() {
      return Constant.STRING[this.$store.state.language].USEREDIT_FAIL;
    },
    termAgreeString() {
      return Constant.STRING[this.$store.state.language].TERM_AGREE;
    },
    policyAgreeString() {
      return Constant.STRING[this.$store.state.language].POLICY_AGREE;
    },
    imageSrc() {
      if (this.image) {
        const imageURL = URL.createObjectURL(this.image);
        return imageURL;
      } else if (this.user.detail.image_url) {
        return this.user.detail.image_url;
      } else {
        return require('@/assets/' + this.blankImageName);
      }
    },
    userEditorDialog: {
      get: function() {
        return this.$store.state.userEditorDialog;
      },
      set: function(newValue) {
        this.$store.state.userEditorDialog = newValue;
      },
    },
  },

  methods: {
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false;
        },
        { once: true },
      );

      this.$refs.uploader.click();
    },

    onFileChanged(e) {
      this.image = e.target.files[0];
    },

    closeDialog() {
      this.termDialog = false;
      this.policyDialog = false;
    },

    updateTerm(value) {
      if (value !== null) {
        this.term = value;
      }
      this.closeDialog();
    },

    updatePolicy(value) {
      if (value !== null) {
        this.policy = value;
      }
      this.closeDialog();
    },

    fetchTerm() {
      this.$store.dispatch('fetchTerm').then(data => {
        this.termText = data.text;
      });
    },

    fetchPolicy() {
      this.$store.dispatch('fetchPolicy').then(data => {
        this.policyText = data.text;
      });
    },

    validateForm() {
      let forms = [
        { field: this.nicknameString, value: this.nickname, rules: [] },
      ];

      for (let i = 0; i < forms.length; i++) {
        let form = forms[i];

        for (let j = 0; j < form.rules.length; j++) {
          let rule = form.rules[j];
          if (!rule(form.value)) {
            alert(form.field + this.formatIncorrectString);
            return false;
          }
        }
        if (!form.value) {
          console.log('222');
          alert(form.field + this.fieldEmptyString);
          return false;
        }
      }
      return true;
    },

    editUser() {
      if (this.validateForm()) {
        let image = this.image;
        let username = this.username;
        let password = this.password;
        let nickname = this.nickname;
        let company = this.company;
        let sns = this.sns;
        let bio = this.bio;
        let term = this.term;
        let policy = this.policy;
        let userID = this.user.id;

        let checkUnique = this.$store.dispatch('checkUnique', {
          username,
          nickname,
        });
        checkUnique.then(data => {
          if (this.user.detail.nickname !== this.nickname && !data.nickname) {
            console.log('3333');
            alert(this.duplicateNicknameString);
          } else {
            this.$store
              .dispatch('editUser', {
                image,
                password,
                nickname,
                company,
                sns,
                bio,
                term,
                policy,
                userID,
              })
              .then(() => this.redirect())
              .catch(e => {
                console.log('editUser Failed', e);
                alert(this.usereditFailString);
              });
          }
        });
      } else {
        console.log('Validation Failed');
      }
    },

    redirect() {
      this.userEditorDialog = false;
      if (this.$route.query.redirectTo) {
        this.$router.push(`${this.$route.query.redirectTo}`).catch(() => {});
      } else {
        this.$router.push('/').catch(() => {});
      }
    },

    requiredRule(value) {
      return !!value || 'This field is required';
    },

    alphaNumeric(value) {
      return (
        !!value.match(/^[0-9a-zA-Z]+$/) || 'This field must be alphanumeric'
      );
    },

    emailValidation(value) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(value).toLowerCase());
    },
  },

  data() {
    return {
      image: null,
      password: '',
      nickname: '',
      company: '',
      sns: '',
      bio: '',
      term: false,
      policy: false,
      termText: '',
      policyText: '',
      termDialog: false,
      policyDialog: false,
      blankImageName: 'signup_user_photo.svg',
      isSelecting: false,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';
.usereditor-card {
  width: 440px;

  background: rgba(255, 255, 255, 0.95);
  border-radius: 10px !important;
}
.close-btn {
  margin-bottom: 2px;
}
.camera-btn {
  /* margin-top: -40px;
  padding-left: 80px; */
  margin-top: -55px;
  margin-left: 80px;
  /* max-height:28px;
  max-width:28px; */
  background: white;
  border: 1px solid #b0b0b0;
}
.usereditor-title {
  @extend .title-heavy;
  color: #3f3f3f !important;
  margin-bottom: 24px;
}
.usereditor-wrapper {
  padding: 30px;
}
.input-field {
  margin-bottom: 15px !important;
  width: 380px;
  @extend .subheader-light;
}
.text-area {
  margin-bottom: 15px !important;
  width: 380px;
  @extend .subheader-light;
}
.usereditor-button {
  width: 100%;
  height: 60px !important;
  background: linear-gradient(
    271.1deg,
    #e84660 0.46%,
    rgba(232, 70, 96, 0.85) 100%
  );
  border-radius: 5px;

  @extend .subheader-heavy;
  color: #ffffff !important;
  margin-top: 15px;
}

.v-text-field {
  &::v-deep .v-input__slot {
    padding: 0 !important;
  }

  &::v-deep .v-input__prepend-inner {
    padding: 0 !important;
    margin: 21px 12px !important;
  }

  &::v-deep .no-icon {
    margin: 5px;
  }

  &--outlined {
    &::v-deep fieldset {
      border: 1px solid #a3a3a3;
      box-sizing: border-box;
      border-radius: 5px;
      margin-bottom: 0px;
    }
  }
}

.v-input--checkbox {
  margin-top: 10px;
  padding: 0;
}

.term-policy {
  cursor: pointer;
  @extend .body-light;
}
</style>
