<template>
  <div>
    <v-dialog :value="dialog" width="440">
      <v-card class="dialog">
        <div class="dialog-top">
          {{ notificationString }}
        </div>
        <img
          :src="
            result.type === 'Success'
              ? require('@/assets/checked.svg')
              : require('@/assets/failure.svg')
          "
          width="95"
          height="95"
        />
        <div class="dialog-title">Submission {{ result.type }}</div>
        <div class="dialog-content">{{ result.detail }}</div>
        <v-btn
          width="300"
          height="50"
          outlined
          color="#705C4F"
          class="dialog-btn"
          @click="buttonClicked"
          >{{ confirmString }}</v-btn
        >
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Constant from '@/store/constant.js';
export default {
  name: 'SubmissionDialog',
  props: {
    dialog: Boolean,
    result: Object,
  },
  computed: {
    notificationString() {
      return Constant.STRING[this.$store.state.language].NOTIFICATION;
    },
    confirmString() {
      return Constant.STRING[this.$store.state.language].CONFIRM;
    },
  },
  methods: {
    buttonClicked() {
      this.$emit('close');
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/assets/sass/global.scss';
.dialog {
  text-align: center;
}

.dialog-top {
  @extend .subheader-heavy;
  color: #3f3f3f;
  padding: 30px;
  text-align: left;
}

.dialog-title {
  text-transform: capitalize;
  margin-top: 10px;
  @extend .body-heavy;
}

.dialog-content {
  margin-top: 6px;
  margin-bottom: 25px;
  @extend .caption-light;
}

.dialog-btn {
  margin-bottom: 25px;
  border: 2px solid #705c4f;
}
</style>
