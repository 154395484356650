import axios from 'axios';

const mutations = {
  setSignDialog(state, value) {
    state.signDialog = value;
  },

  setSignDialogActiveTab(state, { activeTab }) {
    state.signDialogActiveTab = activeTab;
  },

  setSignUpMethod(state, { method }) {
    state.signupMethod = method;
  },

  setOAuthProfile(state, { token, email, imageURL }) {
    state.oauthProfile = { token, email, imageURL };
  },

  clearOAuthProfile(state) {
    state.oauthProfile = null;
  },

  signIn(state, { data }) {
    state.token = data['token'];
    state.username = data['username'];
    state.userImageURL = data['user_image_url'];
    state.userID = data['user_id'];
    state.nickname = data['nickname'];
    state.userType = data['type'];

    axios.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
    localStorage.token = state.token;
    localStorage.username = state.username;
    localStorage.userImageURL = state.userImageURL;
    localStorage.userID = state.userID;
    localStorage.nickname = state.nickname;
    localStorage.userType = state.userType;
  },

  clearAuth(state) {
    state.token = null;
    state.username = null;
    state.userImageURL = null;
    state.userID = null;
    state.nickname = null;
    state.userType = null;
    state.user = null;

    axios.defaults.headers.common['Authorization'] = null;
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('userImageURL');
    localStorage.removeItem('userID');
    localStorage.removeItem('nickname');
    localStorage.removeItem('userType');
  },

  setLanguage(state, { language }) {
    state.language = language;
    localStorage.language = language;
  },

  setOrganizingCompetition(state, { data }) {
    state.organizingCompetition = data;
  },

  updateOrganizingCompetition(state, { competitionState }) {
    if (state.organizingCompetition) {
      state.organizingCompetition.state = competitionState;
    }
  },

  setCompetitions(state, { data }) {
    state.competitions = data;
  },

  setCompetitionOwns(state, { data }) {
    state.competitionOwns = data;
  },

  setTasks(state, { data }) {
    state.tasks = data;
  },

  setCompetitionDetail(state, { id, data }) {
    state.competitionDetail[id] = data;
  },

  setTaskDetail(state, { id, data }) {
    state.taskDetail[id] = data;
  },

  setUserDetail(state, { data }) {
    state.user = data;
  },

  // Discussion
  setDiscussions(state, { data }) {
    let discussions = data;
    Date.prototype.format = function() {
      let yy = this.getFullYear();
      let mm = this.getMonth() + 1;
      let dd = this.getDate();
      return [yy, (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join(
        '. ',
      );
    };

    for (let i = 0; i < discussions.length; i++) {
      let date;
      if (
        discussions[i]['started_at'] == null ||
        discussions[i]['end_at'] == null
      ) {
        date = 'Always';
      } else {
        let startDate = new Date(discussions[i]['started_at']);
        let endDate = new Date(discussions[i]['end_at']);
        date = startDate.format() + ' - ' + endDate.format().slice(6);
      }
      discussions[i]['date'] = date;

      let comments = [];
      for (let j = 0; j < discussions[i].posts.length; j++) {
        comments = comments.concat(discussions[i].posts[j].comments);
      }
      discussions[i]['comments'] = comments;

      discussions[i]['imgSrc'] = discussions[i]['image_url'];
    }
    state.discussions = discussions;
  },
};

export default mutations;