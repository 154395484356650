export default {
  competitions: [],
  competitionOwns: [],
  tasks: [],
  competitionDetail: {},
  taskDetail: {},
  competitionStats: {},
  token: null,
  user: null,
  userImageURL: null,
  userID: null,
  userType: null,
  nickname: null,
  submissions: [],
  menubar: false,
  signinDialog: false,
  signupDialog: false,
  signDialog: false,
  signDialogActiveTab: 0,
  signupMethod: null, // google, password
  oauthProfile: null, // The user profile got from OAuth Providers such as Google
  userEditorDialog: false,
  discussions: [],
  listParams: {},
  organizingCompetition: null,
  language: 'KO',
};