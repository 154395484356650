<template>
  <v-dialog persistent v-model="dialog" width="440">
    <v-card class="dialog">
      <div class="dialog-top">
        {{ title }}
      </div>
      <v-progress-linear
        :value="progress"
        height="45"
        rounded
        striped
        color="#E84660"
      >
        <strong>{{ Math.ceil(progress) }}%</strong>
      </v-progress-linear>
      <div class="dialog-title">
        {{ message }}
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    progress: Number,
    title: String,
    message: String,
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';
.dialog {
  text-align: center;
  padding: 30px;
}

.dialog-top {
  @extend .subheader-heavy;
  color: #3f3f3f;
  margin-bottom: 20px;
  text-align: left;
}

.dialog-title {
  text-transform: capitalize;
  margin-top: 10px;
  @extend .body-heavy;
}

.dialog-content {
  margin-top: 6px;
  margin-bottom: 25px;
  @extend .caption-light;
}

.dialog-btn {
  margin-bottom: 25px;
  border: 2px solid #705c4f;
}
</style>
