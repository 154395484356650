<template>
  <v-app id="inspire">
    <AppBar></AppBar>

    <section id="container">
      <img
        v-if="$route.name === 'Home'"
        :src="require('@/assets/home.svg')"
        class="home-background"
      />
      <router-view></router-view>
    </section>

    <Footer></Footer>
  </v-app>
</template>

<script>
import AppBar from '@/components/AppBar';
import Footer from '@/components/Footer';

export default {
  name: "App",

  props: {
    source: String,
  },

  components: {
    AppBar,
    Footer,
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';

#container {
  position: relative;
  display: flex;
  justify-content: end;
  flex-direction: row;
  align-items: center;
  min-height: calc(100vh - 80px);
}

.home-background {
  position: absolute;
  width: auto;
  height: 68vh;
  right: 0;
  top: 70px;
  flex-grow: 0;
}
</style>