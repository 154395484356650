var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-inner",class:_vm.type === 'Discussion' ? 'page-padding' : '',attrs:{"name":"discussion"}},[_c('div',{staticClass:"back-to-list",on:{"click":_vm.routeBack}},[_c('v-icon',{attrs:{"color":"rgba(0, 0, 0, 0.87)"}},[_vm._v("mdi-chevron-left")]),_vm._v("Back To List ")],1),(_vm.isAdmin)?_c('div',[_c('v-card-title',{staticClass:"pa-0"},[_c('h5',[_vm._v("Post Type")])]),_c('v-select',{staticStyle:{"width":"200px"},attrs:{"items":[_vm.qaString, _vm.noticeString]},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1):_vm._e(),_c('v-card-title',{staticClass:"pa-0"},[_c('h5',[_vm._v("Title")])]),_c('v-card-text',{staticClass:"pa-0"},[_vm._v("Be specific and imagine you’re asking a question to another person")]),((_vm.editMode && _vm.title) || !_vm.editMode)?_c('div',{staticClass:"title"},[_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":"","autofocus":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }$event.preventDefault();return _vm.$refs.editor.editor.view.dom.focus()}},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1):_vm._e(),_c('v-card-title',{staticClass:"pa-0"},[_c('h5',[_vm._v("Body")])]),_c('v-card-text',{staticClass:"pa-0"},[_vm._v("Include all the information someone would need to answer your question")]),((_vm.editMode && _vm.content) || !_vm.editMode)?_c('div',{on:{"click":function($event){return _vm.$refs.editor.editor.view.dom.focus()}}},[_c('editor',{ref:"editor",attrs:{"initial-content":_vm.content,"active-buttons":[
        'bold',
        'italic',
        'strike',
        'underline',
        'code',
        'paragraph',
        'h1',
        'h2',
        'h3',
        'bullet_list',
        'ordered_list',
        'blockquote',
        'code_block',
        'horizontal_rule',
        'undo',
        'redo',
        'image' ],"submitButton":true},on:{"submit":_vm.submit}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }