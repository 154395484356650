var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mysubmission-container"},[_c('v-data-table',{staticClass:"data-table",attrs:{"headers":_vm.headers,"items":_vm.submissions,"loading":_vm.loading,"items-per-page":5},on:{"click:row":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.toLogView($event)}},scopedSlots:_vm._u([{key:"item.local_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.tag(_vm.task.name) + ' - ' + item.local_id)+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,'YYYY-MM-DD HH:mm'))+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.showPopup('Description', item.description, $event)}}},[_vm._v(_vm._s(_vm.moreString))])]}},{key:"item.hyperparameters",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.showPopup('Hyperparameters', item.hyperparameters, $event)}}},[_vm._v(_vm._s(_vm.moreString))])]}},{key:"item.phase",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{class:'phase-' + item.phase.toLowerCase(),attrs:{"width":"96","height":"32"}},[_vm._v(_vm._s(item.phase))])]}}],null,true)}),_c('simple-dialog',{ref:"dialog",attrs:{"dialog":_vm.dialog,"title":_vm.popupTitle,"content":_vm.popupContent},on:{"close:simple":_vm.closePopup}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }