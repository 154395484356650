<template>
  <div class="comment-container" v-if="comment.user"> 
    <div class="comments-left">
      <v-menu
        :close-on-content-click="false"
        offset-y
        nudge-bottom="30"
        nudge-left="150"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-img
            v-bind="attrs"
            v-on="on"
            :src="comment.user.detail.image_url"
            class="hidden-xs-only rounded-circle comments-user"
            :class="['circle', comment.user.detail.type.toLowerCase()]"
            max-width="48"
            max-height="48"
            width="48"
            height="48"
          />
        </template>
        <UserCard
          :userID="comment.user.id.toString()"
          :stateButton="false"
        ></UserCard>
      </v-menu>
      <div class="vote" @click="toggleVote">
        <v-icon small color="pink" v-if="voted">mdi-heart</v-icon>
        <v-icon small color="pink" v-else>mdi-heart-outline</v-icon>
        {{ numVotes }}
      </div>
    </div>
    <div class="comments-right">
      <v-card class="comment-card" outlined elevation="0" v-if="editMode">
        <PostEditor
          ref="postEditor"
          :initial-content="comment.body"
          :isComment="true"
          :active-buttons="[
            'bold',
            'italic',
            'strike',
            'underline',
            'code',
            'paragraph',
            'h1',
            'h2',
            'h3',
            'bullet_list',
            'ordered_list',
            'blockquote',
            'code_block',
            'horizontal_rule',
            'undo',
            'redo',
            'image',
          ]"
          :submitButton="true"
          :cancelButton="true"
          @submit="editComment"
          @cancel="deactivateEditMode"
        />
      </v-card>
      <v-card class="comment-card" outlined elevation="0" v-else>
        <div class="comment-title-row">
          <div class="comment-title-row-left">
            <div class="author-name">
              Commented by {{ comment.user.detail.nickname }}
            </div>
            <div class="divider"></div>
            <div class="comment-time">
              {{ comment.created_at | dateFormat }}
            </div>
          </div>
          <div class="comment-title-row-right">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#C4C4C4"
                  fab
                  x-small
                  text
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(action, index) in actions.filter(
                    action => action.active,
                  )"
                  :key="index"
                  @click="action.function"
                >
                  <v-list-item-title>{{ action.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <editor-content class="editor__content" :editor="editor" />
        <div class="editor-divider"></div>
        <div
          class="reply-field"
          v-for="(reply, index) in replies"
          :key="index"
        >
          <div class="reply-title-row">
            <div class="reply-author">{{ reply.user.detail.nickname }}</div>
            <div class="reply-time">{{ reply.created_at | dateFormat }}</div>
            <div class="reply-delete">
              <div class="divider"></div>
              <div v-if="replyDeleteActive(reply)" @click="deleteReply(reply)">
                {{ deleteString }}
              </div>
            </div>
          </div>
          <div class="reply-content">
            {{ reply.text }}
          </div>
          <div class="reply-divider"></div>
        </div>
        <div class="reply-add">
          <v-textarea
            v-if="replyActive"
            class="reply-input"
            outlined
            hide-details
            label="add a reply"
            v-model="reply"
          >
          </v-textarea>
          <v-btn class="reply-btn" depressed @click="commitReply"
            >{{addReplyString}}</v-btn
          >
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from 'tiptap';
import Constant from '@/store/constant.js';
import UserCard from '@/components/card/UserCard.vue';
import TagSpan from '@/plugins/TagSpan';
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Image,
} from 'tiptap-extensions';
import PostEditor from '@/components/Editor';

export default {
  props: {
    comment: Object,
    isComment: Boolean,
  },

  components: {
    EditorContent,
    UserCard,
    PostEditor,
  },

  created() {
    this.editor.setContent(this.comment.body);
    this.replies = this.comment.replies;

    for (let i = 0; i < this.comment.votes.length; i++) {
      if (this.comment.votes[i].user == this.userID) {
        this.voted = true;
        break;
      }
    }
    this.numVotes = this.comment.votes.length;
  },

  computed: {
    confirmDeletionString() {
      return Constant.STRING[this.$store.state.language].CONFIRM_DELETION;
    },
    addReplyString() {
      return Constant.STRING[this.$store.state.language].ADD_REPLY;
    },
    deleteString() {
      return Constant.STRING[this.$store.state.language].DELETE;
    },
    userID() {
      return this.$store.state.userID;
    },
  },

  methods: {
    commitReply() {
      if(!this.replyActive) {
        this.replyActive = true;
        return;
      }

      let text = this.reply;
      let postID = this.comment.id;
      let isComment = this.isComment;
      this.$store
        .dispatch('commitReply', { postID, text, isComment })
        .then(data => {
          this.replies = data;
          this.replyActive = false;
        })
        .catch(e => alert(e))
        .finally(() => (this.reply = ''));
    },

    toggleVote() {
      let postID = this.comment.id;
      let isComment = this.isComment;

      if (this.voted) {
        this.$store
          .dispatch('deleteVote', { postID, isComment })
          .then(() => {})
          .catch(e => alert(e));
        this.numVotes--;
      } else {
        this.$store
          .dispatch('commitVote', { postID, isComment })
          .then(() => {})
          .catch(e => alert(e));
        this.numVotes++;
      }
      this.voted = !this.voted;
    },

    activateEditMode() {
      this.editMode = true;
    },

    deactivateEditMode() {
      this.editMode = false;
    },

    editComment(body, fileURLs) {
      let payload = {
        body: body,
        fileURLs: fileURLs,
        commentID: this.comment.id,
      };
      return this.$store
        .dispatch('editComment', payload)
        .then(data => {
          this.comment = data;
          this.editor.setContent(this.comment.body);
          this.editMode = false;
        })
        .catch(e => alert(e));
    },

    deleteComment() {
      if (!confirm(this.confirmDeletionString)) {
        return;
      }

      let commentID = this.comment.id;
      this.$store
        .dispatch('deleteComment', { commentID })
        .then(() => {
          this.$emit('delete', commentID);
        })
        .catch(e => alert(e));
    },

    editActive() {
      return this.comment.user.id == this.$store.state.userID;
    },

    deleteActive() {
      return (
        this.$store.state.userType.toLowerCase() === 'admin' ||
        this.comment.user.id == this.$store.state.userID
      );
    },

    actionActive() {
      return this.actions.reduce((acc, current) => {
        return acc.active | current.active;
      });
    },

    replyDeleteActive(reply) {
      return (
        this.$store.state.userType.toLowerCase() === 'admin' ||
        reply.user.id == this.$store.state.userID
      );
    },

    deleteReply(reply) {
      if (!confirm(this.confirmDeletionString)) {
        return;
      }

      let replyID = reply.id;
      this.$store
        .dispatch('deleteReply', { replyID })
        .then(() => {
          this.replies = this.replies.filter(reply => reply.id != replyID);
        })
        .catch(e => alert(e));
    },
  },

  data() {
    return {
      voted: false,
      replies: [],
      reply: '',
      replyActive: false,
      actions: [
        {
          name: 'Edit',
          function: this.activateEditMode,
          active: this.editActive(),
        },
        {
          name: 'Delete',
          function: this.deleteComment,
          active: this.deleteActive(),
        },
      ],
      editor: new Editor({
        editable: false,
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Image(),
          new TagSpan(),
        ],
      }),
      query: null,
      suggestionRange: null,
      editMode: false,
      insertMention: () => {},
    };
  },

  filters: {
    dateFormat(string) {
      Date.prototype.format = function() {
        let yy = this.getFullYear();
        let mm = this.getMonth() + 1;
        let dd = this.getDate();
        let hour = this.getHours();
        let minute = this.getMinutes();
        return (
          [yy, (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('.') +
          ' ' +
          [(hour > 9 ? '' : '0') + hour, (minute > 9 ? '' : '0') + minute].join(
            ':',
          )
        );
      };
      let date = new Date(string);
      return date.format();
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/sass/editor/main.scss';
@import '@/assets/sass/global.scss';

.comment-container {
  display: flex;

  .comments-left {
    display: flex;
    flex-direction: column;
    margin-right: 24px;
    align-items: center;

    .comments-user {
      margin-bottom: 16px;
    }
  }

  .comments-right {
    flex: 1;
  }
}

.comment {
  border: thin solid rgba(0, 0, 0, 0.1) !important;
}

.reply-add {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;

  .reply-input {
    margin-right: 20px;
    @extend .body-light;
    color: rgba(0, 0, 0, 0.5) !important;
  }

  .reply-btn {
    background: linear-gradient(
      271.1deg,
      #e84660 0.46%,
      rgba(232, 70, 96, 0.85) 100%
    );
    border-radius: 5px;
    text-transform: none;
    letter-spacing: normal;

    @extend .body-heavy;
    color: #ffffff;
  }
}


.reply-field {
  @extend .caption-light;

  .reply-title-row {
    display: flex;
    align-items: center;
    color: rgba(33, 33, 33, 0.5);

    .reply-author {
      @extend .caption-heavy;
      margin-right: 16px;
    }
    .reply-time {
      @extend .caption-heavy;
      
    }
    .reply-delete {
      display: flex;
      align-items: center;
      @extend .caption-heavy;
      cursor: pointer;
    }
  }
  .reply-divider {
    border-top: 1px solid rgba(85, 85, 85, 0.35);
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .reply-content {
    @extend .body-light;
    color: rgba(0, 0, 0, 0.5);
  }
  
  
}
.vote {
  cursor: pointer;
  text-align: center;
  display: inline-block;
  width: 56px;
  height: 30px;
  border: 2px solid #e84660;
  box-sizing: border-box;
  border-radius: 5px;
  @extend .body-heavy;
  color: #e84660;
}
.comment-card {
  padding-top: 25px;
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 25px;

  .comment-title-row {
    display: flex;
    justify-content: space-between;

    &-left {
      display: flex;
      align-items: center; 
      .author-name {
        @extend .caption-heavy;
        color: #212121;
      }
      .comment-time {
        @extend .caption-heavy;
        color: rgba(33, 33, 33, 0.25);
      }
    }
    
  }

}

.editor-divider {
  border-top: 1px solid #555555;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  margin-top: 30px;
  margin-bottom: 25px;
}

.divider {
  border-left: 2px solid #c4c4c4;
  height: 16px;
  margin-left: 8px;
  margin-right: 8px;
}
.circle {
  background-color: #ffffff;
}
</style>
