<template>
  <div class="submission-container">
    <label for="select">
      <v-file-input
        solo
        flat
        dense
        hide-details
        prepend-icon=''
        clear-icon=''
        class="select"
        id="select"
        v-model="sourceFile"
        :accept="['.gz']"
      ></v-file-input>
      <div class="file-submission-area">
        <div class="file-submission-icon">
          <img :src="fileImageSrc" width="67" height="67" />
        </div>
        <div class="file-submission-text">
          <div class="file-submission-title">{{submissionClickString}}</div>
          <div class="file-submission-sub">{{submissionConditionString}}</div>
        </div>
      </div>
    </label>
    <div class="file-submission-upload-row">
      <div class="file-submission-upload">
        <div class="uploaded-title">{{sourceFileString}}</div>
        <div class="uploaded-name">{{sourceFile ? sourceFile.name : ''}}</div>
        <img :src="clearImageSrc" width="18" height="18" style="cursor: pointer;" @click="clearFile" />
      </div>
      <hr />
    </div>
    <div class="file-submission">
      <div class="file-submission-left">
        <div class="file-submission-row">
          <div class="model-title-text">{{modelNameString}}</div>
          <div class="model-title-input">
            <v-text-field 
              hide-details 
              outlined 
              dense
              v-model="modelName"
              :placeholder="modelNameDescString"  
            ></v-text-field>
          </div>
        </div>
        <div class="file-submission-row">
          <div class="model-desc-text">{{modelSizeString}}<br />
            <div class="description">{{modelSizeDescString}}</div>
          </div>
          <div class="model-desc-input" style="display: flex; justify-content: space-between">
            <v-btn 
              width="122"
              height="40"
              depressed
              class="size-toggle-button"
              :class="getSelectedSizeTypeClass(modelSizeType.SMALL, modelSize)"
              @click="setSize(modelSizeType.SMALL)"
            >{{modelSizeType.SMALL}}</v-btn>
            <v-btn 
              width="122"
              height="40"
              depressed
              class="size-toggle-button"
              :class="getSelectedSizeTypeClass(modelSizeType.BASE, modelSize)"
              @click="setSize(modelSizeType.BASE)"
            >{{modelSizeType.BASE}}</v-btn>
            <v-btn 
              width="122"
              height="40"
              depressed
              class="size-toggle-button"
              :class="getSelectedSizeTypeClass(modelSizeType.LARGE, modelSize)"
              @click="setSize(modelSizeType.LARGE)"
            >{{modelSizeType.LARGE}}</v-btn>
          </div>
        </div>
        <div class="file-submission-row">
          <div class="model-title-text">{{modelUrlString}} 
            <span class="optional">({{optionalString}})</span>
          </div>
          <div class="model-title-input">
            <v-text-field 
              hide-details 
              outlined 
              dense
              v-model="modelUrl"
              :placeholder="modelUrlDescString"  
            ></v-text-field>
          </div>
        </div>
        <div class="file-submission-row">
          <div class="parameter-text">
            {{hyperparameterString}}
            <span class="optional">({{optionalString}})</span>
          </div>
          <div class="parameter-input">
            <v-text-field 
              hide-details 
              outlined 
              dense 
              @input="bindItem(param)"
              v-model="param.input"
              :placeholder="paramDescString"  
            >
              <template v-slot:append>
                <v-tooltip bottom v-model="param.tooltip">
                  <template v-slot:activator="{}">
                    <img class="icon-copy" :src="copyImageSrc" width="16" height="16" @click="copyText(param)" />
                  </template>
                  <span>{{ copiedString }}</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </div>
          <v-data-table
            ref="hyperparameters"
            class="data-table"
            dense
            :headers="parameterHeaders"
            :items="param.elements"
            hide-default-footer
          >
            <template v-slot:[`item.change`]="{ item }">
              <v-btn
                v-if="item.change === 'delete'"
                icon
                @click="deleteItem(param, item)"
              >
                <v-icon>mdi-minus-box</v-icon>
              </v-btn>
              <v-btn
                v-else-if="item.change === 'add'"
                icon
                @click="addItem(param)"
              >
                <v-icon>mdi-plus-box</v-icon>
              </v-btn>
            </template>

            <template v-slot:[`item.key`]="{ item }">
              <div v-if="item.change === 'delete'" class="added-param">
                {{ item.key }}
              </div>
              <v-text-field
                v-else-if="item.change === 'add'"
                class="input-inner"
                solo
                flat
                dense
                hide-details
                placeholder="input"
                v-model="param.key"
              ></v-text-field>
            </template>
            <template v-slot:[`item.value`]="{ item }">
              <div v-if="item.change === 'delete'" class="added-param">
                {{ item.value }}
              </div>
              <v-text-field
                v-else-if="item.change === 'add'"
                class="input-inner"
                solo
                flat
                dense
                hide-details
                placeholder="input"
                v-model="param.value"
                @keydown.tab="addItem(param)"
              ></v-text-field>
            </template>
          </v-data-table>
        </div>
      </div>
      <div class="file-submission-right">
        <div class="file-submission-row">
          <div class="model-desc-text">{{modelLicenseString}}</div>
          <div class="model-desc-input" style="display: flex;">
            <v-btn 
              width="122" 
              height="40"
              depressed
              class="license-toggle-button"
              :class="getSelectedLicenseTypeClass(mitString, modelLicense)"
              @click="setLicense(mitString)"
            >{{mitString}}</v-btn>
            <v-btn 
              width="122" 
              height="40"
              depressed
              class="license-toggle-button"
              :class="getSelectedLicenseTypeClass(apacheString, modelLicense)"
              @click="setLicense(apacheString)"
            >{{apacheString}}</v-btn>
            <v-text-field 
              class="license-input"
              @click="setLicenseEtc"
              @change="setLicenseEtc"
              hide-details 
              outlined 
              dense
              v-model="modelLicenseEtc"
              :placeholder="modelLicenseDescString"  
            ></v-text-field>
          </div>
        </div>
        <div class="file-submission-row">
          <div class="model-desc-text">{{descriptionString}} 
            <span class="optional">({{optionalString}})</span>
          </div>
          <div class="model-desc-input">
            <v-textarea
              ref="description"
              outlined
              hide-details
              no-resize
              :placeholder="modelDescString"
              class="describe"
              :height="descHeight"
              v-model="description"
            ></v-textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="submission-notice-row">
      <div class="submission-notice" v-html="submissionNoticeString"></div>
    </div>
    <div class="button-row">
      <v-btn
        width="112"
        height="40"
        depressed
        class="button"
        @click="submit"
        >{{ submitString }}</v-btn>
    </div>
    <SubmissionDialog
      :dialog="submissionDialog"
      :result="submissionResult"
      v-on:close="closeDialog"
    ></SubmissionDialog>

    <ProgressBar
      :dialog="progressBarDialog"
      :progress="uploadPercent"
      :title="submissionUploadingTitleString"
      :message="submissionUploadingMessageString"
    ></ProgressBar>
  </div>
</template>

<script>
import Constant from '@/store/constant.js';
import SubmissionDialog from '@/components/dialog/SubmissionDialog';
import ProgressBar from '@/components/ProgressBar';
export default {
  name: 'NewSubmission',
  components: {
    SubmissionDialog,
    ProgressBar,
  },
  props: {
    id: String,
    task: Object,
  },
  computed: {
    submissionClickString() {
      return Constant.STRING[this.$store.state.language].KLUE_SUBMISSION_CLICK;
    },
    submissionConditionString() {
      return Constant.STRING[this.$store.state.language].KLUE_SUBMISSION_CONDITION;
    },
    sourceFileString() {
      return Constant.STRING[this.$store.state.language].UPLOADED_FILE;
    }, 
    modelNameString() {
      return Constant.STRING[this.$store.state.language].MODEL_NAME;
    }, 
    modelSizeString() {
      return Constant.STRING[this.$store.state.language].MODEL_SIZE;
    },
    modelSizeDescString() {
      return Constant.STRING[this.$store.state.language].MODEL_SIZE_DESC;
    },
    modelSizeType() {
      return Constant.TYPE.MODEL_SIZE;
    },
    modelUrlString() {
      return Constant.STRING[this.$store.state.language].MODEL_URL;
    }, 
    modelLicenseString() {
      return Constant.STRING[this.$store.state.language].MODEL_LICENSE;
    }, 
    modelNameDescString() {
      return Constant.STRING[this.$store.state.language].MODEL_NAME_DESC;
    }, 
    modelUrlDescString() {
      return Constant.STRING[this.$store.state.language].MODEL_URL_DESC;
    },
    mitString() {
      return Constant.STRING[this.$store.state.language].MIT;
    },
    apacheString() {
      return Constant.STRING[this.$store.state.language].APACHE;
    },
    modelLicenseDescString() {
      return Constant.STRING[this.$store.state.language].MODEL_LICENSE_DESC;
    }, 
    hyperparameterString() {
      return Constant.STRING[this.$store.state.language].HYPERPARAMETER;
    },
    optionalString() {
      return Constant.STRING[this.$store.state.language].OPTIONAL;
    }, 
    paramDescString() {
      return Constant.STRING[this.$store.state.language].MODEL_PARAM_DESC;
    },
    copiedString() {
      return Constant.STRING[this.$store.state.language].COPIED;
    },
    descriptionString() {
      return Constant.STRING[this.$store.state.language].DESCRIPTION;
    },
    modelDescString() {
      return Constant.STRING[this.$store.state.language].MODEL_DESC;
    },
    submitString() {
      return Constant.STRING[this.$store.state.language].SUBMIT;
    },
    submissionResultType() {
      return Constant.TYPE.SUBMISSION_RESULT;
    },
    fileDescriptionString() {
      return Constant.STRING[this.$store.state.language].FILE_DESCRIPTION;
    },
    serverSubmissionDescriptionString() {
      return Constant.STRING[this.$store.state.language]
        .SERVER_SUBMISSION_DESCRIPTION;
    },
    noSourceFileString() {
      return Constant.STRING[this.$store.state.language].NO_SOURCE_FILE;
    },
    descriptionTooLongString() {
      return Constant.STRING[this.$store.state.language].DESCRIPTION_TOO_LONG;
    },
    noModelNameString() {
      return Constant.STRING[this.$store.state.language].NO_MODEL_NAME;
    },
    noModelSizeString() {
      return Constant.STRING[this.$store.state.language].NO_MODEL_SIZE;
    },
    noModelLicenseString() {
      return Constant.STRING[this.$store.state.language].NO_MODEL_LICENSE;
    },
    submissionUploadingTitleString() {
      return Constant.STRING[this.$store.state.language]
        .SUBMISSION_UPLOADING_TITLE;
    },
    submissionUploadingMessageString() {
      return Constant.STRING[this.$store.state.language]
        .SUBMISSION_UPLOADING_MESSAGE;
    },
    todayRemainingSubmissionString() {
      return Constant.STRING[this.$store.state.language].REMAINING_SUBMISSION;
    },
    submissionNoticeString() {
      return Constant.STRING[this.$store.state.language].KLUE_SUBMISSION_NOTICE;
    }
  }, 
  mounted() {
    this.setHeight();
  },
  methods: {
    getSelectedLicenseTypeClass(item, type) {
      if (type === item) {
        return 'license-toggle-button--active';
      }
      return '';
    },
    getSelectedSizeTypeClass(item, type) {
      if (type === item) {
        return 'size-toggle-button--active';
      }
      return '';
    },
    setLicense(item) {
      this.modelLicense = item;
      this.modelLicenseEtc = '';
    },
    setSize(item) {
      this.modelSize = item;
    },
    setLicenseEtc() {
      this.modelLicense = this.modelLicenseEtc;
    },
    setHeight() {
      this.$nextTick(() => {
        let tableHeight = this.$refs.hyperparameters.$el.offsetHeight;
        console.log(this.$refs.hyperparameters.$el, tableHeight);
        this.descHeight = tableHeight + 337;
      })
    },
    onProgress(percent) {
      this.uploadPercent = percent;
    },
    closeDialog() {
      this.submissionDialog = false;
      this.progressBarDialog = false;
    },
    clearFile() {
      sourceFile = null;
    }, 
    parseParameters(parameters) {
      let parsed = {};
      parameters.elements.forEach(params => {
        if (params.change !== 'add') {
          parsed[params.key] = params.value;
        }
      });
      return parsed;
    },
    submit() {
      let hyperparameters = {
        inference: this.parseParameters(this.param),
      };
      let payload = {
        description: this.description,
        competitionID: this.id,
        hyperparameters: JSON.stringify(hyperparameters),
        name: this.modelName, 
        url: this.modelUrl, 
        license: this.modelLicense, 
        modelSize: this.modelSize,
        fileSize: this.sourceFile ? this.sourceFile.size : 0
      };
      this.submitFile(payload);
    },
    submitFile(payload) {
      this.$store
        .dispatch('getPreSignedURL', payload)
        .then(data => this.commitSubmission(data))
        .catch(e => {
          let err = e.response && e.response.data ? e.response.data.detail : e.name;
          this.submissionDialog = true;
          this.submissionResult = {
            type: this.submissionResultType.FAILURE,
            detail: err,
          };
        });
    },
    commitSubmission(destination) {
      if (!this.sourceFile) {
        alert(this.noSourceFileString);
        return;
      }
      if (this.description.length > 500) {
        alert(this.descriptionTooLongString);
        return;
      }
      if (!this.modelName) {
        alert(this.noModelNameString);
        return;
      }
      if (!this.modelSize) {
        alert(this.noModelSizeString);
        return;
      }
      if (!this.modelLicense) {
        alert(this.noModelLicenseString);
        return;
      }

      let payload = {
        sourceFile: this.sourceFile,
        destination: destination,
        onProgress: this.onProgress,
      };
      this.progressBarDialog = true;
      this.$store
        .dispatch('commitSubmission', payload)
        .then(() => this.completeSubmission())
        .catch(e => {
          this.submissionDialog = true;
          this.progressBarDialog = false;
          this.submissionResult = {
            type: this.submissionResultType.FAILURE,
            detail: e.name,
          };
        });
    },
    completeSubmission() {
      this.submissionResult = {
        type: this.submissionResultType.SUCCESS,
        detail: 'Submission Success',
      };
      this.submissionDialog = true;
      this.progressBarDialog = false;
      this.sourceFile = null;
      this.description = '';
      this.modelName = '';
      this.param = {
        type: 'inference',
        tooltip: false,
        input: '',
        key: '',
        value: '',
        elements: [
          {
            change: 'add',
            key: 'input',
            value: 'input',
          },
        ],
      };
      this.uploadPercent = 0;
    },
    copyText(item) {
      const element = document.createElement('textarea');
      element.value = item.input;
      element.setAttribute('readonly', '');
      element.style.position = 'absolute';
      element.style.left = '-9999px';
      document.body.appendChild(element);
      element.select();
      var returnValue = document.execCommand('copy');
      document.body.removeChild(element);
      if (!returnValue) {
        throw new Error('copied nothing');
      }
      item.tooltip = true;
      setTimeout(() => {
        item.tooltip = false;
      }, 400);
    },

    addItem(parameter) {
      if (!parameter.key || !parameter.value) return;

      parameter.elements.splice(parameter.elements.length - 1, 0, {
        change: 'delete',
        key: parameter.key,
        value: parameter.value,
      });

      parameter.input =
        parameter.input + ` --${parameter.key} ${parameter.value}`;
      parameter.key = '';
      parameter.value = '';

      this.setHeight();
    },

    deleteItem(parameter, item) {
      parameter.elements.splice(parameter.elements.indexOf(item), 1);
      parameter.input = parameter.input.replace(
        new RegExp(`-*${item.key} ${item.value}`),
        '',
      );

      this.setHeight();
    },

    bindItem(parameter) {
      if (parameter.input.trim() === '') {
        parameter.elements = [{ change: 'add', key: 'input', value: 'input' }];
        return;
      }

      const splits = parameter.input.trim().split(' ');
      let elements = [];

      for (let i = 0; i < splits.length; i++) {
        if (splits[i].startsWith('-')) {
          let key = splits[i];
          let value = '';

          if (splits[i].includes('=')) {
            [key, value] = splits[i].split('=');
          } else if (i + 1 < splits.length && !splits[i + 1].startsWith('-')) {
            value = splits[i + 1];
          }

          elements.push({
            change: 'delete',
            key: key.replaceAll('-', ''),
            value: value,
          });
        }
      }
      elements.push({ change: 'add', key: 'input', value: 'input' });
      parameter.elements = elements;

      this.setHeight();
    },
  },
  data() {
    return {
      sourceFile: null,
      todayRemainingSubmission: 0,
      descHeight: 300,
      loading: false,
      submissionDialog: false,
      progressBarDialog: false,
      submissionResult: {},
      uploadPercent: 0,
      clearImageSrc: require('@/assets/images/icons/file_clear.svg'), 
      fileImageSrc: require('@/assets/images/icons/file_submission.svg'),
      copyImageSrc: require('@/assets/images/icons/copy.svg'),
      modelName: '',
      modelSize: '',
      modelUrl: '',
      modelLicense: '',
      modelLicenseEtc: '',
      description: '',
      param: {
        type: 'inference',
        tooltip: false,
        input: '',
        key: '',
        value: '',
        elements: [
          {
            change: 'add',
            key: 'input',
            value: 'input',
          },
        ],
      },
      parameterHeaders: [
        {
          text: '',
          value: 'change',
          align: 'start',
          sortable: false,
        },
        {
          text: 'Key',
          value: 'key',
          align: 'start',
          sortable: false,
        },
        {
          text: 'Value',
          value: 'value',
          align: 'start',
          sortable: false,
        },
      ],
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';

.submission-container {
  flex: 1;
}

.file-submission-area {
  cursor: pointer;
  width: 100%;
  height: 120px;
  margin-bottom: 28px;
  background-color: #FBF8F7;
  border: 1px dashed #907B6D;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  .file-submission-icon {
    margin-right: 40px;
  }

  .file-submission-title {
    @extend .body-heavy;
  }

  .file-submission-sub {
    @extend .body-heavy;
    color: rgba(81, 81, 81, 0.25);
  }
}

hr {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.select {
  display: none;
}

.optional {
  color: #AAAAAA;
}

.description {
  color: #AAAAAA;
  @extend .small-light;
  margin-top: 5px;
}

.icon-copy {
  cursor: pointer;
}

.button {
  background-color: #E84660 !important;
  border-radius: 5px;
  letter-spacing: normal !important;
  @extend .subheader-heavy;
  color: #ffffff;
  text-transform: none !important;
}

.license-toggle-button, .size-toggle-button {
  border-radius: 5px;
  background-color: white !important;
  border: 1px solid rgba(0, 0, 0, 0.15);
  @extend .body-light;
  color: #AAAAAA;
  text-transform: none !important;
  letter-spacing: normal !important;
  &--active {
    background-color: rgba(235, 235, 235, 0.87) !important;
    color: #363636 !important;
  } 
}

.license-input {
  max-width: 122px;
  &::v-deep fieldset {
    border: 1px solid rgba(0, 0, 0, 0.15) !important;
  }

  &::v-deep input {
    text-align: center;
  }
}

.file-submission-upload-row {
  padding-right: 20px;
  margin-bottom: 32px;

  .file-submission-upload {
    margin-bottom: 17px;
    display: flex;
    align-items: center;
    padding: 0 20px;

    .uploaded-title {
      @extend .body-heavy;
    }

    .uploaded-name {
      @extend .body-light;
      text-align: right;
      margin: 0 16px;
      flex: 1;
    }
  }
}

.file-submission {
  padding-left: 20px;
  display: flex;

  &-left {
    flex: 1;
    margin-right: 36px;
  }

  &-right {
    flex: 1;
  }

  .file-submission-row {
    margin-bottom: 32px;

    .model-title-text, .parameter-text, .model-desc-text {
      @extend .body-heavy;
      margin-bottom: 16px;
    }

    .model-desc-input {
      display: flex;
      justify-content: space-between;
    }

    .describe {
      @extend .body-light;
    }

    &::v-deep input {
      @extend .body-light;
    }

    &::v-deep .v-input__append-inner {
      margin-top: 12px !important;
    }
  }
}

.submission-notice-row {
  margin-top: 24px;
  margin-bottom: 32px;
  display: flex;
  justify-content: flex-end;
  text-align: right;
  @extend .body-light;
  color: #AAAAAA;
}

.button-row {
  display: flex;
  justify-content: flex-end;
}

.data-table {
  margin-top: 24px;

  &::v-deep .v-data-table-header {
    background-color: #f7f2f0;
    th {
      color: #212121;
    }
  }

  &::v-deep .v-data-footer {
    @extend .body-light;
    .v-select__selection {
      @extend .body-light;
    }
  }

  &::v-deep .v-input__slot {
    background-color: transparent !important;
    padding: 0px !important;
  }
}

</style>