var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"leaderboard-container"},[_c('div',{staticClass:"leaderboard-text-row"},[_c('div',{staticClass:"leaderboard-title"},[_vm._v(_vm._s(_vm.klueLeaderboardTitleString))]),_c('div',{staticClass:"leaderboard-desc",domProps:{"innerHTML":_vm._s(_vm.klueLeaderboardDescString)}})]),_c('div',{staticClass:"leaderboard-button-row"},_vm._l((_vm.leaderboardSize),function(value,key){return _c('v-btn',{key:key,staticClass:"tab-button",class:_vm.tab === key? 'tab-button-active' : '',attrs:{"width":"96","height":"36","depressed":""},on:{"click":function($event){return _vm.changeTab(key)}}},[_vm._v(_vm._s(value))])}),1),_c('div',{staticClass:"leaderboard-row"},[_c('v-data-table',{staticClass:"data-table",attrs:{"hide-default-header":"","headers":_vm.headers,"items":_vm.records,"loading":_vm.loading,"items-per-page":5},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('tr',_vm._l((_vm.topHeaders),function(header){return _c('th',{key:header,attrs:{"colspan":headers.filter(function (el) { return el.text.includes(header); }).length}},[_vm._v(" "+_vm._s(header.includes(' ') ? header.split(' ')[0] : header)+" ")])}),0),_c('tr',_vm._l((headers),function(header){return _c('th',{key:header.value},[(header.value.includes('\\'))?_c('div',{staticClass:"header-inner"},[(header.text.split('\\')[1].includes(' '))?_c('span',[_vm._v(" "+_vm._s(_vm.capitalize(header.text.split('\\')[1].split(' ')[1]))+" "),_c('sup',[_vm._v(" "+_vm._s(_vm.superString(header.text.split('\\')[1]))+" ")])]):_c('span',[_vm._v(_vm._s(header.text.split('\\')[1]))]),_c('div',{staticClass:"sort"},[_c('img',{class:header.value === _vm.sorting && _vm.ascending ? 'sort-active' : '',attrs:{"src":require('@/assets/images/icons/sort_up.svg'),"width":"6px","height":"6px"},on:{"click":function($event){return _vm.sortRecord(header.value, true)}}}),_c('img',{class:header.value === _vm.sorting && !_vm.ascending ? 'sort-active' : '',attrs:{"src":require('@/assets/images/icons/sort_down.svg'),"width":"6px","height":"6px"},on:{"click":function($event){return _vm.sortRecord(header.value, false)}}})])]):_vm._e()])}),0)]}},_vm._l((_vm.headers),function(header){return {key:("item." + (header.value)),fn:function(ref){
var item = ref.item;
return [_c('span',{key:header.value,class:item[header.value].top ? 'top-score' : ''},[_vm._v(" "+_vm._s(item[header.value].hasOwnProperty('value') ? item[header.value].value : item[header.value])+" ")])]}}}),{key:"item.ranking",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ranking"},[_c('div',{staticClass:"ranking-left"}),_c('div',{staticClass:"ranking-content"},[_vm._v(_vm._s(_vm.records.indexOf(item) + 1))])])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.showPopup(item)}}},[_vm._v(_vm._s(_vm.moreString))])]}},{key:"item.downloadURL",fn:function(ref){
var item = ref.item;
return [(item.downloadURL)?_c('a',{attrs:{"target":"_blank","href":item.downloadURL}},[_c('img',{attrs:{"src":_vm.download,"width":"16"}})]):_vm._e()]}}],null,true)}),_c('detail-description-dialog',{ref:"dialog",attrs:{"dialog":_vm.dialog,"headers":_vm.headers,"topHeaders":_vm.topHeaders,"records":_vm.records,"content":_vm.popupContent,"isMultiple":true},on:{"close:simple":_vm.closePopup}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }