var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.comment.user)?_c('div',{staticClass:"comment-container"},[_c('div',{staticClass:"comments-left"},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","nudge-bottom":"30","nudge-left":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"hidden-xs-only rounded-circle comments-user",class:['circle', _vm.comment.user.detail.type.toLowerCase()],attrs:{"src":_vm.comment.user.detail.image_url,"max-width":"48","max-height":"48","width":"48","height":"48"}},'v-img',attrs,false),on))]}}],null,false,627549796)},[_c('UserCard',{attrs:{"userID":_vm.comment.user.id.toString(),"stateButton":false}})],1),_c('div',{staticClass:"vote",on:{"click":_vm.toggleVote}},[(_vm.voted)?_c('v-icon',{attrs:{"small":"","color":"pink"}},[_vm._v("mdi-heart")]):_c('v-icon',{attrs:{"small":"","color":"pink"}},[_vm._v("mdi-heart-outline")]),_vm._v(" "+_vm._s(_vm.numVotes)+" ")],1)],1),_c('div',{staticClass:"comments-right"},[(_vm.editMode)?_c('v-card',{staticClass:"comment-card",attrs:{"outlined":"","elevation":"0"}},[_c('PostEditor',{ref:"postEditor",attrs:{"initial-content":_vm.comment.body,"isComment":true,"active-buttons":[
          'bold',
          'italic',
          'strike',
          'underline',
          'code',
          'paragraph',
          'h1',
          'h2',
          'h3',
          'bullet_list',
          'ordered_list',
          'blockquote',
          'code_block',
          'horizontal_rule',
          'undo',
          'redo',
          'image' ],"submitButton":true,"cancelButton":true},on:{"submit":_vm.editComment,"cancel":_vm.deactivateEditMode}})],1):_c('v-card',{staticClass:"comment-card",attrs:{"outlined":"","elevation":"0"}},[_c('div',{staticClass:"comment-title-row"},[_c('div',{staticClass:"comment-title-row-left"},[_c('div',{staticClass:"author-name"},[_vm._v(" Commented by "+_vm._s(_vm.comment.user.detail.nickname)+" ")]),_c('div',{staticClass:"divider"}),_c('div',{staticClass:"comment-time"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(_vm.comment.created_at))+" ")])]),_c('div',{staticClass:"comment-title-row-right"},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#C4C4C4","fab":"","x-small":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3184910640)},[_c('v-list',_vm._l((_vm.actions.filter(
                  function (action) { return action.active; }
                )),function(action,index){return _c('v-list-item',{key:index,on:{"click":action.function}},[_c('v-list-item-title',[_vm._v(_vm._s(action.name))])],1)}),1)],1)],1)]),_c('editor-content',{staticClass:"editor__content",attrs:{"editor":_vm.editor}}),_c('div',{staticClass:"editor-divider"}),_vm._l((_vm.replies),function(reply,index){return _c('div',{key:index,staticClass:"reply-field"},[_c('div',{staticClass:"reply-title-row"},[_c('div',{staticClass:"reply-author"},[_vm._v(_vm._s(reply.user.detail.nickname))]),_c('div',{staticClass:"reply-time"},[_vm._v(_vm._s(_vm._f("dateFormat")(reply.created_at)))]),_c('div',{staticClass:"reply-delete"},[_c('div',{staticClass:"divider"}),(_vm.replyDeleteActive(reply))?_c('div',{on:{"click":function($event){return _vm.deleteReply(reply)}}},[_vm._v(" "+_vm._s(_vm.deleteString)+" ")]):_vm._e()])]),_c('div',{staticClass:"reply-content"},[_vm._v(" "+_vm._s(reply.text)+" ")]),_c('div',{staticClass:"reply-divider"})])}),_c('div',{staticClass:"reply-add"},[(_vm.replyActive)?_c('v-textarea',{staticClass:"reply-input",attrs:{"outlined":"","hide-details":"","label":"add a reply"},model:{value:(_vm.reply),callback:function ($$v) {_vm.reply=$$v},expression:"reply"}}):_vm._e(),_c('v-btn',{staticClass:"reply-btn",attrs:{"depressed":""},on:{"click":_vm.commitReply}},[_vm._v(_vm._s(_vm.addReplyString))])],1)],2)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }