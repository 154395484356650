<template>
  <div class="leaderboard-container">
    <div class="leaderboard-button-row">
      <v-btn
        v-for="(value, key) in leaderboardSize"
        :key="key"
        width="96"
        height="36"
        depressed
        class="tab-button"
        :class="tab === key? 'tab-button-active' : ''"
        @click="changeTab(key)"
        >{{ value }}</v-btn>
    </div>
    <v-data-table
      hide-default-header
      class="data-table"
      :headers="headers"
      :items="records"
      :loading="loading"
      :items-per-page="5"
    >
      <template v-slot:header="{props: {headers}}">
        <tr>
          <th v-for="header in headers"
            :key="header.value">
            <div v-if="header.text.includes('\\')" class="header-inner">
              <span>{{header.text.split('\\')[1]}}</span>
              <div class="sort">
                <img 
                  :src="require('@/assets/images/icons/sort_up.svg')" 
                  width="6px" height="6px"
                  :class="header.text === sorting && ascending ? 'sort-active' : ''" 
                  @click="sortRecord(header.text, true)">
                <img 
                  :src="require('@/assets/images/icons/sort_down.svg')" 
                  width="6px" height="6px"
                  :class="header.text === sorting && !ascending ? 'sort-active' : ''" 
                  @click="sortRecord(header.text, false)">
              </div>
            </div>
            <div v-else>{{header.text}}</div>
          </th>
        </tr>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <span style="cursor: pointer;" @click.stop="showPopup(item)">{{moreString}}</span>
      </template>

      <template v-slot:[`item.ranking`]="{ item }">
        <div class="ranking">
          <div class="ranking-left"></div>
          <div class="ranking-content">{{ records.indexOf(item) + 1 }}</div>
        </div>
      </template>

      <template v-for="header in headers" v-slot:[`item.${header.text}`]="{item}">
        <span :key="header.text" :class="item[header.text].top ? 'top-score' : ''">{{item[header.text].value}}</span>
      </template>

      <template v-slot:[`item.url`]="{ item }">
        <a target="_blank" :href="item.url" v-if="item.url">
          <img :src="url" width="16">
        </a>
      </template>
      <template v-slot:[`item.downloadURL`]="{ item }">
        <a target="_blank" :href="item.downloadURL" v-if="item.downloadURL">
          <img :src="download" width="16">
        </a>
      </template>
    </v-data-table>
    <detail-description-dialog 
      ref="dialog"
      :dialog="dialog" 
      :content="popupContent" 
      :isMultiple="false"
      @close:simple="closePopup"
    ></detail-description-dialog >
  </div>
</template>

<script>
import Constant from '@/store/constant.js';
import DetailDescriptionDialog from '@/components/dialog/DetailDescriptionDialog.vue';

export default {
  name: 'Record',
  components: {
    DetailDescriptionDialog
  },
  props: {
    id: String,
    task: Object,
  },
  computed: {
    moreString() {
      return Constant.STRING[this.$store.state.language].MORE;
    },
    selectString() {
      return Constant.STRING[this.$store.state.language].SELECT;
    },
    leaderboardSize() {
      return Constant.TYPE.LEADERBOARD_SIZE;
    },
    defaultHeader() {
      return [
        {
          text: '#',
          value: 'ranking',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Team',
          value: 'team',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Model',
          value: 'model',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Description',
          value: 'description',
          align: 'center',
          sortable: false,
        },
        {
          text: 'URL',
          value: 'url',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Download',
          value: 'downloadURL',
          align: 'center',
          sortable: false,
        },
      ]
    },
    defaultTopHeader() {
      return ['#', 'Team', 'Model', 'Description', 'URL', 'Download'];
    }
  },
  mounted() {
    this.fetchAllRecords();
  },
  methods: {
    showPopup(value) {
      this.popupContent = value;
      this.dialog = true;
    },
    closePopup() {
      this.dialog = false;
    }, 
    changeTab(size) {
      this.tab = size;
      this.fetchAllRecords();
    },
    emphasizeTopValue() {
      this.headers.filter(el => el.text.includes('\\')).forEach(el => {
        let key = el.text;
        let max = 0, maxIdx=-1;
        this.records.forEach((el, idx) => {
          if(max < el[key].value) {
            maxIdx = idx;
            max = el[key].value;
          }
        });
        if(maxIdx !== -1) this.records[maxIdx][key].top = true;
      })
    },
    sortRecord(metric, isAscending) {
      this.sorting = metric;
      this.ascending = isAscending;

      let sign = isAscending ? 1 : -1;
      this.records.sort((a, b) => {
        if(a[metric].value > b[metric].value) return sign;
        else if(a[metric].value < b[metric].value) return -sign;
        else return 0;
      });
      
      let idx = this.headers.findIndex(el => el.text === metric);
      this.$nextTick(() => {
        document.querySelectorAll('.data-table tr td:first-child').forEach(el => {
          el.style.backgroundColor = null;
          el.querySelector('.ranking-left').style.backgroundColor = null;
          
          let rank = parseInt(el.innerText);
          if(rank <= 10) {
            switch(rank) {
              case 1:
                el.style.backgroundColor = 'rgba(232, 70, 96, 0.1)';
                el.querySelector('.ranking-left').style.backgroundColor = '#E84660';
                break;
              case 2: case 3:
                el.style.backgroundColor = 'rgba(205, 164, 52, 0.1)';
                el.querySelector('.ranking-left').style.backgroundColor = '#CDA434';
                break;
              case 4: case 5: case 6:
                el.style.backgroundColor = 'rgba(131, 130, 128, 0.1)';
                el.querySelector('.ranking-left').style.backgroundColor = '#838280';
                break;
              default:
                el.style.backgroundColor = 'rgba(142, 91, 61, 0.1)';
                el.querySelector('.ranking-left').style.backgroundColor = '#8E5B3D';
                break;
            }
          }
        });

        document.querySelectorAll('td:nth-of-type(n+2)').forEach(el => {
          el.style.backgroundColor = 'white';
        });
        document.querySelectorAll(`td:nth-of-type(${idx+1})`).forEach(el => {
          el.style.backgroundColor = '#F9D1CB';
        });
      })
    },
    parseResultsInRecord(row) {
      let result = JSON.parse(row.result);
      Object.keys(result).forEach(key => {
        let modified_key = key.includes(this.task.code) ? key.split(' ')[1] : key;
        row['\\' + modified_key] = {top: false, value: result[key]};
      });
      return row;
    },
    setHeaders(headers) {
      if(this.records.length === 0) return;

      let results = JSON.parse(this.records[0].result);
      Object.keys(results).forEach((key, index) => {
        let modified_key = key.includes(this.task.code) ? key.split(' ')[1] : key;
        headers.splice(index + 4, 0, {
          text: '\\' + modified_key,
          value: '\\' + modified_key,
          align: 'center',
          sortable: false,
        });
      });

      this.sortRecord(this.headers.find(el => el.value.includes('\\')).text, false);
    },
    fetchAllRecords() {
      this.records = [];
      this.headers = this.defaultHeader.slice();
      this.topHeaders = this.defaultTopHeader.slice();
      this.loading = true;
      let competitionID = this.id;
      let mode = 'all';
      let size = this.tab.toLowerCase();

      this.$store
        .dispatch('fetchRecords', { competitionID, mode, size })
        .then(data => {
          data.forEach(item => {
            let inferences = item.submission.inferences;
            if(inferences) {
              inferences.sort((a, b) => new Date(b.finished_at) - new Date(a.finished_at));
            }
            let inferenceTime = inferences && inferences.length !== 0 
              ? new Date(inferences[0].finished_at) - new Date(inferences[0].started_at)
              : 0;
            let row = {
              id: item.submission.id,
              team: item.team.members[0].user.detail.company,
              model: item.submission.name,
              result: item.result,
              description: item.submission.description,
              url: item.submission.url,
              license: item.submission.license,
              size: item.submission.size,
              inference: inferenceTime / 1000, 
              downloadURL: ''
            };

            // this.$store.dispatch('getDownloadURL', {
            //   competitionID, 
            //   submissionID: item.submission.id,
            //   isHost: true
            // })
            // .then(data => {
            //   row['downloadURL'] = data['url'];
            // })
            row = this.parseResultsInRecord(row);
            this.records.push(row);
          });
          this.setHeaders(this.headers);
          this.emphasizeTopValue();
        })
        .catch(e => alert(e))
        .finally(() => {
          this.loading = false;
        });
    },
  },
  data() {
    return {
      url: require('@/assets/images/icons/url.svg'),
      download: require('@/assets/images/icons/download.svg'),
      loading: false,
      dialog: false,
      popupContent: null,
      sorting: '',
      ascending: true,
      tab: 'ALL',
      topHeaders: [],
      headers: [],
      records: [],
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';
.leaderboard-container {
  flex: 1;
}

.leaderboard-button-row {
  margin-bottom: 32px;
}

.top-score {
  color: #E84660;
  font-weight: 700;
}

.tab-button {
  background-color: white !important;
  border-radius: 5px;
  letter-spacing: normal !important;
  @extend .caption-heavy;
  color: #705C4F !important;
  text-transform: none !important;
  margin-right: 12px;
  border: 2px solid #705C4F;

  &-active {
    background-color: #705C4F !important;
    color: white !important;
  }
}

.data-table {
  width: 100%;
  &::v-deep td {
    @extend .caption-light;
    font-size: 12px !important;

    &:last-of-type a {
      margin-top: 5px;
      display: inline-block;
      height: 16px;
    }
  }
  &::v-deep tr td:first-child {
    padding: 0 !important;
    width: 96px;
    .ranking {
      display: flex;
      align-items: center;
      height: 100%;

      &-left {
        width: 8px;
        height: 100%;
      }
      &-content {
        display: flex;
        align-items: center;
        flex: 1;
        height: 100%;
        justify-content: center;
      }
    }
  }

  table > tr {
    height: 48px;
    &:first-of-type {
      background-color: #f7f2f0;
      th {
        @extend .caption-heavy;
      }
    }

    th {
      background-color: #FBF8F7;
      color: #515151 !important;
      @extend .body-heavy;

      &:last-of-type {
        padding-right: 12px;
      }
      
      .header-inner {
        display: flex;
        align-items: center;
        justify-content: center;

        .sort {
          display: flex;
          flex-direction: column;
          margin-left: 5px;

          img {
            margin: 1px;
            cursor: pointer;
          }

          &-active {
            filter: invert(45%) sepia(66%) saturate(6036%) hue-rotate(330deg) brightness(102%) contrast(82%);
          }
        }
      }
    }
  }

  &::v-deep .v-data-footer {
    @extend .body-light;
    .v-select__selection {
      @extend .body-light;
    }
  }

  .final {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      @extend .body-light;
      margin-right: 13px;

    }
  }
}
</style>