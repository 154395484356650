import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

import state from './state.js';
import mutations from './mutations.js';
import actions from './actions.js';

Vue.use(Vuex);

function enhanceAccessToken() {
  const token = localStorage.getItem('token');
  const username = localStorage.getItem('username');
  const userImageURL = localStorage.getItem('userImageURL');
  const userID = localStorage.getItem('userID');
  const nickname = localStorage.getItem('nickname');
  const userType = localStorage.getItem('userType');
  const language = localStorage.getItem('language');

  axios.defaults.headers.common['Authorization'] = token
    ? `Bearer ${token}`
    : '';
  state.token = token;
  state.username = username;
  state.userImageURL = userImageURL;
  state.userID = userID;
  state.nickname = nickname;
  state.userType = userType;
  state.language = language ? language : 'KO';
}
enhanceAccessToken();

export default new Vuex.Store({
  state: state,
  mutations: mutations,
  actions: actions,
});
