<template>
  <div class="record-container">
    <v-data-table
      class="data-table"
      :headers="headers"
      :items="records"
      :loading="loading"
      :items-per-page="5"
    >
      <template v-slot:[`item.id`]="{ item }">
        {{ tag(task.name) + ' - ' + item.id }}
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <span style="cursor: pointer;" @click.stop="showPopup('Description', item.description, $event)">{{moreString}}</span>
      </template>

      <template v-slot:[`item.hyperparameters`]="{ item }">
        <span style="cursor: pointer;" @click.stop="showPopup('Hyperparameters', item.hyperparameters, $event)">{{moreString}}</span>
      </template>

      <template v-slot:[`item.final`]="{ item }">
        <div class="final">
          <span>{{selectString}}</span>
          <v-simple-checkbox
            :value="item.final"
            :ripple="false"
            on-icon="mdi-checkbox-blank"
            color="#E84660"
            @click="commitFinal(item)"
          ></v-simple-checkbox>
        </div>
      </template>
    </v-data-table>
    <simple-dialog 
      ref="dialog"
      :dialog="dialog" 
      :title="popupTitle" 
      :content="popupContent" 
      @close:simple="closePopup"
    ></simple-dialog>
  </div>
</template>

<script>
import Constant from '@/store/constant.js';
import SimpleDialog from '@/components/dialog/SimpleDialog.vue';

export default {
  name: 'Record',
  components: {
    SimpleDialog
  },
  props: {
    id: String,
    task: Object,
  },
  computed: {
    moreString() {
      return Constant.STRING[this.$store.state.language].MORE;
    },
    selectString() {
      return Constant.STRING[this.$store.state.language].SELECT;
    },
    klueString() {
      return Constant.STRING[this.$store.state.language].KLUE;
    }
  },
  methods: {
    showPopup(title, value, event) {
      this.popupTitle = title;
      this.popupContent = value;
      this.dialog = true;
    },
    closePopup() {
      this.dialog = false;
    }, 
    tag(name) {
      if(name === 'Total Evaluation') return this.klueString;
      return name.match(/\b(\w)/g).join('');
    },
    fetchMyRecords() {
      this.records = [];
      this.loading = true;
      let competitionID = this.id;
      let mode = 'my';
      let size = 'all';

      this.$store
        .dispatch('fetchRecords', { competitionID, mode, size })
        .then(data => {
          data.forEach(item => {
            let row = {
              id: item.submission.local_id,
              submission_id: item.submission.id,
              model: item.submission.name,
              hyperparameters: item.submission.hyperparameters,
              description: item.submission.description,
              result: item.result,
              final: item.submission.final,
            };
            row = this.parseResultsInRecord(row);
            this.records.push(row);
          });
          this.records.sort((a, b) => {
            return a.submission_id - b.submission_id;
          })
          this.setHeaders(this.records, this.headers);
        })
        .catch(e => alert(e))
        .finally(() => (this.loading = false));
    },

    parseResultsInRecord(row) {
      let result = JSON.parse(row.result);
      Object.keys(result).forEach(key => {
        if (result[key].toFixed) {
          // Legacy
          row[key] = Number(result[key].toFixed(3));
        } else {
          // Json
          row[key] = result[key].value;
        }
      });
      return row;
    },

    commitFinal(item) {
      this.records.forEach(element => {
        element.final = false;
      });
      const competitionID = this.id;
      const submissionID = item.submission_id;
      this.$store.dispatch('commitFinal', { competitionID, submissionID });
      item.final = !item.final;
    },

    setHeaders(records, headers) {
      if (records.length === 0) {
        return;
      }
      let results = JSON.parse(records[0].result);
      if(this.task.is_multiple) return;

      Object.keys(results).forEach((key, index) => {
        this.addHeader(headers, key, index);
      });
      return;
    },

    addHeader(headers, header, index) {
      let added = headers.reduce(
        (acc, cur) => acc | (cur.text === header),
        false,
      );
      if (!added) {
        headers.splice(2 + index, 0, {
          text: header,
          value: header,
          align: 'center',
          sortable: false,
        });
      }
    },
  },
  mounted() {
    this.fetchMyRecords();
  },
  data() {
    return {
      loading: false,
      dialog: false,
      popupTitle: '',
      popupContent: '',
      headers: [
        {
          text: 'Submission ID',
          value: 'id',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Result',
          value: 'result',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Model',
          value: 'model',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Description',
          value: 'description',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Hyperparameters',
          value: 'hyperparameters',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Final Submission',
          value: 'final',
          align: 'center',
          sortable: false,
        },
      ],
      records: [
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';
.record-container {
  flex: 1;
}

.data-table {
  width: 100%;
  &::v-deep .v-data-table-header {
    background-color: #FBF8F7;
    th {
      color: #515151 !important;
      @extend .body-heavy;
    }
  }

  td {
    @extend .body-light;
  }

  &::v-deep .v-data-footer {
    @extend .body-light;
    .v-select__selection {
      @extend .body-light;
    }
  }

  .final {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      @extend .body-light;
      margin-right: 13px;

    }
  }
}
</style>