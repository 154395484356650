import Vue from 'vue';
import {
  reformatSubmissions,
  reformatDailySubmissionCount,
  reformatCompetitionElapsedTime,
} from './utils.js';

const actions = {
  // Auth
  signIn(store, { username, password }) {
    delete Vue.axios.defaults.headers.common['Authorization'];
    let payload = { username: username, password: password };

    return Vue.axios
      .post('/api/v1/auth/', payload)
      .then(({ data }) => {
        store.commit('signIn', { data });
        let userID = data.user_id;
        return Vue.axios.get(`/api/v1/user/${userID}/`);
      })
      .then(({ data }) => {
        store.commit('setUserDetail', { data });
      });
  },

  signInWithGoogle(store, { token }) {
    delete Vue.axios.defaults.headers.common['Authorization'];
    let payload = { token: token };

    return Vue.axios
      .post('/api/v1/auth/google/', payload)
      .then(({ data }) => {
        store.commit('signIn', { data });
        let userID = data.user_id;
        return Vue.axios.get(`/api/v1/user/${userID}/`);
      })
      .then(({ data }) => {
        store.commit('setUserDetail', { data });
        return true;
      })
      .catch(e => {
        console.log(e);
        return false;
      });
  },

  signOut(store) {
    return Vue.axios.delete('/api/v1/auth/').finally(() => {
      store.commit('clearAuth');
    });
  },

  signUp(
    store, { image, username, password, nickname, term, policy, token },
  ) {
    let axios = Vue.axios.create();
    delete axios.defaults.headers.common['Authorization'];

    const formData = new FormData();
    formData.append('file', image);
    formData.append('username', username);
    formData.append('password', password);
    formData.append('nickname', nickname);
    formData.append('vcode', 'FF2DBA'); // TODO change vcode
    formData.append('term', term);
    formData.append('policy', policy);
    if (token) {
      formData.append('auth_method', 'oauth_google');
      formData.append('oauth_token', token);
    } else formData.append('auth_method', 'password');

    let headers = {
      'Content-Type': 'multipart/form-data',
    };

    return axios
      .post('/api/v1/user/', formData, { headers: headers })
      .then(({ data }) => {
        store.commit('signIn', { data });
      });
  },

  commitLanguage(store, { language }) {
    return store.commit('setLanguage', { language });
  },

  validateToken(store) {
    const payload = { key: store.state.token };
    return Vue.axios.patch('/api/v1/auth/', payload).catch(response => {
      if (response.status !== 200) {
        store.commit('clearAuth');
      }
    });
  },

  checkUnique(store, { username, nickname }) {
    let axios = Vue.axios.create();
    delete axios.defaults.headers.common['Authorization'];

    const formData = new FormData();
    formData.append('username', username);
    formData.append('nickname', nickname);
    let headers = {
      'Content-Type': 'multipart/form-data',
    };
    return axios
      .post('/api/v1/user/unique/', formData, { headers: headers })
      .then(({ data }) => {
        return data;
      });
  },

  fetchUserDetail(store, { userID }) {
    return Vue.axios.get(`/api/v1/user/${userID}/`).then(({ data }) => {
      store.commit('setUserDetail', { data });
      return data;
    });
  },

  editUser(
    store, { userID, image, password, nickname, company, sns, bio, term, policy },
  ) {
    const formData = new FormData();
    formData.append('nickname', nickname);
    formData.append('company', company);
    formData.append('sns', sns);
    formData.append('bio', bio);
    formData.append('term', term);
    formData.append('policy', policy);

    if (image) {
      formData.append('file', image);
    }

    if (password || password.length > 0) {
      formData.append('password', password);
    }

    let headers = {
      'Content-Type': 'multipart/form-data',
    };

    return Vue.axios.patch(`/api/v1/user/${userID}/`, formData, {
      headers: headers,
    });
  },

  // Competitions
  createCompetition(store) {
    return Vue.axios.post('/api/v1/competition/').then(({ data }) => {
      store.commit('setOrganizingCompetition', { data });
    });
  },

  updateCompetition(store, params) {
    const competitionID = params['competitionID'];
    delete params['competitionID'];

    const formData = new FormData();
    for (let key in params) {
      formData.append(key, params[key]);
    }
    let headers = {
      'Content-Type': 'multipart/form-data',
    };
    return Vue.axios
      .patch(`/api/v1/competition/${competitionID}/`, formData, {
        headers: headers,
      })
      .then(({ data }) => {
        store.commit('setOrganizingCompetition', { data });
      });
  },

  requestReview(store, { competitionID }) {
    return Vue.axios
      .post(`/api/v1/competition/${competitionID}/request_review/`)
      .then(() => {
        store.commit('updateOrganizingCompetition', {
          competitionState: 'Review',
        });
      });
  },

  getFilePresignedURL(store, { competitionID, type, filename }) {
    return Vue.axios
      .get(
        `/api/v1/competition/${competitionID}/upload_url/?type=${type}&filename=${filename}`,
      )
      .then(({ data }) => {
        return data.url;
      });
  },

  uploadBigFile(store, { url, file, onProgress }) {
    let axios = Vue.axios.create();
    delete axios.defaults.headers.common['Authorization'];

    const formData = new FormData();
    formData.append('file', file);

    let headers = {
      'Content-Type': 'multipart/form-data',
    };

    let config = {
      headers: headers,
      onUploadProgress: function(progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        if (onProgress) {
          onProgress(percentCompleted);
        }
        return percentCompleted;
      },
    };

    return axios.put(url, formData, config).then(() => {
      return url;
    });
  },

  setOrganizingCompetition(store, data) {
    store.commit('setOrganizingCompetition', { data });
  },

  fetchCompetitions(store) {
    return Vue.axios.get('/api/v1/competition/?view=main').then(({ data }) => {
      store.commit('setCompetitions', { data });
    });
  },

  fetchCompetitionOwns(store) {
    return Vue.axios.get('/api/v1/competition/?view=owner').then(({ data }) => {
      store.commit('setCompetitionOwns', { data });
    });
  },
  fetchCompetitionHosting(store) {
    return Vue.axios.get('/api/v1/competition/?view=host').then(({ data }) => {
      store.commit('setCompetitionOwns', { data });
    });
  },

  fetchTasks(store) {
    return Vue.axios.get('/api/v1/competition/?view=main&group=task').then(({ data }) => {
      store.commit('setTasks', { data });
    });
  },

  fetchCompetitionDetail(store, id) {
    return Vue.axios.get(`/api/v1/competition/${id}/`).then(({ data }) => {
      store.commit('setCompetitionDetail', { id, data });
    });
  },

  fetchTaskDetail(store, id) {
    return Vue.axios.get(`/api/v1/competition/${id}/`).then(({ data }) => {
      store.commit('setTaskDetail', { id, data });
    });
  },

  fetchCompetitionDailySubmissionCount(store, id) {
    return Vue.axios
      .get(`/api/v1/competition/${id}/stats/daily_submission_count/`)
      .then(({ data }) => {
        data = reformatDailySubmissionCount(data);
        return data;
      });
  },

  fetchCompetitionFailCount(store, id) {
    return Vue.axios
      .get(`/api/v1/competition/${id}/stats/fail_count/`)
      .then(({ data }) => {
        return data;
      });
  },
  fetchCompetitionElapsedTime(store, id) {
    return Vue.axios
      .get(`/api/v1/competition/${id}/stats/elapsed_time/`)
      .then(({ data }) => {
        data = reformatCompetitionElapsedTime(data);
        return data;
      });
  },

  fetchSubmissions(store, competitionID) {
    return Vue.axios
      .get(`/api/v1/competition/${competitionID}/submission/`)
      .then(({ data }) => {
        data = reformatSubmissions(data);
        return data;
      });
  },

  fetchTotalSubmissions(store, { competitionID, page, pageSize }) {
    return Vue.axios
      .get(
        `/api/v1/competition/${competitionID}/submission/?host_view=t&page=${page}&page_size=${pageSize}`,
      )
      .then(({ data }) => {
        data = reformatSubmissions(data);
        return data;
      });
  },

  fetchRemainingSubmission(store, { competitionID }) {
    return Vue.axios
      .get(`/api/v1/competition/${competitionID}/remaining_submission/`)
      .then(({ data }) => {
        return data;
      });
  },

  fetchSubmission(store, { competitionID, submissionID, isHost }) {
    let host = isHost ? 't' : 'f';
    return Vue.axios
      .get(
        `/api/v1/competition/${competitionID}/submission/?local_id=${submissionID}&host_view=${host}`,
      )
      .then(({ data }) => {
        data = reformatSubmissions([data])[0];
        return data;
      });
  },

  fetchLogs(store, { competitionID, submissionID, jobName }) {
    return Vue.axios
      .get(
        `/api/v1/competition/${competitionID}/logs/?local_id=${submissionID}&job_name=${jobName}`,
      )
      .then(({ data }) => {
        return data;
      });
  },

  fetchRecords(store, { competitionID, mode, size }) {
    return Vue.axios
      .get(`/api/v1/competition/${competitionID}/record/?mode=${mode}&size=${size}`)
      .then(({ data }) => {
        return data;
      });
  },

  getPreSignedURL(store, { description, competitionID, hyperparameters, name, url, license, modelSize, fileSize }) {
    const formData = new FormData();
    formData.append('description', description);
    formData.append('hyperparameters', hyperparameters);
    formData.append('name', name);
    formData.append('url', url);
    formData.append('license', license);
    formData.append('modelSize', modelSize);
    formData.append('fileSize', fileSize);

    let headers = {
      'Content-Type': 'multipart/form-data',
    };
    return Vue.axios
      .post(`/api/v1/competition/${competitionID}/presigned_url/`, formData, {
        headers: headers,
      })
      .then(({ data }) => {
        return data;
      });
  },

  getDownloadURL(store, { competitionID, submissionID, isHost }) {
    let host = isHost ? 't' : 'f';
    return Vue.axios
      .get(
        `/api/v1/competition/${competitionID}/download_url/?submission_id=${submissionID}&host_view=${host}`,
      )
      .then(({ data }) => {
        return data;
      });
  },

  async commitSubmission(store, { sourceFile, destination, onProgress }) {
    const formData = new FormData();
    let axios = Vue.axios.create();
    let auth = axios.defaults.headers.common['Authorization'];
    delete axios.defaults.headers.common['Authorization'];

    // Object.keys(destination['fields']).forEach(key =>
    //   formData.append(key, destination['fields'][key]),
    // );

    // formData.append('file', sourceFile);

    let startPointer = 0;
    let endPointer = sourceFile.size;
    let chunks = [];
    const chunkSize = 1024 * 1024 * 100;

    while (startPointer < endPointer) {
      let newStartPointer = startPointer + chunkSize;
      chunks.push(sourceFile.slice(startPointer, newStartPointer));
      startPointer = newStartPointer;
    }

    let headers = {
      'Content-Type': 'multipart/form-data',
    };

    let previousData = 0;

    let config = {
      onUploadProgress: progressEvent => {
        let percentCompleted = Math.round(
          (previousData + progressEvent.loaded) * 100 / sourceFile.size,
        );
        if (onProgress) onProgress(percentCompleted);
        return percentCompleted;
      },
    };

    let parts = [];
    for (let idx in chunks) {
      let res = await axios.put(destination['url'][idx], chunks[idx], config);
      parts.push({ 'ETag': res.headers['etag'], 'PartNumber': parseInt(idx) + 1 });
      previousData += chunkSize;
      console.log(res);
    }

    console.log(parts, destination);

    formData.append('parts', JSON.stringify(parts));
    formData.append('key', destination['fields']['key']);
    formData.append('upload_id', destination['fields']['upload_id']);

    axios.defaults.headers.common['Authorization'] = auth;

    return Vue.axios.post(`/api/v1/competition/${destination['fields']['competition_id']}/complete_upload/`, formData, headers = headers).then((data) => {
      return data;
    })

  },

  commitServer(store, { competitionID, type, description }) {
    const formData = new FormData();
    formData.append('type', type);
    formData.append('description', description);

    let headers = {
      'Content-Type': 'multipart/form-data',
    };
    return Vue.axios
      .post(`/api/v1/competition/${competitionID}/server/`, formData, {
        headers: headers,
      })
      .then(({ data }) => {
        return data;
      });
  },

  fetchServerContainers(store, { competitionID }) {
    return Vue.axios
      .get(`/api/v1/server/containers/?competition_id=${competitionID}`)
      .then(({ data }) => {
        return data;
      });
  },

  fetchServerImages(store, { competitionID, containerID }) {
    let api = `/api/v1/server/images/?competition_id=${competitionID}`;
    if (!containerID) {
      api = `/api/v1/server/images/?competition_id=${competitionID}`;
    } else {
      api = `/api/v1/server/images/?competition_id=${competitionID}&container_id=${containerID}`;
    }
    return Vue.axios.get(api).then(({ data }) => {
      return data;
    });
  },

  commitServerImageSubmission(
    store, { description, competitionID, hyperparameters, serverImage },
  ) {
    const formData = new FormData();
    formData.append('description', description);
    formData.append('competition_id', competitionID);
    formData.append('hyperparameters', hyperparameters);
    formData.append('image_id', serverImage['id']);

    let headers = {
      'Content-Type': 'multipart/form-data',
    };
    let config = {
      headers: headers,
    };
    return Vue.axios
      .post(`/api/v1/server/submit/`, formData, config)
      .then(({ data }) => {
        return data;
      });
  },

  pauseContainer(store, containerID) {
    const formData = new FormData();
    formData.append('container_id', containerID);
    console.log(containerID);

    return Vue.axios.post(`/api/v1/server/pause/`, formData);
  },

  resumeContainer(store, containerID) {
    const formData = new FormData();
    formData.append('container_id', containerID);

    return Vue.axios.post(`/api/v1/server/resume/`, formData);
  },

  fetchCredit() {
    return Vue.axios.get(`/api/v1/server/credit/`).then(({ data }) => {
      return data;
    });
  },

  createContainer(
    store, { competitionID, gpuType, gpuCount, requestMinutes, description, ports },
  ) {
    const formData = new FormData();
    formData.append('competition_id', competitionID);
    formData.append('description', description);
    formData.append('gpu_count', gpuCount);
    formData.append('gpu_type', gpuType);
    formData.append('minutes', requestMinutes);
    formData.append('competition_id', competitionID);
    formData.append('ports', ports);

    return Vue.axios
      .post(`/api/v1/server/assign/`, formData)
      .then(({ data }) => {
        return data;
      });
  },

  terminateContainer(store, { containerID }) {
    return Vue.axios
      .delete(`/api/v1/server/terminate/?container_id=${containerID}`)
      .then(({ data }) => {
        return data;
      });
  },

  saveContainer(store, { containerID, description }) {
    const formData = new FormData();
    formData.append('container_id', containerID);
    formData.append('description', description);
    return Vue.axios.post(`/api/v1/server/save/`, formData).then(({ data }) => {
      return data;
    });
  },

  loadImage(store, { competitionID, imageID }) {
    return Vue.axios
      .get(
        `/api/v1/competition/${competitionID}/load_image/?image_id=${imageID}`,
      )
      .then(({ data }) => {
        return data;
      });
  },

  commitFinal(store, { competitionID, submissionID }) {
    return Vue.axios
      .get(
        `/api/v1/competition/${competitionID}/update_record/?submission_id=${submissionID}`,
      )
      .then(({ data }) => {
        return data;
      });
  },

  // Discussion
  fetchDiscussions(store) {
    return Vue.axios.get('/api/v1/discussion/').then(({ data }) => {
      store.commit('setDiscussions', { data });
    });
  },

  commitPost(
    store, { type, title, body, competitionID, discussionID, fileURLs },
  ) {
    let emptyBody = '<head></head><body><p></p></body>';
    if (body === emptyBody) {
      return Promise.reject('본문을 입력하세요');
    } else if (!title) {
      return Promise.reject('제목을 입력하세요');
    } else if (!competitionID && !discussionID) {
      return Promise.reject('잘못된 요청입니다');
    }
    const formData = new FormData();
    formData.append('type', type);
    formData.append('title', title);
    formData.append('body', body);
    formData.append('files', fileURLs);
    if (competitionID) formData.append('competition', competitionID);
    if (discussionID) formData.append('discussion', discussionID);

    let headers = {
      'Content-Type': 'multipart/form-data',
    };
    return Vue.axios
      .post(`/api/v1/post/`, formData, { headers: headers })
      .then(({ data }) => {
        return data;
      });
  },

  editPost(store, { type, title, body, postID, fileURLs }) {
    const formData = new FormData();
    formData.append('type', type);
    formData.append('title', title);
    formData.append('body', body);
    formData.append('files', fileURLs);
    let headers = {
      'Content-Type': 'multipart/form-data',
    };
    return Vue.axios
      .patch(`/api/v1/post/${postID}/`, formData, { headers: headers })
      .then(({ data }) => {
        return data;
      });
  },

  deletePost(store, { postID }) {
    return Vue.axios.delete(`/api/v1/post/${postID}/`);
  },

  fetchPosts(store, params) {
    let p = new URLSearchParams(params);
    return Vue.axios.get(`/api/v1/post/?` + p.toString()).then(({ data }) => {
      return data;
    });
  },

  fetchPost(store, { postID }) {
    return Vue.axios.get(`/api/v1/post/${postID}/`).then(({ data }) => {
      return data;
    });
  },

  commitComment(store, { body, postID, fileURLs }) {
    let emptyBody = '<head></head><body><p></p></body>';
    if (body === emptyBody) {
      return Promise.reject('본문을 입력하세요');
    }
    const formData = new FormData();
    formData.append('body', body);
    formData.append('post_id', postID);
    formData.append('files', fileURLs);

    let headers = {
      'Content-Type': 'multipart/form-data',
    };
    return Vue.axios
      .post(`/api/v1/comment/`, formData, { headers: headers })
      .then(({ data }) => {
        return data;
      });
  },

  editComment(store, { body, commentID, fileURLs }) {
    const formData = new FormData();
    formData.append('body', body);
    formData.append('files', fileURLs);
    let headers = {
      'Content-Type': 'multipart/form-data',
    };
    return Vue.axios
      .patch(`/api/v1/comment/${commentID}/`, formData, { headers: headers })
      .then(({ data }) => {
        return data;
      });
  },

  deleteComment(store, { commentID }) {
    return Vue.axios.delete(`/api/v1/comment/${commentID}/`);
  },

  commitReply(store, { postID, text, isComment }) {
    if (!text) {
      return Promise.reject('텍스트를 입력하세요');
    }
    const formData = new FormData();
    formData.append('text', text);

    if (isComment === false) {
      formData.append('post', postID);
    } else {
      formData.append('comment', postID);
    }

    let headers = {
      'Content-Type': 'multipart/form-data',
    };
    return Vue.axios
      .post(`/api/v1/reply/`, formData, { headers: headers })
      .then(({ data }) => {
        return data;
      });
  },

  deleteReply(store, { replyID }) {
    return Vue.axios.delete(`/api/v1/reply/${replyID}/`);
  },

  commitVote(store, { postID, isComment }) {
    let key = '';

    if (isComment === false) {
      key = 'post';
    } else {
      key = 'comment';
    }

    return Vue.axios
      .post(`/api/v1/${key}/${postID}/upvote/`)
      .then(({ data }) => {
        return data;
      });
  },

  deleteVote(store, { postID, isComment }) {
    let key = '';

    if (isComment === false) {
      key = 'post';
    } else {
      key = 'comment';
    }

    return Vue.axios
      .post(`/api/v1/${key}/${postID}/downvote/`)
      .then(({ data }) => {
        return data;
      });
  },

  uploadFile(store, { file }) {
    const formData = new FormData();
    formData.append('file', file);

    let headers = {
      'Content-Type': 'multipart/form-data',
    };

    return Vue.axios
      .post('/api/v1/file/', formData, { headers: headers })
      .then(({ data }) => {
        return data;
      });
  },

  fetchUserTags(store, { query }) {
    return Vue.axios.get(`/api/v1/usertag/?query=${query}`).then(({ data }) => {
      return data;
    });
  },

  fetchHashTags(store, { query }) {
    return Vue.axios.get(`/api/v1/hashtag/?query=${query}`).then(({ data }) => {
      return data;
    });
  },

  // fetchSearchPosts(store, { discussionID, competitionID, users, tags }) {
  //     return Vue.axios.get(`/api/v1/post/?${discussionID ? `discussion_id=${discussionID}` : `competition_id=${competitionID}`}&usertags=${users.join()}&hashtags=${tags.join()}`)
  //     .then(({ data }) => {
  //         return data
  //     })
  // },

  fetchNotifications() {
    return Vue.axios.get(`/api/v1/user/notifications/`).then(({ data }) => {
      return data;
    });
  },

  readNotification(store, { notificationID }) {
    return Vue.axios
      .get(`/api/v1/user/notification/${notificationID}/`)
      .then(({ data }) => {
        return data;
      });
  },

  deleteNotifications() {
    return Vue.axios.delete(`/api/v1/user/notifications/`).then(({ data }) => {
      return data;
    });
  },

  deleteNotification(store, { notificationID }) {
    return Vue.axios
      .delete(`/api/v1/user/notification/${notificationID}/`)
      .then(({ data }) => {
        return data;
      });
  },

  fetchTerm() {
    return Vue.axios.get(`/api/v1/user/term/`).then(({ data }) => {
      return data;
    });
  },

  fetchPolicy() {
    return Vue.axios.get(`/api/v1/user/policy/`).then(({ data }) => {
      return data;
    });
  },

  commitTerm(store, { agree }) {
    return Vue.axios
      .get(`/api/v1/user/term_agree/?agree=${agree}`)
      .then(({ data }) => {
        return data;
      });
  },

  commitPolicy(store, { agree }) {
    return Vue.axios
      .get(`/api/v1/user/policy_agree/?agree=${agree}`)
      .then(({ data }) => {
        return data;
      });
  },
};

export default actions;