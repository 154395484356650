<template>
  <v-dialog persistent v-model="signupDialog" width="440px">
    <SignUpDialog></SignUpDialog>
  </v-dialog>
</template>

<script>
import SignUpDialog from '@/components/dialog/SignUpDialog';

export default {
  components: {
    SignUpDialog,
  },

  methods: {
    signupDialog: {
      get: function() {
        return this.$store.state.signupDialog;
      },
      set: function(newValue) {
        this.$store.state.signupDialog = newValue;
      },
    },
  },

  created() {
    this.signupDialog = true;
  },
};
</script>
