<template>
  <div>
    <div class="title-row">
      <div class="title-row-left">
        <div class="post-title">
          {{ post.title }}
        </div>
        <div class="post-detail">
          <div class="post-author">
            posted by {{ post.user.detail.nickname }}
          </div>
          <div class="divider"></div>
          <div class="post-date">
            {{ post.created_at | dateFormat }}
          </div>
          <div class="post-action"
            v-for="(action, index) in actions.filter(
              action => action.active,
            )"
            :key="index"
          >
            <div class="divider"></div>
            <div @click="action.function">{{ action.name }}</div>
          </div>
        </div>
      </div>
      <div class="title-row-right">
        <div class="vote" @click="toggleVote">
          <div class="vote-left">
            <v-icon small color="pink" v-if="voted">mdi-heart</v-icon>
            <v-icon small color="pink" v-else>mdi-heart-outline</v-icon>
            {{ numVotes }}
          </div>
          <div class="vote-right">UP</div>
        </div>
      </div>
    </div>
    <div class="content-row">
      <editor-content class="editor__content" :editor="editor" />
    </div>
  </div>
</template>

<script>
import TagSpan from '@/plugins/TagSpan';
import { Editor, EditorContent } from 'tiptap';
import Constant from '@/store/constant.js';
import javascript from 'highlight.js/lib/languages/javascript';
import python from 'highlight.js/lib/languages/python';
import shell from 'highlight.js/lib/languages/shell';
import dockerfile from 'highlight.js/lib/languages/dockerfile';

import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  CodeBlockHighlight,
  Image,
} from 'tiptap-extensions';

export default {
  props: {
    post: Object,
    isComment: Boolean,
  },

  components: {
    EditorContent,
  },

  watch: {
    post() {
      this.init();
    },
  },

  created() {
    this.init();
  },

  computed: {
    noticeString() {
      return Constant.STRING[this.$store.state.language].NOTICE;
    },
    qaString() {
      return Constant.STRING[this.$store.state.language].QA;
    },
    confirmDeletionString() {
      return Constant.STRING[this.$store.state.language].CONFIRM_DELETION;
    },
    userID() {
      return this.$store.state.userID;
    },
  },

  filters: {
    dateFormat(string) {
      Date.prototype.format = function() {
        let yy = this.getFullYear();
        let mm = this.getMonth() + 1;
        let dd = this.getDate();
        let hour = this.getHours();
        let minute = this.getMinutes();
        return (
          [yy, (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('.') +
          ' ' +
          [(hour > 9 ? '' : '0') + hour, (minute > 9 ? '' : '0') + minute].join(
            ':',
          )
        );
      };
      let date = new Date(string);
      return date.format();
    },

    userTypeFilter(userType) {
      let typeString = '';
      if (userType == 'Student') {
        typeString = '캠퍼';
      } else if (userType == 'Assistant') {
        typeString = 'UStage 조교';
      } else if (userType == 'Teacher') {
        typeString = 'UStage 마스터';
      } else if (userType == 'Admin') {
        typeString = '운영진';
      }
      return `부스트캠프 ${typeString}`;
    },
  },

  methods: {
    init() {
      this.isCompetition = this.$route.name.includes('Task');
      this.editor.setContent(this.post.body);
      this.replies = this.post.replies;

      for (let i = 0; i < this.post.votes.length; i++) {
        if (this.post.votes[i].user == this.userID) {
          this.voted = true;
          break;
        }
      }
      this.numVotes = this.post.votes.length;

      const reg_tag = /<span class=("|')hashtag("|') data-hashtag-id=("|')(.*?)("|')>#(.*?)<\/span>/g;
      this.hashtags = Array.from(this.post.body.matchAll(reg_tag))
        .map(el => ({ id: Number(el[4]), tag: el[6] }))
        .slice(0, 5);
    },
    commitReply() {
      let text = this.reply;
      let postID = this.post.id;
      let isComment = this.isComment;
      this.$store
        .dispatch('commitReply', { postID, text, isComment })
        .then(data => {
          this.replies = data;
        })
        .catch(e => alert(e))
        .finally(() => (this.reply = ''));
    },

    toggleVote() {
      let postID = this.post.id;
      let isComment = this.isComment;

      if (this.voted) {
        this.$store
          .dispatch('deleteVote', { postID, isComment })
          .then(() => {})
          .catch(e => alert(e));
        this.numVotes--;
      } else {
        this.$store
          .dispatch('commitVote', { postID, isComment })
          .then(() => {})
          .catch(e => alert(e));
        this.numVotes++;
      }
      this.voted = !this.voted;
    },

    scrollDown() {
      this.$emit('scrollDownEvent');
    },

    routeToHashtag(text) {
      const default_url = this.$route.path.substring(
        0,
        this.$route.path.lastIndexOf('post') - 1,
      );
      this.$router
        .push({ path: `${default_url}?hashtag=${text}` })
        .catch(() => {});
    },

    editPost() {
      if (this.isCompetition) {
        this.$router.push({ path: `../edit/${this.post.id}`, query: { back: this.$route.path } });
      } else {
        this.$router.push({ name: 'DiscussionEditor' });
      }
    },

    deletePost() {
      if (!confirm(this.confirmDeletionString)) {
        return;
      }

      let postID = this.post.id;
      this.$store
        .dispatch('deletePost', { postID })
        .then(() => {
          this.$destroy();
          this.$el.parentNode.removeChild(this.$el);
          if (this.isCompetition) {
            this.$router.push({ name: 'TaskDiscussionDiscussion' });
          } else {
            this.$router.push({ name: 'Ustage' });
          }
        })
        .catch(e => alert(e));
    },

    editActive() {
      return this.post.user.id == this.$store.state.userID;
    },

    deleteActive() {
      return (
        this.$store.state.userType.toLowerCase() === 'admin' ||
        this.post.user.id == this.$store.state.userID
      );
    },

    actionActive() {
      return this.actions.reduce((acc, current) => {
        return acc.active | current.active;
      });
    },
  },

  data() {
    return {
      isCompetition: false,
      voted: false,
      replies: [],
      reply: '',
      replyActive: false,
      actions: [
        { name: 'Edit', function: this.editPost, active: this.editActive() },
        {
          name: 'Delete',
          function: this.deletePost,
          active: this.deleteActive(),
        },
      ],
      hashtags: [],
      postType: Constant.TYPE.POST,
      editor: new Editor({
        editable: false,
        extensions: [
          new TagSpan(),
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Image(),
          new CodeBlockHighlight({
            languages: {
              javascript,
              python,
              shell,
              dockerfile,
            },
          }),
        ],
      }),
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/sass/editor/main.scss';
@import '@/assets/sass/global.scss';

.title-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;

  .post-title {
    @extend .body-heavy;
  }

  .post-detail {
    display: flex;
    align-items: center;
    @extend .caption-light;
    color: #212121;

    .post-author {
      @extend .caption-heavy;
    }

    .post-action {
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }

  .title-row-right {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }

  .divider {
    margin-left: 8px;
    margin-right: 8px;
    width: 2px;
    height: 16px;
    background: #c4c4c4;
  }
}

.editor__content {
  padding: 0px !important;
}

.vote {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .vote-left {
    text-align: center;
    width: 56px;
    height: 30px;
    border: 2px solid #e84660;
    box-sizing: border-box;
    border-radius: 5px 0px 0px 5px;

    @extend .caption-heavy;
    color: #e84660;
  }
  .vote-right {
    text-align: center;
    width: 40px;
    height: 30px;
    border: 2px solid #e84660;
    background: #e84660;
    border-radius: 0px 5px 5px 0px;

    @extend .caption-heavy;
    color: #ffffff;
  }
}

.circle {
  background-color: #ffffff;
  border-radius: 131px;
  height: 131px;
  width: 131px;
  margin-top: -126px;
}
</style>
