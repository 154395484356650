<template>
  <v-card class="signin-card elevation-0 rounded-xl">
    <div class="signin-wrapper">
      <div class="d-flex justify-space-between">
        <div class="back-link">
          <img
            :src="require('@/assets/images/icons/back_link.svg')"
            @click="popupSignDialog"
          />
        </div>
        <v-btn icon @click="closeClicked">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="signin-title">{{ signinTitle }}</div>
      <v-form>
        <v-text-field
          name="username"
          v-model="username"
          :placeholder="inputEmailString"
          type="text"
          outlined
          hide-details
          height="20"
          class="username"
          :rules="[requiredRule, emailValidation]"
        >
          <template v-slot:prepend-inner>
            <Icon name="login_id" size="normal"></Icon>
          </template>
        </v-text-field>
        <v-text-field
          id="password"
          name="password"
          v-model="password"
          :placeholder="inputPasswordString"
          type="password"
          @keyup.enter="signIn"
          outlined
          height="45px"
          hide-details
          class="password"
          :rules="[requiredRule]"
        >
          <template v-slot:prepend-inner>
            <Icon name="login_pw" size="normal"></Icon>
          </template>
        </v-text-field>
      </v-form>
      <v-btn class="signin-button" @click="signIn">{{ signin }}</v-btn>
    </div>
  </v-card>
</template>

<script>
import Icon from '@/components/Icon';
import Constant from '@/store/constant.js';

export default {
  components: {
    Icon,
  },
  props: {
    source: String,
    redirectTo: String,
  },

  methods: {
    signIn() {
      let username = this.username;
      let password = this.password;
      if (this.validateForm()) {
        this.$store
          .dispatch('signIn', { username, password })
          .then(() => this.redirect())
          .catch(e => {
            console.log('signIn Failed', e);
            alert(this.signinAlertString);
          });
      }
    },

    popupSignDialog() {
      this.signinDialog = false;
      this.$store.commit('setSignDialog', true);
    },

    validateForm() {
      let forms = [
        {
          field: this.emailString,
          value: this.username,
          rules: [this.emailValidation],
        },
        { field: this.passwordString, value: this.password, rules: [] },
      ];
      for (let i = 0; i < forms.length; i++) {
        let form = forms[i];

        for (let j = 0; j < form.rules.length; j++) {
          let rule = form.rules[j];
          if (!rule(form.value)) {
            alert(form.field + this.formatIncorrectString);
            return false;
          }
        }
        if (!form.value) {
          alert(form.field + this.fieldEmptyString);
          return false;
        }
      }
      return true;
    },

    closeClicked() {
      this.signinDialog = false;
      this.$emit('close');
    },

    redirect() {
      this.signinDialog = false;
      if (this.$route.query.redirectTo) {
        this.$router.push(`${this.$route.query.redirectTo}`).catch(() => {});
      } else {
        this.$router.push('/').catch(() => {});
      }
    },

    requiredRule(value) {
      return !!value || 'This field is required';
    },

    emailValidation(value) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(value).toLowerCase());
    },
  },

  computed: {
    signinTitle() {
      return Constant.STRING[this.$store.state.language].SIGNIN_TITLE;
    },
    signin() {
      return Constant.STRING[this.$store.state.language].SIGNIN;
    },
    inputEmailString() {
      return Constant.STRING[this.$store.state.language].INPUT_EMAIL;
    },
    inputPasswordString() {
      return Constant.STRING[this.$store.state.language].INPUT_PASSWORD;
    },
    emailString() {
      return Constant.STRING[this.$store.state.language].EMAIL;
    },
    passwordString() {
      return Constant.STRING[this.$store.state.language].PASSWORD;
    },
    signinAlertString() {
      return Constant.STRING[this.$store.state.language].SIGNIN_ALERT;
    },
    formatIncorrectString() {
      return Constant.STRING[this.$store.state.language].FORMAT_INCORRECT;
    },
    fieldEmptyString() {
      return Constant.STRING[this.$store.state.language].FIELD_EMPTY;
    },
    signinDialog: {
      get: function() {
        return this.$store.state.signinDialog;
      },
      set: function(newValue) {
        this.$store.state.signinDialog = newValue;
      },
    },
  },

  data() {
    return {
      username: '',
      password: '',
      // keepSignin: "로그인 유지하기",
      // findIdPwd: "아이디 / 비밀번호 찾기",
      // oAuth: "간편하게 시작하기",
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';

.signin-card {
  width: 440px;
  height: 362px;

  background: rgba(255, 255, 255, 0.95);
  border-radius: 20px;
}
.back-link {
  img {
    vertical-align: bottom;
  }
  img:hover {
    cursor: pointer;
  }
}
.close-btn {
  margin-bottom: 2px;
}
.signin-title {
  @extend .title-heavy;
  color: #3f3f3f !important;
  margin-bottom: 29px;
}
.signin-wrapper {
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
}
.username {
  margin-bottom: 15px !important;
  width: 380px;
  /* height: 50px; */
  @extend .subheader-light;
}
.password {
  margin-bottom: 15px !important;
  width: 380px;
  /* height: 50px; */
  @extend .subheader-light;
}
.signin-button {
  width: 100%;
  height: 60px !important;
  background: linear-gradient(
    271.1deg,
    #e84660 0.46%,
    rgba(232, 70, 96, 0.85) 100%
  );
  border-radius: 5px;
  margin-bottom: 14px;

  @extend .subheader-heavy;
  color: #ffffff !important;
}
.keep-signin {
  @extend .body-light;
  color: #3f3f3f;
}
.find-id-pwd {
  @extend .body-light;
  color: #3f3f3f;
}
.oauth {
  @extend .body-heavy;
  color: #3f3f3f;
}

.v-text-field {
  &::v-deep .v-input__slot {
    padding: 0 !important;
  }

  &::v-deep .v-input__prepend-inner {
    padding: 0 !important;
    margin: 21px 14px !important;
  }
}
</style>
