var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"postWrapper",class:_vm.isCompetition ? '' : 'post-wrapper',attrs:{"id":"post-wrapper"}},[(_vm.post.user)?_c('div',[_c('CompactPostCard',{attrs:{"post":_vm.post,"isComment":false},on:{"scrollDownEvent":_vm.scrollToBottom}}),_c('hr',{staticClass:"post-divider"}),_c('div',{staticClass:"comments-row"},[_c('div',{staticClass:"comments"},[_vm._v("Comments")]),_c('div',{staticClass:"comments-number"},[_vm._v("("+_vm._s(_vm.comments.length)+")")])]),(_vm.user)?_c('div',{staticClass:"comments-edit-row"},[_c('div',{staticClass:"comments-edit-left"},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","nudge-bottom":"30","nudge-left":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"hidden-xs-only rounded-circle",class:['circle', _vm.user.detail.type.toLowerCase()],attrs:{"src":_vm.user.detail.image_url,"max-width":"48","max-height":"48","width":"48","height":"48"}},'v-img',attrs,false),on))]}}],null,false,1864984376)},[_c('UserCard',{attrs:{"userID":_vm.user.id.toString(),"stateButton":false}})],1)],1),_c('div',{staticClass:"comments-edit-right",on:{"click":function($event){return _vm.$refs.postEditor.editor.view.dom.focus()}}},[_c('PostEditor',{ref:"postEditor",attrs:{"initial-content":"","isComment":true,"active-buttons":[
            'bold',
            'italic',
            'strike',
            'underline',
            'code',
            'paragraph',
            'h1',
            'h2',
            'h3',
            'bullet_list',
            'ordered_list',
            'blockquote',
            'code_block',
            'horizontal_rule',
            'undo',
            'redo',
            'image' ],"submitButton":true},on:{"submit":_vm.commitComment}})],1)]):_vm._e(),_vm._l((_vm.comments),function(comment){return _c('div',{key:comment.id,staticClass:"comments-list"},[_c('CompactCommentCard',{attrs:{"comment":comment,"isComment":true},on:{"delete":_vm.deleteComment}})],1)})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }