<template>
  <div class="inner-container">
    <div v-html="content" class="editor__content"></div>
    <hr class="bottom-line" />
  </div>
</template>

<script>
import katex from "katex";
import { Editor, EditorContent } from 'tiptap';
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Image,
  Table,
	TableHeader,
	TableCell,
	TableRow,
} from 'tiptap-extensions';

export default {
  name: 'TaskInner',
  props: {
    id: String,
    task: Object,
  },
  components: {
    EditorContent,
  },
  watch: {
    task: {
      immediate: true,
      handler() {
        if(this.task) {
          this.setContent();
        }
      }
    },
    $route() {
      this.setContent();
    },
  },
  methods: {
    setContent() {
      const name = this.$route.name;
      this.content = this.task.detail[this.contents[name]] || '';
      this.content = this.content.replaceAll(/\$\$?([^\$]*)\$\$?/g, (a, b) => {
        return `<span class='math'>${katex.renderToString(b, {
          displayMode: true,
        })}</span>`
      });
      
    },
  },
  data() {
    return {
      html: '',
      contents: {
        TaskOverviewCopyright: 'copyright_notice',
        TaskOverviewTotal: 'total_score_evaluation',
        TaskOverviewEvaluation: 'evaluation_methods',
        TaskOverviewDescription: 'overview_description',
        TaskDataDescription: 'data_desc',
      },
      content: '',
      editor: new Editor({
        editable: false,
        editorProps: {
          attributes: {
            class: 'total'
          }
        },
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Image(),
          new Table({
            resizable: false,
          }),
          new TableHeader(),
          new TableCell(),
          new TableRow(),
        ],
      }),
    };
  },
};
</script>
<style scoped lang="scss">
@import '@/assets/sass/global.scss';
.bottom-line {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  margin-top: 72px;
}

.inner-container {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: pre-wrap;
}

* {
  &::v-deep :focus {
    outline: none;
  }
}
.editor__content {
  min-height: 280px;
  &::v-deep .math {
    .katex-display, .katex, .katex-html {
      display: inline;
    }
  }
  &::v-deep p {
    margin: 0px;
    white-space: pre-line;
    text-align: justify;
    @extend .body-light;
  }
  &::v-deep .total {
    background-color: #F7F2F0;
  }

  &::v-deep .tot {
    background-color: #D3C7C0;
  }

  &::v-deep .head {
    td {
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      background-color: #FBF8F7 !important;
      @extend .body-heavy;
    }
  }

  &::v-deep tr {
    text-align: center !important;

    td {
      background-clip: padding-box;
      border: none;
      vertical-align: middle;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      padding: 8px 0 10px 0;
      @extend .body-light;
    }
  }
}
</style>
