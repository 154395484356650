import { render, staticRenderFns } from "./Record.vue?vue&type=template&id=0b8fef6e&scoped=true&"
import script from "./Record.vue?vue&type=script&lang=js&"
export * from "./Record.vue?vue&type=script&lang=js&"
import style0 from "./Record.vue?vue&type=style&index=0&id=0b8fef6e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b8fef6e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
installComponents(component, {VDataTable,VSimpleCheckbox})
