<template>
  <div class="sponsor-container">
    <div class="sponsor-title-row">{{sponsorsTitleString}}</div>
    <div v-for="(innerSponsor, rank) in sponsors" :key="rank" class="sponsor-rank-row">
      <div class="sponsor-subtitle-row">
        <hr class="line" />
        <div class="sponsor-subtitle-text">{{rank}}</div>
        <hr class="line" />
      </div>
      <div class="sponsor-content-row">
        <sponsor-member 
          class="sponsor-content"
          v-for="sponsor in innerSponsor"
          :key="sponsor.name"
          :organization="Object.assign(sponsor, {'rank': rank})"
        ></sponsor-member>
      </div>
    </div>
    <div class="sponsor-title-row">{{dataProvidersTitleString}}</div>
    <div v-for="(innerProvider, rank) in dataProviders" :key="rank" class="sponsor-rank-row">
      <div class="sponsor-subtitle-row">
        <hr class="line" />
        <div class="sponsor-subtitle-text">{{rank}}</div>
        <hr class="line" />
      </div>
      <div class="sponsor-content-row">
        <sponsor-member 
          class="sponsor-content"
          v-for="provider in innerProvider"
          :key="provider.name"
          :organization="Object.assign(provider)"
        ></sponsor-member>
      </div>
    </div>
  </div>
</template>

<script>
import Constant from '@/store/constant.js';
import SponsorMember from '@/components/OrganizationMember.vue';
export default {
  name: 'Sponsor',
  components: { SponsorMember },
  computed: {
    sponsorsTitleString() {
      return Constant.STRING[this.$store.state.language].KLUE_SPONSORS_TITLE;
    },
    dataProvidersTitleString() {
      return Constant.STRING[this.$store.state.language].KLUE_DATA_PROVIDERS_TITLE;
    },
  },
  watch: {
    '$route.name'() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  data() {
    return {
      sponsors: {
        Platinum: [
          {name: 'upstage', text: 'Upstage', url: 'https://www.upstage.ai'},
          {name: 'naver_clova', text: 'Naver Clova', url: 'https://clova.ai/ko'},
          {name: 'google', text: 'Google', url: 'https://www.google.com'},
        ],
        Gold: [
          {name: 'kakao_enterprise', text: 'Kakao Enterprise', url: 'https://www.kakaoenterprise.com'},
        ],
        Silver: [
          {name: 'scatter_lab', text: 'Scatter Lab', url: 'https://scatterlab.co.kr'},
          {name: 'select_star', text: 'Select Star', url: 'https://selectstar.ai'},
        ],
        Bronze: [
          {name: 'riiid', text: 'Riiid', url: 'https://riiid.co'},
          {name: 'deepnatural', text: 'DeepNatural', url: 'https://www.deepnatural.ai'},
          {name: 'kaist', text: 'KAIST', url: 'https://www.kaist.ac.kr'},
        ],
      },
      dataProviders: {
        Providers: [
          {name: 'acrofan', text: 'acrofan', url: 'https://kr.acrofan.com'},
          {name: 'hankyung', text: '한국경제신문', url: 'https://www.hankyung.com'},
        ]
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';

.sponsor-container {
  width: 1084px;
  margin: 0 auto;
  margin-top: 188px;
  margin-bottom: 72px;
}

.sponsor-title-row {
  @extend .subheadline-heavy;
  text-align: center;
  margin-bottom: 16px;
}

.sponsor-rank-row {
  margin: 40px 0;

  .sponsor-subtitle-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .line {
      flex: 1;
    }

    .sponsor-subtitle-text {
      @extend .subheader-heavy;
      margin: 0 90px;
    }
  }

  .sponsor-content-row {
    display: flex;

    .sponsor-content {
      margin-right: 50px;
    }

    .sponsor-content:nth-child(3n) {
      margin-right: 20px !important;
    }
  }
}

</style>