<template>
  <div class="discussion-container">
    <div class="discussion-top-row">
      <v-radio-group v-model="sort" :column="false" hide-details dense>
        <v-radio
          class="radio"
          v-for="key in sorts"
          color="#E84660"
          off-icon="mdi-checkbox-blank-circle"
          on-icon="mdi-check-bold"
          :label="key"
          :key="key"
          @click="page = 1"
        >
        </v-radio>
      </v-radio-group>
      <v-btn 
        width="112" height="32" 
        depressed class="button" 
        @click.native="$router.push({ path: `edit`, query: { back: $route.path } })"
      >{{newPostString}}</v-btn>
    </div>

    <hr class="discussion-divider" />
    <div class="discussions-area">
      <DiscussionRow
        :id="id"
        :post="post"
        :isCompetition="true"
        :to="{ path: `post/${post.id}`, query: { page, sort, back: $route.path } } "
        v-for="post in posts"
        :key="post.id"
      >
      </DiscussionRow>
    </div>

    <div class="pagination-area">
      <v-pagination
        class="pagination"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        v-model="page"
        :length="pageNum"
        :total-visible="7"
      >
      </v-pagination>
    </div>
  </div>
</template>

<script>
import Constant from '@/store/constant.js';
import DiscussionRow from '@/components/row/TaskDiscussionRow.vue';
export default {
  props: {
    id: String,
  },
  components: {
    DiscussionRow,
  },
  data() {
    return {
      posts: [],
      pageSize: 20,
      totalCount: 0,
      sort: 0,
      page: 1,
      sorts: [
        'Recently Posted',
        'Most UP',
        'Most Commented',
      ],
      postType: Constant.TYPE.POST,
      searchText: '',
    };
  },
  computed: {
    isNotice() {
      return this.$route.name.toLowerCase().includes(this.postType.NOTICE);
    },
    type() {
      return this.isNotice ? this.postType.NOTICE : this.postType.QA;
    },
    newPostString() {
      return Constant.STRING[this.$store.state.language].NEW_POST;
    },
    pageNum() {
      let pageNum = parseInt(this.totalCount / this.pageSize);
      if (this.totalCount % this.pageSize > 0) pageNum++;
      return pageNum;
    },
    initialHashtag() {
      return this.$route.query.hashtag;
    },
    initialUsertag() {
      return this.$route.query.usertag;
    },
  },
  watch: {
    page() {
      this.$store.state.listParams.page = this.page;
      this.fetchPosts();
      this.updateQuery();
    },
    sort() {
      this.$store.state.listParams.sort = this.sort;
      this.fetchPosts();
      this.updateQuery();
    },
    searchText() {
      this.fetchPosts();
    },
    '$route.name'() {
      this.fetchPosts();
    }
  },
  methods: {
    search(body) {
      this.searchText = body;
    },

    getUserTagsFromText(text) {
      if (text === '') return [];
      const users = Array.from(text.matchAll(Constant.REG_USER)).map(el =>
        Number(el[4]),
      );
      return users;
    },

    getHashTagsFromText(text) {
      if (text === '') return [];
      const tags = Array.from(text.matchAll(Constant.REG_TAG)).map(el =>
        Number(el[4]),
      );
      return tags;
    },

    fetchPosts() {
      this.posts = [];
      let type = this.type;
      let competition_id = this.id || '';
      let page = this.page;
      let page_size = this.pageSize;
      let sort = this.sorts[this.sort];
      let usertags = this.searchText
        ? this.getUserTagsFromText(this.searchText)
        : [];
      let hashtags = this.searchText
        ? this.getHashTagsFromText(this.searchText)
        : [];
      if (this.sort == 4) {
        usertags.push(this.$store.state.userID);
      }
      usertags = usertags.join();
      hashtags = hashtags.join();

      let data = {
        competition_id,
        page,
        page_size,
        sort,
        usertags,
        hashtags,
        type,
      };

      this.$store
        .dispatch('fetchPosts', data)
        .then(data => {
          let count = data.count;
          let results = data.results;

          this.totalCount = count;
          this.posts = results.map(el => ({
            ...el,
            usertags: Array.from(
              el.body.matchAll(Constant.REG_USER),
            ).map(el => Number(el[4])),
          }));
        })
        .catch(e => alert(e));
    },
    updateQuery() {
      let query = {};
      if (this.$route.query.usertag)
        query['usertag'] = this.$route.query.usertag;
      if (this.$route.query.hashtag)
        query['hashtag'] = this.$route.query.hashtag;
      if (this.sort) query['sort'] = this.sort;
      if (this.page) query['page'] = this.page;
      this.$router.replace({ query: query });
    },
  },
  mounted() {
    this.sort = parseInt(this.$route.query.sort) || 0;
    this.page = parseInt(this.$route.query.page) || 1;
    if (!this.$route.query.hashtag && !this.$route.query.usertag) {
      this.fetchPosts();
    }
    this.$store.state.listParams = {
      page: this.page,
      sort: this.sort,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';
.discussion-container {
  flex: 1;
}

.pagination {
  &::v-deep .v-pagination {
    &__item,
    &__navigation {
      color: #000000 !important;
      background: none !important;
      box-shadow: none;
      border: none;
      &--active {
        color: #e84660 !important;
      }
    }
  }
}
.radio {
  &::v-deep .mdi-checkbox-blank-circle {
    color: #c4c4c4;
  }
  &::v-deep .v-label {
    @extend .caption-light;
    color: #212121 !important;
    margin-left: -4px;
    margin-top: -1px;
    margin-right: 20px;
  }
}

.discussion-top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.discussion-area {
  min-height: 400px;
}

.pagination-area {
  margin-top: 90px;
}

.discussion-divider {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.button {
  background: linear-gradient( 271.1deg, #e84660 0.46%, rgba(232, 70, 96, 0.85) 100%);
  border-radius: 5px;
  letter-spacing: normal !important;
  @extend .body-heavy;
  color: #ffffff;
  text-transform: none !important;
  margin-right: 10px;
}
</style>
