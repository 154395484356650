<template>
  <v-dialog persistent v-model="signinDialog" width="440px">
    <SignInDialog @close="$router.push('/')"></SignInDialog>
  </v-dialog>
</template>

<script>
import SignInDialog from '@/components/dialog/SignInDialog';

export default {
  components: {
    SignInDialog,
  },

  watch: {
    signinDialog: {
      get: function() {
        return this.$store.state.signinDialog;
      },
      set: function(newValue) {
        this.$store.state.signinDialog = newValue;
      },
    },
  },

  created() {
    this.signinDialog = true;
  },

  data() {
    return {
      signinDialog: true,
    };
  },
};
</script>
