var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"leaderboard-container"},[_c('div',{staticClass:"leaderboard-button-row"},_vm._l((_vm.leaderboardSize),function(value,key){return _c('v-btn',{key:key,staticClass:"tab-button",class:_vm.tab === key? 'tab-button-active' : '',attrs:{"width":"96","height":"36","depressed":""},on:{"click":function($event){return _vm.changeTab(key)}}},[_vm._v(_vm._s(value))])}),1),_c('v-data-table',{staticClass:"data-table",attrs:{"hide-default-header":"","headers":_vm.headers,"items":_vm.records,"loading":_vm.loading,"items-per-page":5},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('tr',_vm._l((headers),function(header){return _c('th',{key:header.value},[(header.text.includes('\\'))?_c('div',{staticClass:"header-inner"},[_c('span',[_vm._v(_vm._s(header.text.split('\\')[1]))]),_c('div',{staticClass:"sort"},[_c('img',{class:header.text === _vm.sorting && _vm.ascending ? 'sort-active' : '',attrs:{"src":require('@/assets/images/icons/sort_up.svg'),"width":"6px","height":"6px"},on:{"click":function($event){return _vm.sortRecord(header.text, true)}}}),_c('img',{class:header.text === _vm.sorting && !_vm.ascending ? 'sort-active' : '',attrs:{"src":require('@/assets/images/icons/sort_down.svg'),"width":"6px","height":"6px"},on:{"click":function($event){return _vm.sortRecord(header.text, false)}}})])]):_c('div',[_vm._v(_vm._s(header.text))])])}),0)]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.showPopup(item)}}},[_vm._v(_vm._s(_vm.moreString))])]}},{key:"item.ranking",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ranking"},[_c('div',{staticClass:"ranking-left"}),_c('div',{staticClass:"ranking-content"},[_vm._v(_vm._s(_vm.records.indexOf(item) + 1))])])]}},_vm._l((_vm.headers),function(header){return {key:("item." + (header.text)),fn:function(ref){
var item = ref.item;
return [_c('span',{key:header.text,class:item[header.text].top ? 'top-score' : ''},[_vm._v(_vm._s(item[header.text].value))])]}}}),{key:"item.url",fn:function(ref){
var item = ref.item;
return [(item.url)?_c('a',{attrs:{"target":"_blank","href":item.url}},[_c('img',{attrs:{"src":_vm.url,"width":"16"}})]):_vm._e()]}},{key:"item.downloadURL",fn:function(ref){
var item = ref.item;
return [(item.downloadURL)?_c('a',{attrs:{"target":"_blank","href":item.downloadURL}},[_c('img',{attrs:{"src":_vm.download,"width":"16"}})]):_vm._e()]}}],null,true)}),_c('detail-description-dialog',{ref:"dialog",attrs:{"dialog":_vm.dialog,"content":_vm.popupContent,"isMultiple":false},on:{"close:simple":_vm.closePopup}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }