import Vue from 'vue';
import vueMoment from 'vue-moment';
import App from './App.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import GAuth from 'vue-google-oauth2';
import VueKatex from 'vue-katex';
import 'katex/dist/katex.min.css';

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.status === 401) {
      store.commit('clearAuth');
      router.push('/signin');
    }
    return Promise.reject(error);
  },
);

Vue.use(VueAxios, axios);
Vue.use(vueMoment);
Vue.config.productionTip = false;
Vue.axios.defaults.baseURL = `${process.env.VUE_APP_API_URI}`;
Vue.use(GAuth, {
  clientId: `${process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID}`,
  scope: 'profile email',
});
Vue.use(VueKatex, {
  globalOptions: {
    //... Define globally applied KaTeX options here
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');