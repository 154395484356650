var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"record-container"},[_c('v-data-table',{staticClass:"data-table",attrs:{"headers":_vm.headers,"items":_vm.records,"loading":_vm.loading,"items-per-page":5},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.tag(_vm.task.name) + ' - ' + item.id)+" ")]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.showPopup('Description', item.description, $event)}}},[_vm._v(_vm._s(_vm.moreString))])]}},{key:"item.hyperparameters",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.showPopup('Hyperparameters', item.hyperparameters, $event)}}},[_vm._v(_vm._s(_vm.moreString))])]}},{key:"item.final",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"final"},[_c('span',[_vm._v(_vm._s(_vm.selectString))]),_c('v-simple-checkbox',{attrs:{"value":item.final,"ripple":false,"on-icon":"mdi-checkbox-blank","color":"#E84660"},on:{"click":function($event){return _vm.commitFinal(item)}}})],1)]}}],null,true)}),_c('simple-dialog',{ref:"dialog",attrs:{"dialog":_vm.dialog,"title":_vm.popupTitle,"content":_vm.popupContent},on:{"close:simple":_vm.closePopup}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }