<template>
  <div>
    <v-dialog
      @keydown.esc.prevent="buttonClicked(null)"
      @click:outside="buttonClicked(null)"
      :value="dialog"
      width="440"
    >
      <v-card class="dialog">
        <v-row class="ml-0 mr-0 close-btn">
          <v-col class="pa-0 ml-auto" cols="auto">
            <v-btn icon @click="buttonClicked(null)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <div class="dialog-title">{{ policyString }}</div>
        <div class="dialog-content">
          <div v-html="content" />
        </div>
        <v-row no-gutters v-if="edit">
          <v-col class="mr-2">
            <v-btn @click="buttonClicked(true)" class="policy-btn" depressed>{{
              agreeString
            }}</v-btn>
          </v-col>
          <v-col class="ml-2">
            <v-btn @click="buttonClicked(false)" class="policy-btn" depressed>{{
              diasgreeString
            }}</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Constant from '@/store/constant.js';
export default {
  name: 'PolicyDialog',
  props: {
    dialog: Boolean,
    content: String,
    edit: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    buttonClicked(value) {
      this.$emit('close:policy', value);
    },
  },

  computed: {
    policyString() {
      return Constant.STRING[this.$store.state.language].POLICY;
    },
    agreeString() {
      return Constant.STRING[this.$store.state.language].AGREE;
    },
    diasgreeString() {
      return Constant.STRING[this.$store.state.language].DISAGREE;
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/assets/sass/global.scss';
.dialog {
  border-radius: 20px;
  padding: 30px;
}
.dialog-top {
  @extend .subheader-heavy;
  color: #3f3f3f;
  display: flex;
  justify-content: end;
}

.dialog-title {
  text-transform: capitalize;
  margin-top: 10px;
  @extend .title-heavy;
}

.dialog-content {
  margin-top: 10px;
  margin-bottom: 25px;
  padding: 10px;
  border: 1px solid #b0b0b0;
  box-sizing: border-box;
  max-height: 650px;
  overflow-y: auto;
  @extend .caption-light;
}

.dialog-checkbox {
  margin-bottom: 25px;
}

.policy-btn {
  width: 100%;
  height: 45px !important;
  background: linear-gradient(
    271.1deg,
    #e84660 0.46%,
    rgba(232, 70, 96, 0.85) 100%
  );
  border-radius: 5px;
  @extend .body-heavy;
  color: #ffffff !important;
}
</style>
