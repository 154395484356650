<template>
  <v-dialog v-model="dialog" width="350">
    <v-card class="pt-5 pb-5">
      <v-row>
        <v-col></v-col>
        <v-col>
          <v-progress-circular
            :size="120"
            :width="7"
            color="green"
            indeterminate
          ></v-progress-circular>
        </v-col>
        <v-col></v-col>
      </v-row>

      <v-card-text align="center">
        <h2>{{ message }}</h2>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ProgressCircular',
  props: {
    dialog: Boolean,
    message: String,
  },
};
</script>
