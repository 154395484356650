<template>
  <div class="team-container" ref="teamContainer">
    <div class="team-title">{{teamTitleString}}</div>
    <div class="team-subtitle-row">
      <div>{{researcherString}}</div>
      <div class="team-update">ver. 2021.05.18</div>
    </div>
    <v-divider class="divider"></v-divider>
    <div class="team-detail-row">
      <team-member 
        v-for="member in researchers" 
        :key="member.name"
        :member="member"
      ></team-member>
    </div>
    <div class="team-subtitle-row">{{authorString}}</div>
    <v-divider class="divider"></v-divider>
    <div class="team-detail-row">
      <team-member 
        v-for="member in authors" 
        :key="member.name"
        :member="member"
      ></team-member>
    </div>
  </div>
</template>

<script>
import Constant from '@/store/constant.js';
import TeamMember from '@/components/TeamMember.vue';
export default {
  name: 'Team',
  components: { TeamMember },
  computed: {
    teamTitleString() {
      return Constant.STRING[this.$store.state.language].KLUE_TEAM_TITLE;
    },
    researcherString() {
      return Constant.STRING[this.$store.state.language].KLUE_RESEARCHERS;
    },
    authorString() {
      return Constant.STRING[this.$store.state.language].KLUE_AUTHORS;
    },
  },
  watch: {
    '$route.name'() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    window.scrollTo (0, 0);
  },
  data() {
    return {
      researchers: [
        {name: 'Sungjoon Park', position: 'Project Manager', mail: 'sungjoon.park@upstage.ai', belong: 'Upstage', studying: false, link: {web: 'https://sungjoonpark.github.io/', linkedin: 'http://linkedin.com'}},
        {name: 'Jihyung Moon', position: 'Project Manager', mail: 'jihyung.moon@upstage.ai', belong: 'Upstage', studying: false, link: {web: 'https://inmoonlight.github.io/about/', twitter: 'https://twitter.com/jihyung_moon'}},
        {name: 'Sungdong Kim', position: 'Data and Model PIC', mail: 'sungdong.kim@navercorp.com', belong: 'Naver Corp.', studying: false, link: {web: 'https://github.com/dsksd'}},
        {name: 'Won Ik Cho', position: 'Data Manager (Semantics)', mail: 'tsatsuki@snu.ac.kr', belong: 'Seoul National University',  studying: true, link: {web: 'https://sites.google.com/site/warnikchow', twitter: 'https://twitter.com/warnikchow'}},
        {name: 'Jiyoon Han', position: 'Data Manager (Syntax)', mail: 'clinamen35@yonsei.ac.kr', belong: 'Yonsei University',  studying: true, link: {}},
        {name: 'Jangwon Park', position: 'Pretraining PIC', mail: 'jangwon.pk@gmail.com', belong: 'Kookmin Bank',  studying: false, link: {web: 'https://github.com/monologg'}},
        {name: 'Chisung Song', position: 'NER task PIC', mail: 'daydrilling@gmail.com', belong: 'NCSOFT', studying: false, link: {web: 'https://github.com/daydrill'}},
        {name: 'Junseong Kim', position: 'MRC task PIC', mail: 'junseong.kim@scatterlab.co.kr', belong: 'Scatter Lab', studying: false, link: {web: 'https://junseong.oopy.io/introduction', facebook: 'https://facebook.com/codertimo'}},
        {name: 'Yongsook Song', position: 'TC task PIC', mail: 'youngsoksong@khu.ac.kr', belong: 'KyungHee University', studying: true, link: {web: 'https://github.com/songys'}},
        {name: 'Taehwan Oh', position: 'DP task PIC', mail: 'ghksl0604@yonsei.ac.kr', belong: 'Yonsei University', studying: true, link: {}},
        {name: 'Joohong Lee', position: 'RE task PIC', mail: 'joohong@scatterlab.co.kr', belong: 'Scatter Lab', studying: false,link: {web: 'https://roomylee.github.io/', facebook: 'https://www.facebook.com/roomylee/'}},
        {name: 'Juhyun Oh', position: 'NLI / NER team', mail: '411juhyun@snu.ac.kr', belong: 'Seoul National University', studying: true, link: {}},
        {name: 'Sungwon Lyu', position: 'Pretraining team', mail: 'james.ryu@kakaoenterprise.com', belong: 'Kakao Enterprise', studying: false, link: {linkedin: 'https://www.linkedin.com/in/lyusungwon/'}},
        {name: 'Younghoon Jeong', position: '', mail: 'boychaboy@sogang.ac.kr', belong: 'Sogang University', studying: true, link: {web: 'https://boychaboy.github.io'}},
        {name: 'Inkwon Lee', position: 'Pretraining / DP team', mail: 'md98765@naver.com', belong: 'Sogang University', studying: true, link: {web: 'https://github.com/inkoon'}},
        {name: 'Sangwoo Seo', position: 'RE team', mail: 'sangwoo@scatterlab.co.kr', belong: 'Scatter Lab', studying: false, link: {web: 'https://github.com/SeoSangwoo'}},
        {name: 'Dongjun Lee', position: 'Fine-Tuning / STS Team', mail: 'humanbrain.djlee@gmail.com', belong: 'LBox', studying: false, link: {web: 'https://dongjunlee.github.io/', linkedin: 'https://www.linkedin.com/in/dongjun-lee/'}},
        {name: 'Hyunwoo Kim', position: 'MRC team', mail: 'hyunw.kim@vl.snu.ac.kr', belong: 'Seoul National University', studying: true, link: {web: 'https://hyunw.kim', twitter: 'https://twitter.com/hyunw_kim_'}},
        {name: 'Myeonghwa Lee', position: 'STS / TC team', mail: 'myeon9h@kaist.ac.kr', belong: 'KAIST', studying: true, link: {web: 'https://github.com/myeonghwa-lee', linkedin: 'https://www.linkedin.com/in/myeonghwa-lee-a840a420a/'}},
        {name: 'Seongbo Jang', position: 'RE team', mail: 'seongbo@scatterlab.co.kr', belong: 'Scatter Lab', studying: false, link: {web: 'https://sb-jang.github.io/', linkedin: 'https://www.linkedin.com/in/seongbojang-684839212'}},
        {name: 'Seungwon Do', position: 'TOD team', mail: 'seungwon.do1@gmail.com', belong: 'ETRI', studying: false, link: {web: 'https://dodoseung.github.io', twitter: 'https://twitter.com/seungwon_do'}},
        {name: 'Sunkyoung Kim', position: 'RE / MRC team', mail: 'sunkyoung@kaist.ac.kr', belong: 'KAIST', studying: true, link: {web: 'https://github.com/Sunkyoung',linkedin: 'http://linkedin.com/in/sunkyoung-kim-0a4aa5167'}},
        {name: 'Kyungtae Lim', position: 'DP task (model)', mail: 'ktlim@hanbat.ac.kr', belong: 'Hanbat National University', studying: false, link: {web: 'https://sites.google.com/view/aailab/', blog: 'https://blog.naver.com/jujbob'}},
        {name: 'Jongwon Lee', position: 'TOD Team', mail: 'jay722.lee@samsung.com', belong: 'Samsung Electronics', studying: false, link: {web: 'https://github.com/jongwon-jay-lee', linkedin: 'https://www.linkedin.com/in/jongwon-lee-5474a17b/'}},
        {name: 'Kyumin Park', position: 'TOD team', mail: 'pkm9403@kaist.ac.kr', belong: 'KAIST', studying: true, link: {web: 'https://github.com/Kyumin-Park', linkedin: 'https://www.linkedin.com/in/kyumin-park-6b2431195/'}},
        {name: 'Jay Shin', position: 'TOD team', mail: 'jshin49@gmail.com', belong: 'Riiid!', studying: false, link: {linkedin: 'https://www.linkedin.com/in/jayshin94/'}},
        {name: 'Seonghyun Kim', position: 'NER team', mail: 'bananaband657@gmail.com', belong: 'Smilegate', studying: false, link: {web: 'https://github.com/MrBananaHuman', facebook: 'https://www.facebook.com/qksksk657/'}},
        {name: 'Lucy Park', position: 'MRC team', mail: 'lucy@upstage.ai', belong: 'Upstage', studying: false, link: {web: 'https://lucypark.kr', twitter: 'https://twitter.com/echojuliett'}},
      ],
      authors: [
        {name: 'Alice Oh', position: 'Advisor', mail: 'alice.oh@kaist.edu', belong: 'KAIST', studying: false, link: {web: 'https://aliceoh9.github.io/'}},
        {name: 'Jung-Woo Ha', position: 'Advisor', mail: 'jungwoo.ha@navercorp.com', studying: false, belong: 'Naver Corp.', link: {web: 'https://github.com/jungwoo-ha', facebook: 'https://www.facebook.com/jungwoo.ha.921/'}},
        {name: 'Kyunghyun Cho', position: 'Advisor', mail: 'kyunghyun.cho@nyu.edu', studying: false, belong: 'New York University', link: {web: 'https://kyunghyuncho.me/'}},
      ]
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';

.divider {
  margin-top: 8px;
  border: 1px solid #717171;
}

.team-container {
  width: 1084px;
  margin: 0 auto;
  margin-top: 188px;
  margin-bottom: 72px;
}

.team-title {
  @extend .biggest-heavy;
  margin-bottom: 32px;
}

.team-subtitle-row {
  @extend .subheadline-heavy;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .team-update {
    @extend .body-light;
    background-color: white;
  }
}

.team-detail-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 72px;
}
</style>