<template>
  <div v-if="barActive">
    <v-app-bar id="home-app-bar" app color="white" height="64px">
      <div class="app-bar">
        <button
          @click="routeToTab(items[0])"
          style="padding: 0; border: none; background: none"
        >
          <v-img
            :src="require('@/assets/klue_logo.png')"
            contain
            max-width="77"
            class="logo"
          />
        </button>

        <v-tabs v-model="tab" background-color="white" hide-slider>
          <v-tab
            v-for="(item, i) in items"
            :key="i"
            @click="routeToTab(item)"
            :ripple="false"
            active-class="tab-text-selected"
            class="tab-text"
            min-width="90"
            text
            :disabled="!item.active"
          >
            {{ item.name }}
          </v-tab>
        </v-tabs>

        <div class="signinup-text" v-if="userImageURL === null">
          <a
            style="text-decoration: none; color: inherit; cursor: pointer;"
            @click="popupSignDialog(0)"
          >
            {{ signinString }}
          </a>
          <span class="mr-4 ml-4"> | </span>
          <a
            style="text-decoration: none; color: inherit; cursor: pointer;"
            @click="popupSignDialog(1)"
          >
            {{ signupString }}
          </a>
        </div>
        <div v-else>
          <div class="user-info">
            <div class="user-image">
              <v-img
                :src="userImageURL"
                class="hidden-xs-only rounded-circle profile-image"
                max-width="33"
                max-height="33"
                height="33"
                width="33"
              />
            </div>
            <div class="user-text">
              <v-menu
                :close-on-content-click="false"
                offset-y
                nudge-bottom="30"
                nudge-left="150"
              >
                <template v-slot:activator="{ on, attrs }">
                  <button
                    v-bind="attrs"
                    v-on="on"
                    class="profile-text"
                    style="padding: 0; border: none; background: none"
                  >
                    <div class="username-text">
                      {{ nickname
                      }}<v-icon size="15" color="#C4C4C4"
                        >mdi-chevron-down</v-icon
                      >
                    </div>
                  </button>
                </template>

                <UserCard
                  :userID="userID.toString()"
                  :stateButton="true"
                ></UserCard>
              </v-menu>
            </div>
          </div>
        </div>

        <v-dialog v-model="signinDialog" width="440px">
          <SignIn></SignIn>
        </v-dialog>
        <v-dialog v-model="signupDialog" width="440px">
          <SignUp></SignUp>
        </v-dialog>
        <v-dialog v-model="signDialog" width="440px">
          <SignDialog></SignDialog>
        </v-dialog>
      </div>
    </v-app-bar>
    <v-app-bar
      v-if="more"
      id="home-app-bar-more"
      fixed
      color="white"
      height="50px"
    >
      <div class="app-bar">
        <v-tabs v-model="participant" color="#E84661" centered optional>
          <v-tabs-slider style="height: 4px"></v-tabs-slider>
          <v-tab
            v-for="(item, i) in participants"
            :key="i"
            @click="routeToTab(item)"
            :ripple="false"
            exact
            active-class="tab-text-selected"
            class="tab-text"
            min-width="90"
            text
            :disabled="!item.active"
          >
            {{ item.text }}
          </v-tab>
        </v-tabs>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import SignIn from '@/components/dialog/SignInDialog';
import SignUp from '@/components/dialog/SignUpDialog';
import UserCard from '@/components/card/UserCard';
import SignDialog from '@/components/dialog/SignDialog';
import Icon from '@/components/Icon';
import Constant from '@/store/constant.js';

export default {
  name: 'AppBar',

  components: {
    SignIn,
    SignUp,
    SignDialog,
    UserCard,
    Icon,
  },

  watch: {
    '$route.name': {
      immediate: true,
      handler () {
        let participant = this.participants.findIndex(el => el.name === this.$route.name);
        if(participant === -1) {
          this.tab = this.items.findIndex(el => this.$route.name.includes(el.name));
        } else {
          this.tab = 1;
          this.more = true;
          this.participant = participant;
        }
      }
      
    },
  },

  computed: {
    signinString() {
      return Constant.STRING[this.$store.state.language].SIGNIN;
    },
    signupString() {
      return Constant.STRING[this.$store.state.language].SIGNUP;
    },
    newNotificationsString() {
      return Constant.STRING[this.$store.state.language].NEW_NOTIFICATIONS;
    },
    deleteNotificationsString() {
      return Constant.STRING[this.$store.state.language].DELETE_NOTIFICATIONS;
    },
    notificationTaggedString() {
      return Constant.STRING[this.$store.state.language].NOTIFICATION_TAGGED;
    },
    notificationCommentString() {
      return Constant.STRING[this.$store.state.language].NOTIFICATION_COMMENT;
    },
    notificationQAString() {
      return Constant.STRING[this.$store.state.language].NOTIFICATION_QA;
    },
    poweredByString() {
      return Constant.STRING[this.$store.state.language].POWERED_BY;
    },
    teamString() {
      return Constant.STRING[this.$store.state.language]
        .KLUE_TEAM;
    },
    sponsorsString() {
      return Constant.STRING[this.$store.state.language]
        .KLUE_SPONSORS;
    },
    organizersString() {
      return Constant.STRING[this.$store.state.language]
        .KLUE_ORGANIZERS;
    },
    participants() {
      return [
        {
          text: this.teamString,
          name: 'Team',
          active: true,
        },
        {
          text: this.sponsorsString,
          name: 'Sponsor',
          active: true,
        },
        { 
          text: this.organizersString, 
          name: 'Organizer', 
          active: true 
        },
      ];
    },

    barActive() {
      return this.$route.path !== '/signin' && this.$route.path !== '/signup';
    },
    userImageURL() {
      return this.$store.state.userImageURL;
    },
    username() {
      return this.$store.state.username;
    },
    userID() {
      return this.$store.state.userID;
    },
    nickname() {
      return this.$store.state.nickname;
    },
    signinDialog: {
      get: function() {
        return this.$store.state.signinDialog;
      },
      set: function(newValue) {
        this.$store.state.signinDialog = newValue;
      },
    },
    signupDialog: {
      get: function() {
        return this.$store.state.signupDialog;
      },
      set: function(newValue) {
        this.$store.state.signupDialog = newValue;
      },
    },
    signDialog: {
      get: function() {
        return this.$store.state.signDialog;
      },
      set: function(newValue) {
        this.$store.commit('setSignDialog', newValue);
      },
    },
  },

  data: () => ({
    items: [
      { name: 'Home', active: true },
      { name: 'Participants', active: true },
      { name: 'Paper', link: 'https://arxiv.org/pdf/2105.09680.pdf', active: true },
      { name: 'Task', active: true },
      { name: 'Leaderboard', active: true },
      { name: 'Issue Report', link: 'https://github.com/KLUE-benchmark/KLUE', active: true },
    ],
    tab: 0,
    participant: 0,
    more: false,
    // signinDialog: false,
  }),

  methods: {
    routeToTab(item) {
      if(item.name === 'Participants') {
        this.more = true;
        this.$router.push(this.participants[0]);
        return;
      }
      if(!this.participants.find(el => el.name === item.name)) this.more = false;
      
      if('link' in item) {
        window.open(item.link, '_blank');
        return;
      }
      this.$router.push(item);
    },
    popupSignDialog(activeTab) {
      this.$store.commit('setSignDialogActiveTab', { activeTab });
      this.$store.commit('setSignDialog', true);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';

.app-bar {
  width: 1084px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

#home-app-bar {
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  width: 100%;

  &::v-deep .v-toolbar__content {
    padding: 4px 0 !important;
  }

  &::v-deep .v-tab {
    @extend .body-heavy;
    padding: 0 22px;
  }
}

#home-app-bar-more {
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  top: 64px;
  border-top: 1px solid #919191 !important;

  &::v-deep .v-toolbar__content {
    padding: 4px 0 !important;
  }

  .v-tabs {
    &::v-deep .v-tabs-slider-wrapper {
      height: 4px !important;
    }
    .v-tab {
      padding: 0 32px;
      min-width: 120px;
      text-transform: none !important;
      @extend .body-heavy;
      &:not(&--active) {
        color: #212121 !important;
      }
    }
  }
}

button:focus {
  outline: none;
}

.logo {
  margin-right: 84px;
}

.v-tabs {
  .tab-text {
    @extend .subheader-heavy;

    color: #212121 !important;
    text-transform: none !important;
    letter-spacing: 0.1px;
  }

  .tab-text-selected {
    color: #e84660 !important;
  }

  .v-tab--active.v-tab:not(:focus)::before {
    border-radius: 5px;
  }
}


::v-deep .v-window__container {
  transition: none;
}

.signinup-text {
  display: flex;
  justify-content: end;
  @extend .caption-heavy;

  color: rgba(0, 0, 0, 0.6) !important;
  text-decoration: none !important;
  min-width: 150px;
}

.user-info {
  display: flex;
  justify-content: center;
  align-items: center;

  .user-text {
    margin-left: 18px;
    display: flex;
  }

  .username-text {
    white-space: nowrap;
    display: flex;
    align-items: center;
    @extend .mid-heavy;
    color: rgba(0, 0, 0, 0.68);

    i {
      margin-left: 4px;
    }
  }
}


</style>
