<template>
  <div class="home-container">
    <div class="home-logo">
      <v-img
        :src="require('@/assets/klue_text.svg')"
        contain
        max-width="197"
        class="logo"
      />
    </div>
    <div class="home-title">Korean Language Understanding Evaluation</div>
    <div class="home-content">
      Korean Language Understanding Evaluation (KLUE) benchmark is a series of datasets to evaluate natural language understanding capability of Korean language models. KLUE consists of 8 diverse and representative tasks, which are accessible to anyone without any restrictions. With ethical considerations in mind, we deliberately design annotation guidelines to obtain unambiguous annotations for all datasets. Furthermore, we build an evaluation system and carefully choose evaluations metrics for every task, thus establishing fair comparison across Korean language models. 
    </div>
  </div>
</template>

<script>
import Constant from '@/store/constant.js';
export default {
  name: 'Home',

  provide: {
    theme: { isDark: false },
  },

  computed: {
    userType() {
      return localStorage.getItem('userType');
    },
    startString() {
      return Constant.STRING[this.$store.state.language].KLUE_GUIDE_START;
    }
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';

.home-container {
  width: 1084px;
  margin: 0 auto;
}

.home-logo {
  margin-bottom: 40px;
}
.home-title {
  @extend .subheadline-heavy;
  margin-bottom: 16px;
}
.home-content {
  width: 836px;
  text-align: justify;
  @extend .body-light;
  line-height: 28px;
  color: #515151;
}

.button {
  background: linear-gradient( 271.1deg, #e84660 0.46%, rgba(232, 70, 96, 0.85) 100%);
  border-radius: 5px;
  letter-spacing: normal !important;
  @extend .subheader-heavy;
  color: #ffffff;
  text-transform: none !important;
}


</style>
