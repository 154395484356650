<template>
  <div class="tasks-container">
    <div class="tasks-title-row">
      <div class="tasks-title">{{klueTaskListString}}</div>
    </div>
    <div class="tasks-content-row" v-if="multipleTasks">
      <div class="tasks-content-title">{{klueTaskTotalString}}</div>
      <hr />
      <task-row 
        class="tasks-content"
        v-for="task in multipleTasks"
        :key="task.name"
        :task="task"
      ></task-row>
    </div>
    <div class="tasks-content-row" v-if="tasks">
      <div class="tasks-content-title">{{klueTaskIndividualString}}</div>
      <hr />
      <task-row 
        class="tasks-content tasks-content-bottom"
        v-for="task in tasks"
        :key="task.name"
        :task="task"
      ></task-row>
    </div>
  </div>
</template>

<script>
import TaskRow from '@/components/row/TaskRow.vue';
import Constant from '@/store/constant.js';
export default {
  components: {
    TaskRow,
  },
  computed: {
    klueTaskListString() {
      return Constant.STRING[this.$store.state.language].KLUE_TASK_LIST;
    },
    klueTaskString() {
      return Constant.STRING[this.$store.state.language].KLUE_TASK_TEXT;
    },
    klueTaskTotalString() {
      return Constant.STRING[this.$store.state.language].KLUE_TASK_TOTAL;
    },
    klueTaskIndividualString() {
      return Constant.STRING[this.$store.state.language].KLUE_TASK_INDIVIDUAL;
    },
  },
  mounted() {
    this.$store
      .dispatch('fetchTasks')
      .then(() => {
        let tasks = this.$store.state.tasks.map((el, idx) => {
          let etc = JSON.parse(el.etc);
          delete el.etc;
          return {
            ...el, 
            idx: idx,
            data_url: etc.data,
            lead: etc.lead, 
            metric: etc.metric, 
            members: etc.members,
            dataset: etc.dataset,
          }
        });
        this.tasks = tasks.filter(el => !el.is_multiple);
        this.multipleTasks = tasks.filter(el => el.is_multiple);
      })
      .catch(e => alert(e));
  },
  data() {
    return {
      multipleTasks: [],
      tasks: []
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/global.scss';
.tasks-container {
  width: 1084px;
  margin: 0 auto;
  margin-top: 144px;
  margin-bottom: 72px;
}

.tasks-title-row {
  margin-bottom: 40px;
  .tasks-title {
    @extend .biggest-heavy;
    margin-bottom: 16px;
  }

  .tasks-desc {
    text-align: justify;
    @extend .body-light;
  }
}

.tasks-content-row {
  margin-bottom: 46px;
  .tasks-content-title {
    @extend .subheadline-heavy;
    margin-bottom: 16px;
  }

  hr {
    border: 1px solid #717171;
  }
}

.tasks-content {
  &:hover {
    background-color: #F7F2F0;
  }
}
.tasks-content-bottom {
  border-bottom: 1px solid #717171;
}
</style>