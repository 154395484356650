<template>
  <div>
    <v-dialog
      ref="dialog"
      hide-overlay
      @keydown.esc.prevent="buttonClicked(null)"
      @click:outside="buttonClicked(null)"
      :value="dialog"
      width="500"
    >
      <v-card class="dialog">
        <div class="dialog-top">
          <div class="dialog-title">{{ title }}</div>
          <v-icon @click="buttonClicked(null)">mdi-close</v-icon>
        </div>
        <div class="dialog-content">{{ content }}</div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'SimpleDialog',
  props: {
    dialog: Boolean,
    title: String,
    content: String,
  },

  methods: {
    buttonClicked(value) {
      this.$emit('close:simple', value);
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/global.scss';
.dialog {
  padding: 30px;
  background: #FFFFFF;
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.dialog-top {
  color: #3f3f3f;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialog-title {
  @extend .subheader-heavy;
}

.dialog-content {
  margin-top: 25px;
  padding: 14px 16px 9px 23px;
  background: #F6F6F6;
  max-height: 300px;
  overflow-y: auto;
  @extend .subheader-light;
}
</style>