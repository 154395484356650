<template>
  <div
    class="task-detail-container"
    ref="pageWrapper"
  >
    <div
      class="back"
      @click="$router.push({ path: '/tasks' })"
      style="cursor: pointer;"
    >
      <v-icon size="medium">mdi-chevron-left</v-icon>
      <span style="font-size: 12px;">Back to List</span>
    </div>
    
    <task-cover-row v-if="task" :task="task"></task-cover-row> 
    
    <div class="task-tab">
      <v-tabs v-model="tab" color="#E84661">
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          v-for="(item, i) in tabs"
          :key="i"
          @click="routeToTab(item.name)"
          :disabled="item.disabled"
        >
          {{ item.text }}
        </v-tab>
      </v-tabs>
    </div>
    <div class="content">
      <div class="sidebar">
        <v-list>
          <v-list-item-group v-model="innerTab" mandatory>
            <v-list-item
              v-for="(item, i) in tabs[tab].tabs.filter(el => el.active)"
              :key="i"
              :disabled="!item.active"
              @click="routeToTab(`Task${tabs[tab].name}${item.name}`)"
              >{{ item.text }}</v-list-item
            >
          </v-list-item-group>
        </v-list>
      </div>
      <router-view :task="task"></router-view>
    </div>
  </div>
</template>

<script>
import Constant from '@/store/constant.js';
import TaskCoverRow from '@/components/row/TaskCoverRow.vue';
export default {
  name: 'TaskDetail',

  props: {
    id: String,
  },

  components: {
    TaskCoverRow
  },

  computed: {
    overviewString() {
      return Constant.STRING[this.$store.state.language].OVERVIEW;
    },
    dataString() {
      return Constant.STRING[this.$store.state.language].DATA;
    },
    submissionString() {
      return Constant.STRING[this.$store.state.language].SUBMISSION;
    },
    myRecordString() {
      return Constant.STRING[this.$store.state.language].MY_RECORD;
    },
    leaderboardString() {
      return Constant.STRING[this.$store.state.language].LEADERBOARD;
    },
    qaString() {
      return Constant.STRING[this.$store.state.language].QA;
    },
    totalScoreEvaluationString() {
      return Constant.STRING[this.$store.state.language].TOTAL_SCORE_EVALUATION;
    },
    overviewDescriptionString() {
      return Constant.STRING[this.$store.state.language].OVERVIEW_DESCRIPTION;
    },
    evaluationMethodsString() {
      return Constant.STRING[this.$store.state.language].EVALUATION_METHODS;
    },
    totalScoreEvaluationString() {
      return Constant.STRING[this.$store.state.language].TOTAL_SCORE_EVALUATION;
    },
    copyrightNoticeString() {
      return Constant.STRING[this.$store.state.language].COPYRIGHT_NOTICE;
    },
    dataDescriptionString() {
      return Constant.STRING[this.$store.state.language].KLUE_DATA_DESCRIPTION;
    },
    dataDownloadString() {
      return Constant.STRING[this.$store.state.language].KLUE_DATA_DOWNLOAD;
    },
    newSubmissionString() {
      return Constant.STRING[this.$store.state.language].KLUE_NEW_SUBMISSION;
    },
    mySubmissionString() {
      return Constant.STRING[this.$store.state.language].KLUE_MY_SUBMISSION;
    },
    jobLogString() {
      return Constant.STRING[this.$store.state.language].KLUE_JOB_LOG;
    },
    taskRecordString() {
      return Constant.STRING[this.$store.state.language].TASK_RECORD;
    },
    taskLeaderboardString() {
      return Constant.STRING[this.$store.state.language].TASK_LEADERBOARD;
    },
    leaderboardDescriptionString() {
      return Constant.STRING[this.$store.state.language].LEADERBOARD_DESCRIPTION;
    },
    noticeString() {
      return Constant.STRING[this.$store.state.language].NOTICE;
    },
    tabs() {
      return [
        { 
          text: this.overviewString, 
          name: 'Overview', 
          disabled: false, 
          tabs: [{
            name: 'Description',
            text: this.overviewDescriptionString,
            active: true,
          },
          {
            name: 'Evaluation',
            text: this.evaluationMethodsString,
            active: true,
          },
          {
            name: 'Copyright',
            text: this.copyrightNoticeString,
            active: true,
          }]
        },
        { 
          text: this.dataString, 
          name: 'Data', 
          disabled: false,
          tabs: [{
            name: 'Description',
            text: this.dataDescriptionString, 
            active: !this.task || (this.task && this.task.detail.data_desc),
          }, 
          {
            name: 'Download',
            text: this.dataDownloadString, 
            active: true,
          }] 
        },
        { 
          text: this.submissionString, 
          name: 'Submission', 
          disabled: !this.$store.state.user, 
          tabs: [{
            name: 'New', 
            text: this.newSubmissionString, 
            active: true,
          }, 
          {
            name: 'My', 
            text: this.mySubmissionString, 
            active: true,
          }, 
          {
            name: 'Log', 
            text: this.jobLogString, 
            active: true,
          }]
        },
        { 
          text: this.myRecordString, 
          name: 'Record', 
          disabled: !this.$store.state.user,
          tabs: [{
            name: 'Task',
            text: this.taskRecordString,
            active: true,
          }]
        },
        { 
          text: this.leaderboardString, 
          name: 'Leaderboard', 
          disabled: false,
          tabs: [{
            name: 'Description',
            text: this.leaderboardDescriptionString,
            active: this.task && this.task.is_multiple,
          },
          {
            name: 'Task',
            text: this.taskLeaderboardString,
            active: this.task && !this.task.is_multiple,
          }]
        },
        { 
          text: this.qaString, 
          name: 'Discussion', 
          disabled: !this.$store.state.user,
          tabs: [{
            name: 'Notice',
            text: this.noticeString,
            active: true,
          }, 
          {
            name: 'Discussion',
            text: this.qaString,
            active: true,
          }]
        },
      ];
    },
  },

  watch: {
    '$route.name': {
      immediate: true, 
      handler() {
        if (this.$route.name === 'TaskDetail') {
          this.$router.replace({ name: 'TaskOverviewDescription' });
          this.innerTab = 0;
          return;
        }
        this.tab = this.tabs.findIndex(el => this.$route.name.includes(el.name));
        this.innerTab = this.tabs[this.tab].tabs.findIndex(el => this.$route.name.includes(el.name));
      }
    }
  },

  methods: {
    routeToTab(name) {
      for(let tab of this.tabs) {
        if(name === tab.name) {
          this.$router.push({ name: `Task${tab.name}${tab.tabs.filter(el => el.active)[0].name}`});
          return;
        }
      }
      this.$router.push({name});
    }
  },

  mounted() {
    let id = Number.parseInt(this.id);
    this.$store.dispatch('fetchTaskDetail', id).then(() => {
      let task = this.$store.state.taskDetail[id];
      
      let etc = JSON.parse(task.etc);
      delete task.etc;
      
      this.task = {
        ...task, 
        lead: etc.lead, 
        metric: etc.metric, 
        members: etc.members,
        dataset: etc.dataset,
        code: etc.code
      };

      let detail = this.task.detail;
      
      etc = JSON.parse(detail.etc);
      delete detail.etc;

      this.task.detail = {
        ...this.task.detail,
        total_score_evaluation: etc.total_score_evaluation
      };
    })
    .catch(e => alert(e));
  },

  data() {
    return {
      tab: 0,
      innerTab: 0,
      task: null,
    };
  },
};
</script>
<style scoped lang="scss">
@import '@/assets/sass/global.scss';
.task-detail-container {
  width: 1084px;
  margin: 0 auto;
  margin-top: 138px;
  margin-bottom: 144px;
}

.back {
  @extend .subheader-heavy;
  color: #717171;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  i {
    display: inline-block;
  }
}
.task-tab {
  margin-top: 8px;
  margin-bottom: 48px;
  padding: 0 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
.v-tabs {
  &::v-deep .v-tabs-slider-wrapper {
    width: 108px;
    height: 5px !important;
  }
  .v-tab {
    min-width: initial;
    margin-left: 20px;
    margin-right: 20px;
    letter-spacing: initial;
    text-transform: none !important;
    @extend .body-heavy;
    &--active {
      color: #212121 !important;
    }
    &:not(&--active) {
      color: #212121 !important;
    }
  }
}

.content {
  display: flex;
}

.sidebar {
  flex-shrink: 0;
  width: 200px !important;
  height: 280px;
  margin-right: 46px;
  background: white;
  box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  &::v-deep .v-list {
    padding: 0;
  }
  &::v-deep .v-list-item {
    padding: 14px 21px;
    @extend .body-heavy;
    font-weight: 500;
    min-height: initial;
    &--disabled {
      color: grey !important;
      &::before {
        color: grey !important;
      }
    }
  }
  &::v-deep .v-item {
    &--active {
      background-color: #fef3f5;
      color: #e84962 !important;
      &::before {
        background-color: #fef3f5;
        color: #e84962 !important;
      }
    }
  }
}
</style>
